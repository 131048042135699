import { Row, Col } from "antd";

import { IssueTile } from "components";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setIssueCount } from "store/actions";
import { KeyValue } from "types";
import { ISSUE_TIES_DATA } from "../__mock__";

export default function IssueTileContainer() {
  const [serviceCount, setServiceCount] = useState(0);
  const [breakdownCount, setBreakdownCount] = useState(0);
  const [roadsideBreakdownCount, setRoadsideBreakdownCount] = useState(0);
  const dispatch = useDispatch();
  const counts: KeyValue = useMemo(
    () => ({
      scheduledMaintenances: serviceCount,
      vehicleBreakdowns: breakdownCount,
      roadsideBreakdowns: roadsideBreakdownCount,
    }),
    [serviceCount, breakdownCount, roadsideBreakdownCount]
  );

  useEffect(() => {
    dispatch(
      setIssueCount(2, (resp) => {
        setServiceCount(resp.getCount());
      })
    );
    dispatch(
      setIssueCount(4, (resp) => {
        setBreakdownCount(resp.getCount());
      })
    );
    dispatch(
      setIssueCount(5, (resp) => {
        setRoadsideBreakdownCount(resp.getCount());
      })
    );
  }, [dispatch]);

  return (
    <Row className="tiles-container">
      {Object.entries(ISSUE_TIES_DATA).map(([key, tile]) => (
        <Col key={tile.name}>
          <IssueTile
            chart={tile.chart}
            name={tile.name}
            value={counts[key] !== undefined ? counts[key] : tile.value}
            type={tile.type === "simple" ? "simple" : "chart"}
            className={tile.className || ""}
          />
        </Col>
      ))}
    </Row>
  );
}
