import { FinancialsState } from "types/store";

export function parseFinancialsTable(
  search: FinancialsState["search"],
  filter: string,
  list?: FinancialsState["paymentList"]
) {
  if (list) {
    return list.filter((payment) => {
      return (
        filter === payment.payment_status &&
        Object.values(payment.payment_type)
          .join(" ")
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    });
  }

  return [];
}
