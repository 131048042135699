import { AreaChartDataProps } from "@k2/rv-viz";

export const ISSUE_TIES_DATA = {
  scheduledMaintenances: {
    value: 45,
    chart: [
      {
        curve: "curveMonotoneX",
        data: [
          {
            x: 1617217200000,
            y: 0,
          },
          {
            x: 1617130800000,
            y: 0,
          },
          {
            x: 1617044400000,
            y: 0,
          },
          {
            x: 1616958000000,
            y: 0,
          },
          {
            x: 1616871600000,
            y: 0,
          },
          {
            x: 1616785200000,
            y: 0,
          },
          {
            x: 1616698800000,
            y: 0,
          },
          {
            x: 1615230000000,
            y: 30,
          },
        ],
        name: "scheduledMaintenances",
        opacity: 0.2,
        stroke: "#4580D9",
      },
    ],
    name: "Scheduled Maintenances",
    type: "chart",
    className: "--blue-bars",
  },
  vehicleBreakdowns: {
    value: 3,
    chart: [
      {
        curve: "curveMonotoneX",
        data: [
          {
            x: 1617217200000,
            y: 0,
          },
          {
            x: 1617130800000,
            y: 0,
          },
          {
            x: 1617044400000,
            y: 0,
          },
          {
            x: 1616958000000,
            y: 0,
          },
          {
            x: 1616871600000,
            y: 0,
          },
          {
            x: 1616785200000,
            y: 0,
          },
          {
            x: 1616698800000,
            y: 0,
          },
          {
            x: 1615230000000,
            y: 30,
          },
        ],
        name: "vehicleBreakdowns",
        opacity: 0.2,
        stroke: "#4580D9",
      },
    ],
    name: "Vehicle Breakdowns",
    type: "chart",
    className: "--blue-bars",
  },
  quickService: {
    value: 40,
    chart: [
      {
        curve: "curveMonotoneX",
        data: [
          {
            x: 1617217200000,
            y: 0,
          },
          {
            x: 1617130800000,
            y: 0,
          },
          {
            x: 1617044400000,
            y: 0,
          },
          {
            x: 1616958000000,
            y: 0,
          },
          {
            x: 1616871600000,
            y: 0,
          },
          {
            x: 1616785200000,
            y: 0,
          },
          {
            x: 1616698800000,
            y: 0,
          },
          {
            x: 1615230000000,
            y: 28,
          },
        ],
        name: "quickService",
        opacity: 0.2,
        stroke: "#4580D9",
      },
    ],
    name: "Quick Service",
    type: "chart",
    className: "--blue-bars",
  },
  roadsideBreakdowns: {
    value: 2,
    chart: [
      {
        curve: "curveMonotoneX",
        data: [
          {
            x: 1617217200000,
            y: 0,
          },
          {
            x: 1617130800000,
            y: 0,
          },
          {
            x: 1617044400000,
            y: 0,
          },
          {
            x: 1616958000000,
            y: 0,
          },
          {
            x: 1616871600000,
            y: 0,
          },
          {
            x: 1616785200000,
            y: 0,
          },
          {
            x: 1616698800000,
            y: 0,
          },
          {
            x: 1615230000000,
            y: 6,
          },
        ],
        name: "roadsideBreakdowns",
        opacity: 0.2,
        stroke: "#ED2020",
      },
    ],
    name: "Roadside Breakdowns",
    type: "chart",
    className: "--red-bars",
  },
  majorRepairs: {
    value: 10,
    chart: [
      {
        curve: "curveMonotoneX",
        data: [
          {
            x: 1617217200000,
            y: 0,
          },
          {
            x: 1617130800000,
            y: 6,
          },
          {
            x: 1617044400000,
            y: 1,
          },
          {
            x: 1616958000000,
            y: 0,
          },
          {
            x: 1616871600000,
            y: 0,
          },
          {
            x: 1616785200000,
            y: 2,
          },
          {
            x: 1616698800000,
            y: 3,
          },
          {
            x: 1616526000000,
            y: 2,
          },
          {
            x: 1616439600000,
            y: 9,
          },
          {
            x: 1616353200000,
            y: 5,
          },
          {
            x: 1616266800000,
            y: 2,
          },
          {
            x: 1616180400000,
            y: 1,
          },
          {
            x: 1616094000000,
            y: 4,
          },
          {
            x: 1615316400000,
            y: 1,
          },
        ],
        name: "majorRepairs",
        opacity: 0.2,
        stroke: "#ED2020",
      },
    ],
    name: "Major Repairs",
    type: "chart",
    className: "--red-bars",
  },
  accidents: {
    value: 3,
    chart: [
      {
        curve: "curveMonotoneX",
        data: [
          {
            x: 1617217200000,
            y: 0,
          },
          {
            x: 1617130800000,
            y: 6,
          },
          {
            x: 1617044400000,
            y: 1,
          },
          {
            x: 1616958000000,
            y: 0,
          },
          {
            x: 1616871600000,
            y: 0,
          },
          {
            x: 1616785200000,
            y: 2,
          },
          {
            x: 1616698800000,
            y: 3,
          },
          {
            x: 1616526000000,
            y: 2,
          },
          {
            x: 1616439600000,
            y: 9,
          },
          {
            x: 1616353200000,
            y: 5,
          },
          {
            x: 1616266800000,
            y: 2,
          },
          {
            x: 1616180400000,
            y: 1,
          },
          {
            x: 1616094000000,
            y: 4,
          },
          {
            x: 1615316400000,
            y: 1,
          },
        ],
        name: "accidents",
        opacity: 0.2,
        stroke: "#ED2020",
      },
    ],
    name: "Accidents",
    type: "chart",
    className: "--red-bars",
  },
};

export const VEHICLE_TIES_DATA: {
  [key: string]: {
    value: number;
    name: string | JSX.Element;
    type: string;
    chart: AreaChartDataProps;
    className: string;
    link: boolean;
    render?: JSX.Element;
  };
} = {
  onHire: {
    link: true,
    value: 2000,
    name: (
      <span className="tile-name">
        <span>On-hire</span>
        <span className="tile-info">
          <strong className="on-hire-count">+100</strong>
          this month
        </span>
      </span>
    ),
    type: "simple",
    chart: [],
    className: "",
  },
  leaseExpiring: {
    link: true,
    value: 200,
    name: "Lease Expiring in 3 Months",
    type: "simple",
    className: "",
    chart: [],
  },
  leaseExpired: {
    link: true,
    value: 100,
    name: "Lease Expired",
    chart: [],
    className: "",
    type: "simple",
  },
  onLineOrArchive: {
    link: true,
    value: 10,
    name: "Online or Active",
    chart: [],
    className: "",
    type: "simple",
  },
  permitExpiring: {
    link: false,
    value: 73,
    name: "Permit Expiring",
    chart: [],
    className: "permit-expiry",
    type: "simple",
    render: (
      <section className="permit-expiring-data">
        <article className="data-box">
          <span className="count">35</span>
          <span className="title">Ads</span>
        </article>
        <article className="data-box">
          <span className="count">21</span>
          <span className="title">Food</span>
        </article>
        <article className="data-box">
          <span className="count">17</span>
          <span className="title">Box</span>
        </article>
      </section>
    ),
  },
};

export const FINANCIALS_TIES_DATA = {
  // totalPaid: {
  //   value: 500000,
  //   name: "Total paid",
  //   paymentPer: "",
  //   type: "simple",
  //   chart: [],
  //   className: "",
  //   buttonText: "",
  //   isAmount: true,
  // },
  totalDistance: {
    value: "5430 Kms",
    name: "Total Distance Covered",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: false,
  },
  totalOperationalBikes: {
    value: 6,
    name: "Total Operational Motorbikes",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: false,
  },
  batteryCost: {
    value: 68000,
    name: "Battery Subscription Cost",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: true,
  },
  totalSwaps: {
    value: 65,
    name: "Total Swaps",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: false,
  },
  CostPerSwap: {
    value: 165,
    name: "Cost Per Swap",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: true,
  },
 
  totalSwapCost: {
    value: 50000,
    name: "Total Swap Cost",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: true,
  },
  serviceContracts: {
    value: 6,
    name: "Service Contracts",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: false,
  },
  costPerService: {
    value: 500,
    name: "Cost Per Service Contract",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: true,
  },

  totalServiceCost: {
    value: 9865,
    name: "Total Service Contract Cost",
    paymentPer: "",
    type: "simple",
    chart: [],
    className: "",
    buttonText: "",
    isAmount: false,
  },
  // outstandingPayment: {
  //   value: 250000,
  //   name: "Total outstanding Payment",
  //   paymentPer: "",
  //   type: "simple",
  //   className: "",
  //   buttonText: "Due Date: 26 April,2024",
  //   chart: [],
  //   isAmount: true,
  // },
  // overduePayment: {
  //   value: 2000,
  //   name: "Overdue Payment",
  //   paymentPer: "",
  //   chart: [],
  //   className: "",
  //   buttonText: "",
  //   type: "simple",
  //   isAmount: true,
  // },
  // paymentsApproved: {
  //   value: 50,
  //   name: "Payments Approved",
  //   paymentPer: "",
  //   chart: [],
  //   className: "",
  //   buttonText: "View Approved Payments",
  //   type: "simple",
  //   isAmount: false,
  // },
  // paymentsPendingApproval: {
  //   value: 20,
  //   name: "Payments Pending Approval",
  //   paymentPer: "",
  //   chart: [],
  //   className: "",
  //   buttonText: "View  Pending Payments",
  //   type: "simple",
  //   isAmount: false,
  // },
  // paymentsPendingApproval2: {
  //   value: 20,
  //   name: "Payments Per Vehicle",
  //   paymentPer: "day",
  //   chart: [],
  //   className: "",
  //   buttonText: "View  Pending Payments",
  //   type: "simple",
  //   isAmount: true,
  // },
};

export const FINANCIALS_WIDGET: {
  [key: string]: {
    title: string;
    value: string;
    chart: AreaChartDataProps;
    footer: string;
    totalValue: string;
    table?: any[];
    due_date_tag?: string;
    due_date?: string;
  };
} = {
  "traffic-fines-count": {
    title: "Traffic Fines Count",
    value: "100 ",
    chart: ISSUE_TIES_DATA.roadsideBreakdowns.chart,
    footer: "Total Traffic Fines",
    totalValue: "4,200 AED",
    table: [
      { key: 0, name: "Paid", count: "80", value: "3,000 AED" },
      { key: 1, name: "outstanding", count: "20", value: "1,200 AED" },
    ],
  },
  "excess-insurance-count": {
    title: "Excess Insurance Count",
    value: "200 ",
    chart: ISSUE_TIES_DATA.vehicleBreakdowns.chart,
    footer: "Excess Insurance",
    totalValue: "6,000 AED",
    table: [
      { key: 0, name: "Paid", count: "150", value: "4,500 AED" },
      { key: 1, name: "outstanding", count: "50", value: "1,500 AED" },
    ],
  },
  "additional-costs": {
    title: "Total Bill",
    value: "PKR 210,000",
    chart:[],
    footer: "For Month of ",
    due_date_tag: "Due Date: ",
    due_date: "28 August 2024",
    totalValue: "August 2024",
    table: [
      { key: 0, name: "Sub Total", value: "4,500 PKR" },
      { key: 1, name: "Tax", value: "9,500 PKR" },
    ],
  },
  "repair-invoices-count": {
    title: "Repair Invoices Count",
    value: "18 ",
    chart: ISSUE_TIES_DATA.vehicleBreakdowns.chart,
    footer: "Total Repair Invoices",
    totalValue: "1,800 AED",
    table: [
      { key: 0, name: "Paid", count: "15", value: "1,400 AED" },
      { key: 1, name: "outstanding", count: "03", value: "400 AED" },
    ],
  },
  "traffic-salik-count": {
    title: "Traffic Salik Count",
    value: "35 ",
    chart: ISSUE_TIES_DATA.vehicleBreakdowns.chart,
    footer: "Total Salik",
    totalValue: "1,200 AED",
    table: [
      { key: 0, name: "Paid", count: "30", value: "1,000 AED" },
      { key: 1, name: "outstanding", count: "05", value: "200 AED" },
    ],
  },
};
