import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import { parseIssuesTable } from "utils/parsers";
import {
  setBulkActionsIssues,
  setIssuesSelectedPage,
  setSelectedIssuesRows,
} from "store/actions";
import { ActionMenu, issueTypeCell, statusCell } from "./cell-renders";
import { TableProps } from "antd/lib/table";

const columns: TableProps<any>["columns"] = [
  {
    title: "Issue ID",
    dataIndex: "issue_id",
    width: 130,
    ellipsis: true,
  },
  {
    title: "Issue Type",
    dataIndex: "issue_type",
    width: 130,
  },
  {
    title: "Vehicle/Kiosk",
    dataIndex: "issue_host",
    width: 130,
  },
  {
    title: "Host ID",
    dataIndex: "issue_host_id",
    width: 130,
    ellipsis: true,
  },
  {
    title: "Rider Name",
    dataIndex: "driver_name",
    // width: 120,
    ellipsis: true,
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Created On",
    dataIndex: "date_and_time",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Issue Occur On",
    dataIndex: "issue_occur_date",
    width: 140,
    ellipsis: true,
  },
  {
    title: "Assignee",
    dataIndex: "assignee",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    width: 110,
    ellipsis: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: statusCell,
    width: 100,
  },
  {
    title: "Category",
    dataIndex: "issue_level",
    width: 100,
    ellipsis: true,
  },

  {
    title: "",
    dataIndex: "action",
    render: ActionMenu,
    align: "right",
    width: 56,
    className: "action-menu open-link --issues",
  },
];
const getColumns: (userType: number) => TableProps<any>["columns"] = (
    userType
) => {
  if (userType > 2) {
    return columns.filter(
        (column) =>
            column.title !== "Category"
    );
  }

  return columns

}
export default function TableView() {
  const dispatch = useDispatch();
  const {
    issues,
    page,
    search,
    selectedIssuesRows,
    filters: { listType },
  } = useSelector<AppState, AppState["issues"]>((state) => state.issues);
  const {
    common: { userType =1 },
  } =  useSelector<AppState, AppState>((state) => state);
  const { selectedPage, totalPages } = page;
  const data = useMemo(
    () => parseIssuesTable(search, issues, listType),
    [issues, listType, search]
  );
  const onChangeSelection = useCallback(
    (value) => {
      dispatch(setSelectedIssuesRows(value));

      if (value.length !== 0) {
        dispatch(setBulkActionsIssues(true));
      } else {
        dispatch(setBulkActionsIssues(false));
      }
    },
    [dispatch]
  );

  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setIssuesSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setIssuesSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setIssuesSelectedPage(1));
  }, [dispatch]);

  return (
    <>
      <Table
        columns={getColumns(userType)}
        dataSource={data}
        // rowSelection={{
        //   selectedRowKeys: selectedIssuesRows,
        //   onChange: onChangeSelection,
        // }}
        pagination={false}
        scroll={{ y: "70vh" }}
      />
      <TablePagination
        page={page}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </>
  );
}
