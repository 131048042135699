import { useState } from "react";
import { Typography } from "antd";
import styled from "styled-components";

import { themeAngleRightIcon, themeColoredIcon } from "svgs";

const { Text } = Typography;

const ColorBoxStyled = styled.span<{
  selected: boolean;
  color: string;
}>`
  display: grid;
  place-items: center;
  border-radius: 2px;
  user-select: all;
  height: 23.83px;
  width: 23.83px;
  padding: 3px;
  border: ${({ selected, color }) => selected && `1px solid ${color}`};

  .color-box {
    display: inline-block;
    height: 100%;
    width: 100%;
    background: ${(props) => props.color};
    border-radius: 2px;
  }
`;
const primaryColorsList = [
  { selected: false, color: "#7230C8" },
  { selected: false, color: "#F57400" },
  { selected: false, color: "#4580D9" },
  { selected: false, color: "#0ABE93" },
  { selected: false, color: "#ECC600" },
  { selected: false, color: "#2C9F3F" },
  { selected: false, color: "#F56A6A" },
  { selected: false, color: "#ED2020" },
  { selected: false, color: "#05A27C" },
  { selected: false, color: "#EC9C00" },
  { selected: false, color: "#0ABE93" },
  { selected: false, color: "#18A0FB" },
  { selected: false, color: "#0A56C8" },
  { selected: false, color: "#DA30A0" },
  { selected: false, color: "#4A1183" },
  { selected: false, color: "#915E30" },
  { selected: false, color: "#F02849" },
  { selected: false, color: "#085467" },
  { selected: false, color: "#414141" },
  { selected: false, color: "#030303" },
  { selected: false, color: "#217D00" },
  { selected: false, color: "#F3D743" },
  { selected: true, color: "#116EB6" },
];
const secondaryColorsList = [
  { selected: false, color: "#7230C8" },
  { selected: false, color: "#F57400" },
  { selected: false, color: "#4580D9" },
  { selected: false, color: "#0ABE93" },
  { selected: false, color: "#ECC600" },
  { selected: false, color: "#2C9F3F" },
  { selected: false, color: "#F56A6A" },
  { selected: false, color: "#ED2020" },
  { selected: false, color: "#05A27C" },
  { selected: false, color: "#EC9C00" },
  { selected: false, color: "#0ABE93" },
  { selected: false, color: "#18A0FB" },
  { selected: false, color: "#0A56C8" },
  { selected: false, color: "#414141" },
  { selected: false, color: "#DA30A0" },
  { selected: false, color: "#4A1183" },
  { selected: false, color: "#915E30" },
  { selected: false, color: "#F02849" },
  { selected: false, color: "#085467" },
  { selected: false, color: "#414141" },
  { selected: false, color: "#030303" },
  { selected: false, color: "#217D00" },
  { selected: true, color: "#F3D743" },
];

export default function CustomColors() {
  const [primaryColors, setPrimaryColors] = useState(primaryColorsList);
  const [secondaryColors, setSecondaryColors] = useState(secondaryColorsList);
  return (
    <section className="theme-wrapper">
      <section>
        <section>
          <Text className="primary-colors-title">Primary Color</Text>
          <article className="colors-container">
            {primaryColors.map((data) => {
              const { selected, color } = data;
              return (
                <ColorBoxStyled
                  key={data.color}
                  color={color}
                  selected={selected}>
                  <span className="color-box"></span>
                </ColorBoxStyled>
              );
            })}
            <figure className="customize">Customize</figure>
          </article>
        </section>
        <section>
          <Text className="primary-colors-title">Secondary Color</Text>
          <article className="colors-container">
            {secondaryColors.map((data) => {
              const { selected, color } = data;
              return (
                <ColorBoxStyled
                  key={data.color}
                  color={color}
                  selected={selected}>
                  <span className="color-box"></span>
                </ColorBoxStyled>
              );
            })}
            <figure className="customize">Customize</figure>
          </article>
        </section>
      </section>
      <span>{themeAngleRightIcon}</span>
      <span>{themeColoredIcon}</span>
    </section>
  );
}
