/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: zone_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as zone_service_pb from './zone_service_pb';


export class ZoneManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddZone = new grpcWeb.MethodDescriptor(
    '/api.ZoneManagementService/AddZone',
    grpcWeb.MethodType.UNARY,
    zone_service_pb.AddZoneRequest,
    zone_service_pb.AddZoneResponse,
    (request: zone_service_pb.AddZoneRequest) => {
      return request.serializeBinary();
    },
    zone_service_pb.AddZoneResponse.deserializeBinary
  );

  addZone(
    request: zone_service_pb.AddZoneRequest,
    metadata: grpcWeb.Metadata | null): Promise<zone_service_pb.AddZoneResponse>;

  addZone(
    request: zone_service_pb.AddZoneRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: zone_service_pb.AddZoneResponse) => void): grpcWeb.ClientReadableStream<zone_service_pb.AddZoneResponse>;

  addZone(
    request: zone_service_pb.AddZoneRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: zone_service_pb.AddZoneResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.ZoneManagementService/AddZone',
        request,
        metadata || {},
        this.methodDescriptorAddZone,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.ZoneManagementService/AddZone',
    request,
    metadata || {},
    this.methodDescriptorAddZone);
  }

  methodDescriptorUpdateZone = new grpcWeb.MethodDescriptor(
    '/api.ZoneManagementService/UpdateZone',
    grpcWeb.MethodType.UNARY,
    zone_service_pb.UpdateZoneRequest,
    zone_service_pb.UpdateZoneResponse,
    (request: zone_service_pb.UpdateZoneRequest) => {
      return request.serializeBinary();
    },
    zone_service_pb.UpdateZoneResponse.deserializeBinary
  );

  updateZone(
    request: zone_service_pb.UpdateZoneRequest,
    metadata: grpcWeb.Metadata | null): Promise<zone_service_pb.UpdateZoneResponse>;

  updateZone(
    request: zone_service_pb.UpdateZoneRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: zone_service_pb.UpdateZoneResponse) => void): grpcWeb.ClientReadableStream<zone_service_pb.UpdateZoneResponse>;

  updateZone(
    request: zone_service_pb.UpdateZoneRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: zone_service_pb.UpdateZoneResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.ZoneManagementService/UpdateZone',
        request,
        metadata || {},
        this.methodDescriptorUpdateZone,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.ZoneManagementService/UpdateZone',
    request,
    metadata || {},
    this.methodDescriptorUpdateZone);
  }

  methodDescriptorGetZonesList = new grpcWeb.MethodDescriptor(
    '/api.ZoneManagementService/GetZonesList',
    grpcWeb.MethodType.UNARY,
    zone_service_pb.GetZonesListRequest,
    zone_service_pb.GetZonesListResponse,
    (request: zone_service_pb.GetZonesListRequest) => {
      return request.serializeBinary();
    },
    zone_service_pb.GetZonesListResponse.deserializeBinary
  );

  getZonesList(
    request: zone_service_pb.GetZonesListRequest,
    metadata: grpcWeb.Metadata | null): Promise<zone_service_pb.GetZonesListResponse>;

  getZonesList(
    request: zone_service_pb.GetZonesListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: zone_service_pb.GetZonesListResponse) => void): grpcWeb.ClientReadableStream<zone_service_pb.GetZonesListResponse>;

  getZonesList(
    request: zone_service_pb.GetZonesListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: zone_service_pb.GetZonesListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.ZoneManagementService/GetZonesList',
        request,
        metadata || {},
        this.methodDescriptorGetZonesList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.ZoneManagementService/GetZonesList',
    request,
    metadata || {},
    this.methodDescriptorGetZonesList);
  }

  methodDescriptorGetZoneById = new grpcWeb.MethodDescriptor(
    '/api.ZoneManagementService/GetZoneById',
    grpcWeb.MethodType.UNARY,
    zone_service_pb.GetZoneByIdRequest,
    zone_service_pb.GetZoneByIdResponse,
    (request: zone_service_pb.GetZoneByIdRequest) => {
      return request.serializeBinary();
    },
    zone_service_pb.GetZoneByIdResponse.deserializeBinary
  );

  getZoneById(
    request: zone_service_pb.GetZoneByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<zone_service_pb.GetZoneByIdResponse>;

  getZoneById(
    request: zone_service_pb.GetZoneByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: zone_service_pb.GetZoneByIdResponse) => void): grpcWeb.ClientReadableStream<zone_service_pb.GetZoneByIdResponse>;

  getZoneById(
    request: zone_service_pb.GetZoneByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: zone_service_pb.GetZoneByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.ZoneManagementService/GetZoneById',
        request,
        metadata || {},
        this.methodDescriptorGetZoneById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.ZoneManagementService/GetZoneById',
    request,
    metadata || {},
    this.methodDescriptorGetZoneById);
  }

}

