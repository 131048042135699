import { AreaChart, AreaChartDataProps } from "@k2/rv-viz";

interface ComponentProps {
  className: string;
  data: AreaChartDataProps;
}

function getColors(name: string) {
  switch (name) {
    case "roadsideBreakdowns":
    case "majorRepairs":
    case "accidents":
    case "brokenVehicles":
      return {
        dark: [
          {
            startingColor: "#ED2020",
            name: "brokenVehicles",
            endingColor: "#fff",
          },
        ],
        light: [
          {
            startingColor: "#ED2020",
            name: "brokenVehicles",
            endingColor: "#fff",
          },
        ],
      };
    case "inMaintenance":
      return {
        dark: [
          {
            startingColor: "#F57400",
            name: "inMaintenance",
            endingColor: "#fff",
          },
        ],
        light: [
          {
            startingColor: "#F57400",
            name: "inMaintenance",
            endingColor: "#fff",
          },
        ],
      };
    case "rideInProgresss":
      return {
        dark: [
          {
            startingColor: "#2C9F3F",
            name: "rideInProgresss",
            endingColor: "#fff",
          },
        ],
        light: [
          {
            startingColor: "#2C9F3F",
            name: "rideInProgresss",
            endingColor: "#fff",
          },
        ],
      };
    case "scheduledMaintenances":
    case "vehicleBreakdowns":
    case "quickService":
    case "idle":
      return {
        dark: [
          {
            startingColor: "#4580D9",
            name: "idle",
            endingColor: "#fff",
          },
        ],
        light: [
          {
            startingColor: "#4580D9",
            name: "idle",
            endingColor: "#fff",
          },
        ],
      };
    default:
      return;
  }
}

export default function TileSparkline(props: ComponentProps) {
  const { className, data } = props;
  const colors = getColors(data?.[0]?.name);

  return (
    <AreaChart
      classes={{
        areaSeries: className,
      }}
      horizontalGridLines={false}
      verticalGridLines={false}
      xAxis={false}
      yAxis={false}
      legends={false}
      xyPlot={{ margin: { left: 0, right: 0, top: 0, bottom: 0 } }}
      crosshair={false}
      data={data}
      colors={colors}
      componentStates={{
        blank: <div className="emptyTileChart"></div>,
      }}
    />
  );
}
