import ProtectedRoute from "components/protected-route";
import { Switch, Redirect } from "react-router-dom";

import { ROUTES } from "utils/constants";
import ListView from "./list-view";
import MapView from "./map-view";

export default function ZonesContent() {
  return (
    <div className="zones-content">
      <Switch>
        <ProtectedRoute exact path={ROUTES.zones}>
          <Redirect to={ROUTES.zones_list} />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.zones_list}>
          <ListView />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.zones_map}>
          <MapView />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.zones}/*`}>
          <Redirect to={ROUTES.zones_list} />
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
