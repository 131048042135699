import {Breadcrumb, Button} from "antd";
import { TableProps } from "antd/lib/table";
import { format } from "date-fns";
import { Table } from "components/base";
import {breadcrumbIcon, settingsIcon2} from "svgs";
import { KeyValue } from "types";
import { useSelector } from "react-redux";
import { AppState } from "store";
import React, {useCallback, useMemo} from "react";
import { DATE_FORMAT, FULL_DATE_FORMAT } from "../../../../utils/constants";
import {
  getDateFromSeconds,
} from "../../../../utils/helpers";
import { IssueStatus, IssueType } from "protobuf/issue_service_pb";
import { issueTypeCell, statusCell } from "../cell-renders";
import {useHistory} from "react-router-dom";


const columns: TableProps<any>["columns"] = [
  {
    title: "label",
    dataIndex: "label",
    width: 180,
    ellipsis: true,
  },
  {
    title: "value",
    dataIndex: "value",
    render: ValueCell,
  },
];

function ValueCell(value: string, row: KeyValue, index: number) {
  switch (row.type) {
    case "color":
      return (
        <span
          className="value-with-color"
          style={
            {
              "--color-cell": value,
            } as React.CSSProperties
          }
        >
          {value}
        </span>
      );
    case "last_maintaned":
      return (
        <span className="last-maintaned-cell">
          {value}
          <Button type="link" icon={settingsIcon2}>
            Schedule Maintenance
          </Button>
        </span>
      );
    case "functional_status":
      return <span className="value-with-color --circle">{ functionalStatusCell( parseInt(value) ) }</span>;
    case "utilization_status":
      return <span>{ renderUtilizationStatus( parseInt(value)) }</span>;
    case "assigned_to":
      return (
        <figure className="user-icon-container-table">
          <img
            src={`https://picsum.photos/22?random=${index}`}
            alt="user pic"
            className="round-image"
          />
          {value}
        </figure>
      );
    default:
      return value;
  }
}

export default function Detail() {

  const {
    issues: { selectedIssue },
  } = useSelector<AppState, AppState>((state) => state);
  const history = useHistory();
  const onClickBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const initialValues = useMemo(() => {
    return {
      id: selectedIssue?.id || "",
      serialId: selectedIssue?.serialId || 0,
      reportedBy: selectedIssue?.reportedBy || "",
      vehicleId: selectedIssue?.vehicleId || "",
      addedAt: selectedIssue?.addedAt || 0,
      status: selectedIssue?.status || IssueStatus.Enum,
      issue_type: selectedIssue?.type || IssueType.Enum,
      assignedTo: selectedIssue?.assignedTo || "",
      ownerOrgId: selectedIssue?.ownerOrgId || "",
      dueDate: selectedIssue?.dueDate || 0,
      riderId: selectedIssue?.riderId || "",
      riderName: selectedIssue?.riderName || "",
      createdByName: selectedIssue?.createdByName || "",
      assigneeName: selectedIssue?.assigneeName || "",
      vehiclePate: selectedIssue?.vehiclePate || "",
      issue_other_type: selectedIssue?.issueOtherTypeText || "",

      // description: selectedIssue?.description || "",
    };
  }, [selectedIssue]);

  const issueData = [
    { label: "Issue ID", value: initialValues.serialId },
    { label: "Issue Type", value: selectedIssue?.type ? issueTypeCell(selectedIssue?.type) : "" },
    { label: "Issue Description", value: selectedIssue?.issueOtherTypeText },
    { label: "Vehicle ID", value: initialValues.vehiclePate },
    { label: "Rider Name", value: initialValues.riderName },
    { label: "Created By", value: initialValues.createdByName },
    { label: "Created On", value:  format(
      getDateFromSeconds(initialValues.addedAt),
      FULL_DATE_FORMAT
    )  },
    { label: "Assignee", value: initialValues.assigneeName ? initialValues.assigneeName:"Not assigned" },
    { label: "Due Date", value: initialValues.dueDate >= 0 ? format(
      getDateFromSeconds(initialValues.dueDate),
      DATE_FORMAT
    ): "Not set yet"},
    { label: "Status", value: selectedIssue?.status ? statusCell(selectedIssue?.status) : "" },
  ];

  return (
    <section className="__detail-container">
      <Breadcrumb className="vehicle-route" separator={breadcrumbIcon} style={{ marginLeft: "-15px"}}>
        <Breadcrumb.Item>
          <Button type="link" className="vehicles-link" onClick={onClickBack}>
            Issue
          </Button>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Issue Details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="detail-content" style={{marginTop: "50px"}}>
        <Table
          title={() => ""}
          bordered={false}
          showHeader={false}
          customClasses="--simple-two-columns"
          columns={columns}
          dataSource={issueData}
          pagination={false}
        />
      </div>
    </section>
  );
}

function renderUtilizationStatus(value: number) {
  const status = value;
  switch (status) {
    case 0:
      return "In the Lot";
    case 1:
      return "Checked Out";
    case 2:
      return "In the Lot";
    case 3:
      return "In the Lot";
    case 4:
      return "In the Lot";
    case 5:
      return "In the Lot";
    default:
      return "Checked Out";
  }
}

function functionalStatusCell(value: number) {
  const status = value;
  switch (status) {
    case 0:
      return "Unknown";
    case 1:
      return "Ride In-progress";
    case 2:
      return "In Maintenance";
    case 3:
      return "Marked Broken";
    case 4:
      return "Idle";
    case 5:
      return "Retired";
    default:
      return "";
  }
}