import {useCallback, useMemo,  useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "components/base";
import { Button } from "antd";
import { useHistory, useLocation} from "react-router-dom";
import {
  markReadNotifications,
  initializeNotification,
  setNotificationsList,
  setBulkActionsNotifications,
  setSelectedNotifications,
  setNotificationsSearch
} from "store/actions";
import {
    searchIcon,
} from "svgs";
import { AppState } from "store";

import { Key } from 'react'; 

interface NotificationsHeaderProps {
  selectedRows: Key[];  
  clearSelection: () => void; 
}

export default function NotificationsHeader({ selectedRows, clearSelection }: NotificationsHeaderProps) {
  const location = useLocation();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { showbulkActionsNotifications } = useSelector<AppState, AppState["notificationCenter"]>(
    (state) => state.notificationCenter
  );
  const { selectedNotifications,notificationsCenter } = useSelector<AppState, AppState["notificationCenter"]>(
    (state) => state.notificationCenter
);  

    const {
        common: { userType =1 },
    } = useSelector<AppState, AppState>((state) => state);


  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setNotificationsSearch(event.target.value));
    },
    [dispatch]
  );
  let idList: string[] = [];

const onMarkAsRead = useCallback(async () => {
  
  if (selectedNotifications !== undefined) {
    console.log("selectedNotifications IDList:    ",selectedNotifications);
    idList = selectedNotifications
.map(item => item?.id )
.filter(id => typeof id === 'string');
  }
  console.log("IDList: ", idList);
  dispatch(markReadNotifications(idList));
  // After marking notifications as read, update the state to refresh the table
  const updatedNotifications = notificationsCenter!.map(notification => {
    if (idList.includes(notification.id)) {
      return { ...notification, isRead: true };
    }
    return notification;
  });
  console.log("Updated notifications:", updatedNotifications);
   dispatch(setBulkActionsNotifications(false));
  dispatch(setSelectedNotifications([]));
  dispatch(setNotificationsList(updatedNotifications));
  clearSelection();
 
}, [dispatch, notificationsCenter, idList,clearSelection]);


  return (


    <div className="common-header" >
      { 
         
        <Input
          customClasses="--standard"
          placeholder="Search Notifications..."
          prefix={searchIcon}
          onChange={onChange}
        />
      }
       {showbulkActionsNotifications ? (
        <article className="bulk-actions">
          <Button  onClick={onMarkAsRead}>
            Mark as read
          </Button>
        
        </article>
      ): ""}
    
    </div>
  );
}
