import { AuthcServiceClient } from "protobuf/Authc_serviceServiceClientPb";
import {
  VehicleManagementServiceClient,
  VehicleModelManagementServiceClient,
  VehicleModelAdminServiceClient,
} from "protobuf/Vehicle_serviceServiceClientPb";
import {
  IssueManagementServiceClient,
  IssueServiceClient,
} from "protobuf/Issue_serviceServiceClientPb";
import {
  UserManagementServiceClient,
  UserAdminServiceClient,
} from "protobuf/User_serviceServiceClientPb";
import {OrganizationAdminServiceClient, OwnerOrgManagementServiceClient} from "protobuf/Client_serviceServiceClientPb";
import { END_POINTS } from "./constants";
import {
  BatteryManagementServiceClient,
  BatteryModelManagementServiceClient
} from "../protobuf/Battery_serviceServiceClientPb";
import {ZoneManagementServiceClient} from "../protobuf/Zone_serviceServiceClientPb";
import {FcmNotificationServiceClient} from "../protobuf/Fcm_notification_serviceServiceClientPb";
import {FcmUserServiceClient} from "../protobuf/Fcm_user_token_serviceServiceClientPb";
import { LogServiceClient } from "protobuf/Log_serviceServiceClientPb";
import {BillingServiceClient} from "../protobuf/Billing_serviceServiceClientPb";

export const vehicleManagementServiceClient = new VehicleManagementServiceClient(
  END_POINTS.vehicles
);

export const vehicleModelManagementServiceClient = new VehicleModelManagementServiceClient(
  END_POINTS.vehicles
);

export const logServiceClient = new LogServiceClient(
  END_POINTS.vehicles
);

export const vehicleModelAdminServiceClient = new VehicleModelAdminServiceClient(
    END_POINTS.vehicles
);

export const issueManagementServiceClient = new IssueManagementServiceClient(
  END_POINTS.issues
);

export const issueServiceClient = new IssueServiceClient(END_POINTS.issues);

export const authcServiceClient = new AuthcServiceClient(END_POINTS.auth);

export const userManagementServiceClient = new UserManagementServiceClient(
  END_POINTS.user
);

export const userAdminServiceClient = new UserAdminServiceClient(
  END_POINTS.user
);

export const ownerOrgManagementServiceClient = new OwnerOrgManagementServiceClient(
  END_POINTS.user
);

export const fcmNotificationServiceClient = new FcmNotificationServiceClient(
    END_POINTS.user
);

export const fcmUserServiceClient = new FcmUserServiceClient(
    END_POINTS.user
);

export const batteryManagmentServiceClient = new BatteryManagementServiceClient(
    END_POINTS.vehicles
)

export const batteryModelManagementServiceClient = new BatteryModelManagementServiceClient(
    END_POINTS.vehicles
)

export const organizationManagementServiceClient = new OrganizationAdminServiceClient(END_POINTS.user)

export const zoneManagmentServiceClient = new ZoneManagementServiceClient(
    END_POINTS.vehicles
)

export const billingServiceClient = new BillingServiceClient(
    END_POINTS.billings
)
