import { Tabs } from "antd";

const { TabPane } = Tabs;

interface ComponentProps {
  selected?: string;
  options: { key: string }[];
  showCount?: boolean;
  onChange?: (value: string) => void;
}

function customTabPane(value: string, showCount?: boolean) {
  return (
    <article className="custom-tab-pane">
      <span>{value}</span>
      {showCount && <span className="count">12</span>}
    </article>
  );
}

export default function TabNavs(props: ComponentProps) {
  const { options, selected, showCount, onChange } = props;

  return (
    <Tabs className="tab-navs" activeKey={selected} onChange={onChange}>
      {options.map((option) => (
        <TabPane
          tab={customTabPane(option?.key, showCount)}
          key={option?.key}
        />
      ))}
    </Tabs>
  );
}
