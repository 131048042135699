import { useSelector } from "react-redux";
import { AppState } from "store";

interface ComponentProps {
  customClass?: string;
}

export default function LoadingScreen(props: ComponentProps) {
  const { customClass = "" } = props;
  const { loading } = useSelector<AppState, AppState["common"]>(
    (state) => state.common
  );

  return loading ? (
    <section className={`loading-screen ${customClass}`}>
      <article className="progress-bar-wrapper">
        <figure className="progress-bar"></figure>
      </article>
    </section>
  ) : null;
}
