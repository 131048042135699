import { Collapse, Button, Slider } from "antd";
import { cancelIcon, chevronClose, chevronOpen } from "svgs";

import {
  CheckboxPanelList,
  TimeContainer,
} from "components/tab-filters/sub-components";
import { useCallback, useState } from "react";
import { onChangeRootIssuesFilters } from "store/actions";
import { useDispatch } from "react-redux";

const { Panel } = Collapse;

const userStatus = [
  { label: "Active", value: "Active" },
  { label: "In-active", value: "In-active" },
  { label: "Blocked", value: "Blocked" },
];

export default function UsersFilter() {
  const [checkedList, setCheckedList] = useState([]);
  const dispatch = useDispatch();
  const onCheckboxListChange = useCallback((name, value, type) => {
    setCheckedList(value);
  }, []);
  const onClearAll = useCallback(() => {
    dispatch(onChangeRootIssuesFilters(false));
  }, [dispatch]);

  return (
    <div className="common-filter">
      <div className="__filter-header">
        {" "}
        <div className="all-options">
          <span className="filter-text">FILTERS</span>

          <Button
            className="clear-all-btn"
            icon={cancelIcon}
            type="link"
            danger
            onClick={onClearAll}>
            Clear All
          </Button>
        </div>
      </div>
      <Collapse
        defaultActiveKey={["5"]}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (isActive ? chevronOpen : chevronClose)}
        className="__common-collapse --user">
        <Panel header="Shift Time" key="1" className="shift-time-container">
          <section className="start">
            <span className="label">Start Time</span>
            <TimeContainer defaultOpt="am" />
          </section>

          <section className="end">
            <span className="label">End Time</span>
            <TimeContainer defaultOpt="pm" />
          </section>
        </Panel>

        <Panel
          header="Safety Rating"
          key="2"
          className="safety-rating-container">
          <span className="slider-container">
            <span>01</span>
            <Slider
              range
              defaultValue={[1, 4]}
              min={0}
              max={5}
              className="custom-slider"
            />
            <span>05</span>
          </span>
        </Panel>
        <CheckboxPanelList
          header={""}
          key={"Issue Status"}
          name={"Status"}
          options={userStatus}
          value={checkedList}
          onChange={onCheckboxListChange}
          disabled
        />
      </Collapse>
    </div>
  );
}
