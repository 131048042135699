import {useCallback, useEffect, useMemo} from "react";
import { Collapse, Button } from "antd";
import { cancelIcon, chevronClose, chevronOpen } from "svgs";

import { Checkbox } from "components/base";
import { CheckboxPanelList } from "./sub-components";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import {
  onChangeNotificationsFilters,
  onChangeRootNotificationsFilters, setRefreshToken, setToken,
} from "store/actions";
import { getIndeterminate, getAllCheckedStatus } from "utils/helpers";
import {FilterItem} from "../../types";
import {filter} from "cypress/types/minimatch";

export default function NotificationsFilter() {
  const dispatch = useDispatch();
  const {
    filters: { original: originalFilters, updated: updatedFilters },
    categories
  } = useSelector<AppState, AppState["notificationCenter"]>((state) => state.notificationCenter);

  const isAllChecked = useMemo(
    () => getAllCheckedStatus(updatedFilters, originalFilters),
    [updatedFilters, originalFilters]
  );
  const isIndeterminate = useMemo(
    () => getIndeterminate(updatedFilters, originalFilters),
    [updatedFilters, originalFilters]
  );

  const onChange = useCallback(
    (name, value, type: "list" | "all") => {
        console.log(`Name : ${name}, value : ${value}, type: ${type}`)
      dispatch(onChangeNotificationsFilters(name, value, type));
    },
    [dispatch]
  );
  const onRootChange = useCallback(
    (event) => {
      dispatch(onChangeRootNotificationsFilters(event.target.checked));
    },
    [dispatch]
  );
  const onClearAll = useCallback(() => {
    dispatch(onChangeRootNotificationsFilters(false));
  }, [dispatch]);
  return (
    <div className="common-filter">
      <div className="__filter-header">
        {" "}
        <div className="all-options">
          <Checkbox
            customClasses="--filter-light"
            onChange={onRootChange}
            checked={isAllChecked}
            indeterminate={isIndeterminate}>
            <span className="filter-text">FILTERS</span>
          </Checkbox>
          <Button
            className="clear-all-btn"
            icon={cancelIcon}
            type="link"
            danger
            onClick={onClearAll}>
            Clear All
          </Button>
        </div>
      </div>
      <Collapse
        className="__common-collapse"
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (isActive ? chevronOpen : chevronClose)}
        defaultActiveKey={Object.keys(originalFilters)}>
        {Object.entries(originalFilters).map(([key, row]) => (
          <CheckboxPanelList
            header={""}
            key={key}
            name={key}
            options={row}
            value={updatedFilters[key].map((filter) => filter.value)}
            onChange={onChange}
          />
        ))}
      </Collapse>
    </div>
  );
}
