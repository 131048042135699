import { Typography, Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { deleteUser, setModalName } from "store/actions";

const { Text, Title } = Typography;

export default function BlockAccountForm() {
  const { selectedUser } = useSelector<AppState, AppState["users"]>(
    (state) => state.users
  );

  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onDelete = useCallback(() => {
    dispatch(deleteUser(selectedUser?.id || "", true));
    onCancel();
    
  }, [dispatch, selectedUser?.id]);

  return (
    <section className="block-user-modal">
      <Title level={5}>Block Account</Title>
      <Text type="secondary" className="description">
        Are you sure you want to block the account of user{" "}
        <strong>
          "{selectedUser?.name} ({selectedUser?.phone})"
        </strong>
      </Text>
      <div className="action-btn-container">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" danger onClick={onDelete}>
          Archive
        </Button>
      </div>
    </section>
  );
}
