/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: user_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as user_service_pb from './user_service_pb';
import * as webhook_pb from './webhook_pb';


export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorMe = new grpcWeb.MethodDescriptor(
    '/api.UserService/Me',
    grpcWeb.MethodType.UNARY,
    user_service_pb.MeRequest,
    user_service_pb.MeResponse,
    (request: user_service_pb.MeRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.MeResponse.deserializeBinary
  );

  me(
    request: user_service_pb.MeRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.MeResponse>;

  me(
    request: user_service_pb.MeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.MeResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.MeResponse>;

  me(
    request: user_service_pb.MeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.MeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/Me',
        request,
        metadata || {},
        this.methodDescriptorMe,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/Me',
    request,
    metadata || {},
    this.methodDescriptorMe);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/api.UserService/UpdateUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.UpdateUserRequest,
    user_service_pb.UpdateUserResponse,
    (request: user_service_pb.UpdateUserRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.UpdateUserResponse.deserializeBinary
  );

  updateUser(
    request: user_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.UpdateUserResponse>;

  updateUser(
    request: user_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdateUserResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.UpdateUserResponse>;

  updateUser(
    request: user_service_pb.UpdateUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdateUserResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorUpdatePassword = new grpcWeb.MethodDescriptor(
    '/api.UserService/UpdatePassword',
    grpcWeb.MethodType.UNARY,
    user_service_pb.UpdatePasswordRequest,
    user_service_pb.UpdatePasswordResponse,
    (request: user_service_pb.UpdatePasswordRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.UpdatePasswordResponse.deserializeBinary
  );

  updatePassword(
    request: user_service_pb.UpdatePasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.UpdatePasswordResponse>;

  updatePassword(
    request: user_service_pb.UpdatePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdatePasswordResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.UpdatePasswordResponse>;

  updatePassword(
    request: user_service_pb.UpdatePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdatePasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/UpdatePassword',
        request,
        metadata || {},
        this.methodDescriptorUpdatePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/UpdatePassword',
    request,
    metadata || {},
    this.methodDescriptorUpdatePassword);
  }

  methodDescriptorForgotPassword = new grpcWeb.MethodDescriptor(
    '/api.UserService/ForgotPassword',
    grpcWeb.MethodType.UNARY,
    user_service_pb.ForgotPasswordRequest,
    user_service_pb.ForgotPasswordResponse,
    (request: user_service_pb.ForgotPasswordRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.ForgotPasswordResponse.deserializeBinary
  );

  forgotPassword(
    request: user_service_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.ForgotPasswordResponse>;

  forgotPassword(
    request: user_service_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.ForgotPasswordResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.ForgotPasswordResponse>;

  forgotPassword(
    request: user_service_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.ForgotPasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/ForgotPassword',
        request,
        metadata || {},
        this.methodDescriptorForgotPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/ForgotPassword',
    request,
    metadata || {},
    this.methodDescriptorForgotPassword);
  }

  methodDescriptorSendOtpToNewNumber = new grpcWeb.MethodDescriptor(
    '/api.UserService/SendOtpToNewNumber',
    grpcWeb.MethodType.UNARY,
    user_service_pb.SendOtpToNewNumberRequest,
    user_service_pb.SendOtpToNewNumberResponse,
    (request: user_service_pb.SendOtpToNewNumberRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.SendOtpToNewNumberResponse.deserializeBinary
  );

  sendOtpToNewNumber(
    request: user_service_pb.SendOtpToNewNumberRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.SendOtpToNewNumberResponse>;

  sendOtpToNewNumber(
    request: user_service_pb.SendOtpToNewNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.SendOtpToNewNumberResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.SendOtpToNewNumberResponse>;

  sendOtpToNewNumber(
    request: user_service_pb.SendOtpToNewNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.SendOtpToNewNumberResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/SendOtpToNewNumber',
        request,
        metadata || {},
        this.methodDescriptorSendOtpToNewNumber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/SendOtpToNewNumber',
    request,
    metadata || {},
    this.methodDescriptorSendOtpToNewNumber);
  }

  methodDescriptorVerifyNewNumberByOtp = new grpcWeb.MethodDescriptor(
    '/api.UserService/VerifyNewNumberByOtp',
    grpcWeb.MethodType.UNARY,
    user_service_pb.VerifyNewNumberByOtpRequest,
    user_service_pb.VerifyNewNumberByOtpResponse,
    (request: user_service_pb.VerifyNewNumberByOtpRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.VerifyNewNumberByOtpResponse.deserializeBinary
  );

  verifyNewNumberByOtp(
    request: user_service_pb.VerifyNewNumberByOtpRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.VerifyNewNumberByOtpResponse>;

  verifyNewNumberByOtp(
    request: user_service_pb.VerifyNewNumberByOtpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.VerifyNewNumberByOtpResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.VerifyNewNumberByOtpResponse>;

  verifyNewNumberByOtp(
    request: user_service_pb.VerifyNewNumberByOtpRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.VerifyNewNumberByOtpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/VerifyNewNumberByOtp',
        request,
        metadata || {},
        this.methodDescriptorVerifyNewNumberByOtp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/VerifyNewNumberByOtp',
    request,
    metadata || {},
    this.methodDescriptorVerifyNewNumberByOtp);
  }

  methodDescriptorGetSupportNumber = new grpcWeb.MethodDescriptor(
    '/api.UserService/GetSupportNumber',
    grpcWeb.MethodType.UNARY,
    user_service_pb.GetSupportNumberRequest,
    user_service_pb.SupportNumberResponse,
    (request: user_service_pb.GetSupportNumberRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.SupportNumberResponse.deserializeBinary
  );

  getSupportNumber(
    request: user_service_pb.GetSupportNumberRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.SupportNumberResponse>;

  getSupportNumber(
    request: user_service_pb.GetSupportNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.SupportNumberResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.SupportNumberResponse>;

  getSupportNumber(
    request: user_service_pb.GetSupportNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.SupportNumberResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/GetSupportNumber',
        request,
        metadata || {},
        this.methodDescriptorGetSupportNumber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/GetSupportNumber',
    request,
    metadata || {},
    this.methodDescriptorGetSupportNumber);
  }

  methodDescriptorGetOwnerManuals = new grpcWeb.MethodDescriptor(
    '/api.UserService/GetOwnerManuals',
    grpcWeb.MethodType.UNARY,
    user_service_pb.GetOwnerManualsRequest,
    user_service_pb.GetOwnerManualsResponse,
    (request: user_service_pb.GetOwnerManualsRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.GetOwnerManualsResponse.deserializeBinary
  );

  getOwnerManuals(
    request: user_service_pb.GetOwnerManualsRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.GetOwnerManualsResponse>;

  getOwnerManuals(
    request: user_service_pb.GetOwnerManualsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.GetOwnerManualsResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.GetOwnerManualsResponse>;

  getOwnerManuals(
    request: user_service_pb.GetOwnerManualsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.GetOwnerManualsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/GetOwnerManuals',
        request,
        metadata || {},
        this.methodDescriptorGetOwnerManuals,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/GetOwnerManuals',
    request,
    metadata || {},
    this.methodDescriptorGetOwnerManuals);
  }

  methodDescriptorUpdateUserStatus = new grpcWeb.MethodDescriptor(
    '/api.UserService/UpdateUserStatus',
    grpcWeb.MethodType.UNARY,
    webhook_pb.WebhookRequest,
    webhook_pb.WebhookResponse,
    (request: webhook_pb.WebhookRequest) => {
      return request.serializeBinary();
    },
    webhook_pb.WebhookResponse.deserializeBinary
  );

  updateUserStatus(
    request: webhook_pb.WebhookRequest,
    metadata: grpcWeb.Metadata | null): Promise<webhook_pb.WebhookResponse>;

  updateUserStatus(
    request: webhook_pb.WebhookRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: webhook_pb.WebhookResponse) => void): grpcWeb.ClientReadableStream<webhook_pb.WebhookResponse>;

  updateUserStatus(
    request: webhook_pb.WebhookRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: webhook_pb.WebhookResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserService/UpdateUserStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateUserStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserService/UpdateUserStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateUserStatus);
  }

}

export class UserManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddUser = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/AddUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.AddUserManagementRequest,
    user_service_pb.AddUserManagementResponse,
    (request: user_service_pb.AddUserManagementRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.AddUserManagementResponse.deserializeBinary
  );

  addUser(
    request: user_service_pb.AddUserManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.AddUserManagementResponse>;

  addUser(
    request: user_service_pb.AddUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.AddUserManagementResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.AddUserManagementResponse>;

  addUser(
    request: user_service_pb.AddUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.AddUserManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/AddUser',
        request,
        metadata || {},
        this.methodDescriptorAddUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/AddUser',
    request,
    metadata || {},
    this.methodDescriptorAddUser);
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/GetUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.GetUserManagementRequest,
    user_service_pb.GetUserManagementResponse,
    (request: user_service_pb.GetUserManagementRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.GetUserManagementResponse.deserializeBinary
  );

  getUser(
    request: user_service_pb.GetUserManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.GetUserManagementResponse>;

  getUser(
    request: user_service_pb.GetUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.GetUserManagementResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.GetUserManagementResponse>;

  getUser(
    request: user_service_pb.GetUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.GetUserManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/UpdateUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.UpdateUserManagementRequest,
    user_service_pb.UpdateUserManagementResponse,
    (request: user_service_pb.UpdateUserManagementRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.UpdateUserManagementResponse.deserializeBinary
  );

  updateUser(
    request: user_service_pb.UpdateUserManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.UpdateUserManagementResponse>;

  updateUser(
    request: user_service_pb.UpdateUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdateUserManagementResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.UpdateUserManagementResponse>;

  updateUser(
    request: user_service_pb.UpdateUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdateUserManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorListUsers = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/ListUsers',
    grpcWeb.MethodType.UNARY,
    user_service_pb.ListUsersManagementRequest,
    user_service_pb.ListUsersManagementResponse,
    (request: user_service_pb.ListUsersManagementRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.ListUsersManagementResponse.deserializeBinary
  );

  listUsers(
    request: user_service_pb.ListUsersManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.ListUsersManagementResponse>;

  listUsers(
    request: user_service_pb.ListUsersManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersManagementResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.ListUsersManagementResponse>;

  listUsers(
    request: user_service_pb.ListUsersManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/ListUsers',
        request,
        metadata || {},
        this.methodDescriptorListUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/ListUsers',
    request,
    metadata || {},
    this.methodDescriptorListUsers);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/DeleteUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.DeleteUserManagementRequest,
    user_service_pb.DeleteUserManagementResponse,
    (request: user_service_pb.DeleteUserManagementRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.DeleteUserManagementResponse.deserializeBinary
  );

  deleteUser(
    request: user_service_pb.DeleteUserManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.DeleteUserManagementResponse>;

  deleteUser(
    request: user_service_pb.DeleteUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.DeleteUserManagementResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.DeleteUserManagementResponse>;

  deleteUser(
    request: user_service_pb.DeleteUserManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.DeleteUserManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorListUserRiders = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/ListUserRiders',
    grpcWeb.MethodType.UNARY,
    user_service_pb.ListUsersRiderManagementRequest,
    user_service_pb.ListUsersRiderManagementResponse,
    (request: user_service_pb.ListUsersRiderManagementRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.ListUsersRiderManagementResponse.deserializeBinary
  );

  listUserRiders(
    request: user_service_pb.ListUsersRiderManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.ListUsersRiderManagementResponse>;

  listUserRiders(
    request: user_service_pb.ListUsersRiderManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersRiderManagementResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.ListUsersRiderManagementResponse>;

  listUserRiders(
    request: user_service_pb.ListUsersRiderManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersRiderManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/ListUserRiders',
        request,
        metadata || {},
        this.methodDescriptorListUserRiders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/ListUserRiders',
    request,
    metadata || {},
    this.methodDescriptorListUserRiders);
  }

  methodDescriptorDownloadFile = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/DownloadFile',
    grpcWeb.MethodType.UNARY,
    user_service_pb.DownloadFileRequest,
    user_service_pb.DownloadFileResponse,
    (request: user_service_pb.DownloadFileRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.DownloadFileResponse.deserializeBinary
  );

  downloadFile(
    request: user_service_pb.DownloadFileRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.DownloadFileResponse>;

  downloadFile(
    request: user_service_pb.DownloadFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.DownloadFileResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.DownloadFileResponse>;

  downloadFile(
    request: user_service_pb.DownloadFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.DownloadFileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/DownloadFile',
        request,
        metadata || {},
        this.methodDescriptorDownloadFile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/DownloadFile',
    request,
    metadata || {},
    this.methodDescriptorDownloadFile);
  }

  methodDescriptorUploadProfilePhoto = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/UploadProfilePhoto',
    grpcWeb.MethodType.UNARY,
    user_service_pb.UploadPhotoRequest,
    user_service_pb.UploadPhotoResponse,
    (request: user_service_pb.UploadPhotoRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.UploadPhotoResponse.deserializeBinary
  );

  uploadProfilePhoto(
    request: user_service_pb.UploadPhotoRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.UploadPhotoResponse>;

  uploadProfilePhoto(
    request: user_service_pb.UploadPhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.UploadPhotoResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.UploadPhotoResponse>;

  uploadProfilePhoto(
    request: user_service_pb.UploadPhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.UploadPhotoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/UploadProfilePhoto',
        request,
        metadata || {},
        this.methodDescriptorUploadProfilePhoto,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/UploadProfilePhoto',
    request,
    metadata || {},
    this.methodDescriptorUploadProfilePhoto);
  }

  methodDescriptorAddSupportNumber = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/AddSupportNumber',
    grpcWeb.MethodType.UNARY,
    user_service_pb.AddSupportNumberRequest,
    user_service_pb.SupportNumberResponse,
    (request: user_service_pb.AddSupportNumberRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.SupportNumberResponse.deserializeBinary
  );

  addSupportNumber(
    request: user_service_pb.AddSupportNumberRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.SupportNumberResponse>;

  addSupportNumber(
    request: user_service_pb.AddSupportNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.SupportNumberResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.SupportNumberResponse>;

  addSupportNumber(
    request: user_service_pb.AddSupportNumberRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.SupportNumberResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/AddSupportNumber',
        request,
        metadata || {},
        this.methodDescriptorAddSupportNumber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/AddSupportNumber',
    request,
    metadata || {},
    this.methodDescriptorAddSupportNumber);
  }

  methodDescriptorSetTenantProxy = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/SetTenantProxy',
    grpcWeb.MethodType.UNARY,
    user_service_pb.SetTenantProxyRequest,
    user_service_pb.SetTenantProxyResponse,
    (request: user_service_pb.SetTenantProxyRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.SetTenantProxyResponse.deserializeBinary
  );

  setTenantProxy(
    request: user_service_pb.SetTenantProxyRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.SetTenantProxyResponse>;

  setTenantProxy(
    request: user_service_pb.SetTenantProxyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.SetTenantProxyResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.SetTenantProxyResponse>;

  setTenantProxy(
    request: user_service_pb.SetTenantProxyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.SetTenantProxyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/SetTenantProxy',
        request,
        metadata || {},
        this.methodDescriptorSetTenantProxy,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/SetTenantProxy',
    request,
    metadata || {},
    this.methodDescriptorSetTenantProxy);
  }

  methodDescriptorGetClientList = new grpcWeb.MethodDescriptor(
    '/api.UserManagementService/GetClientList',
    grpcWeb.MethodType.UNARY,
    user_service_pb.GetClientListRequest,
    user_service_pb.GetClientListResponse,
    (request: user_service_pb.GetClientListRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.GetClientListResponse.deserializeBinary
  );

  getClientList(
    request: user_service_pb.GetClientListRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.GetClientListResponse>;

  getClientList(
    request: user_service_pb.GetClientListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.GetClientListResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.GetClientListResponse>;

  getClientList(
    request: user_service_pb.GetClientListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.GetClientListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserManagementService/GetClientList',
        request,
        metadata || {},
        this.methodDescriptorGetClientList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserManagementService/GetClientList',
    request,
    metadata || {},
    this.methodDescriptorGetClientList);
  }

}

export class UserAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddUser = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/AddUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.AddUserAdminRequest,
    user_service_pb.AddUserAdminResponse,
    (request: user_service_pb.AddUserAdminRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.AddUserAdminResponse.deserializeBinary
  );

  addUser(
    request: user_service_pb.AddUserAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.AddUserAdminResponse>;

  addUser(
    request: user_service_pb.AddUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.AddUserAdminResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.AddUserAdminResponse>;

  addUser(
    request: user_service_pb.AddUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.AddUserAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/AddUser',
        request,
        metadata || {},
        this.methodDescriptorAddUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/AddUser',
    request,
    metadata || {},
    this.methodDescriptorAddUser);
  }

  methodDescriptorGetUser = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/GetUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.GetUserAdminRequest,
    user_service_pb.GetUserAdminResponse,
    (request: user_service_pb.GetUserAdminRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.GetUserAdminResponse.deserializeBinary
  );

  getUser(
    request: user_service_pb.GetUserAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.GetUserAdminResponse>;

  getUser(
    request: user_service_pb.GetUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.GetUserAdminResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.GetUserAdminResponse>;

  getUser(
    request: user_service_pb.GetUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.GetUserAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/GetUser',
        request,
        metadata || {},
        this.methodDescriptorGetUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/GetUser',
    request,
    metadata || {},
    this.methodDescriptorGetUser);
  }

  methodDescriptorUpdateUser = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/UpdateUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.UpdateUserAdminRequest,
    user_service_pb.UpdateUserAdminResponse,
    (request: user_service_pb.UpdateUserAdminRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.UpdateUserAdminResponse.deserializeBinary
  );

  updateUser(
    request: user_service_pb.UpdateUserAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.UpdateUserAdminResponse>;

  updateUser(
    request: user_service_pb.UpdateUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdateUserAdminResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.UpdateUserAdminResponse>;

  updateUser(
    request: user_service_pb.UpdateUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.UpdateUserAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/UpdateUser',
        request,
        metadata || {},
        this.methodDescriptorUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/UpdateUser',
    request,
    metadata || {},
    this.methodDescriptorUpdateUser);
  }

  methodDescriptorDeleteUser = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/DeleteUser',
    grpcWeb.MethodType.UNARY,
    user_service_pb.DeleteUserAdminRequest,
    user_service_pb.DeleteUserAdminResponse,
    (request: user_service_pb.DeleteUserAdminRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.DeleteUserAdminResponse.deserializeBinary
  );

  deleteUser(
    request: user_service_pb.DeleteUserAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.DeleteUserAdminResponse>;

  deleteUser(
    request: user_service_pb.DeleteUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.DeleteUserAdminResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.DeleteUserAdminResponse>;

  deleteUser(
    request: user_service_pb.DeleteUserAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.DeleteUserAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/DeleteUser',
        request,
        metadata || {},
        this.methodDescriptorDeleteUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/DeleteUser',
    request,
    metadata || {},
    this.methodDescriptorDeleteUser);
  }

  methodDescriptorListUsers = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/ListUsers',
    grpcWeb.MethodType.UNARY,
    user_service_pb.ListUsersAdminRequest,
    user_service_pb.ListUsersAdminResponse,
    (request: user_service_pb.ListUsersAdminRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.ListUsersAdminResponse.deserializeBinary
  );

  listUsers(
    request: user_service_pb.ListUsersAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.ListUsersAdminResponse>;

  listUsers(
    request: user_service_pb.ListUsersAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersAdminResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.ListUsersAdminResponse>;

  listUsers(
    request: user_service_pb.ListUsersAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/ListUsers',
        request,
        metadata || {},
        this.methodDescriptorListUsers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/ListUsers',
    request,
    metadata || {},
    this.methodDescriptorListUsers);
  }

  methodDescriptorListUserRiders = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/ListUserRiders',
    grpcWeb.MethodType.UNARY,
    user_service_pb.ListUsersRiderAdminRequest,
    user_service_pb.ListUsersRiderAdminResponse,
    (request: user_service_pb.ListUsersRiderAdminRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.ListUsersRiderAdminResponse.deserializeBinary
  );

  listUserRiders(
    request: user_service_pb.ListUsersRiderAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.ListUsersRiderAdminResponse>;

  listUserRiders(
    request: user_service_pb.ListUsersRiderAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersRiderAdminResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.ListUsersRiderAdminResponse>;

  listUserRiders(
    request: user_service_pb.ListUsersRiderAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.ListUsersRiderAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/ListUserRiders',
        request,
        metadata || {},
        this.methodDescriptorListUserRiders,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/ListUserRiders',
    request,
    metadata || {},
    this.methodDescriptorListUserRiders);
  }

  methodDescriptorDownloadFile = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/DownloadFile',
    grpcWeb.MethodType.UNARY,
    user_service_pb.DownloadFileRequest,
    user_service_pb.DownloadFileResponse,
    (request: user_service_pb.DownloadFileRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.DownloadFileResponse.deserializeBinary
  );

  downloadFile(
    request: user_service_pb.DownloadFileRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.DownloadFileResponse>;

  downloadFile(
    request: user_service_pb.DownloadFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.DownloadFileResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.DownloadFileResponse>;

  downloadFile(
    request: user_service_pb.DownloadFileRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.DownloadFileResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/DownloadFile',
        request,
        metadata || {},
        this.methodDescriptorDownloadFile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/DownloadFile',
    request,
    metadata || {},
    this.methodDescriptorDownloadFile);
  }

  methodDescriptorUploadProfilePhoto = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/UploadProfilePhoto',
    grpcWeb.MethodType.UNARY,
    user_service_pb.UploadPhotoRequest,
    user_service_pb.UploadPhotoResponse,
    (request: user_service_pb.UploadPhotoRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.UploadPhotoResponse.deserializeBinary
  );

  uploadProfilePhoto(
    request: user_service_pb.UploadPhotoRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.UploadPhotoResponse>;

  uploadProfilePhoto(
    request: user_service_pb.UploadPhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.UploadPhotoResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.UploadPhotoResponse>;

  uploadProfilePhoto(
    request: user_service_pb.UploadPhotoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.UploadPhotoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/UploadProfilePhoto',
        request,
        metadata || {},
        this.methodDescriptorUploadProfilePhoto,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/UploadProfilePhoto',
    request,
    metadata || {},
    this.methodDescriptorUploadProfilePhoto);
  }

  methodDescriptorGenerateAPIKeySecret = new grpcWeb.MethodDescriptor(
    '/api.UserAdminService/GenerateAPIKeySecret',
    grpcWeb.MethodType.UNARY,
    user_service_pb.GenerateAPIKeySecretRequest,
    user_service_pb.GenerateAPIKeySecretResponse,
    (request: user_service_pb.GenerateAPIKeySecretRequest) => {
      return request.serializeBinary();
    },
    user_service_pb.GenerateAPIKeySecretResponse.deserializeBinary
  );

  generateAPIKeySecret(
    request: user_service_pb.GenerateAPIKeySecretRequest,
    metadata: grpcWeb.Metadata | null): Promise<user_service_pb.GenerateAPIKeySecretResponse>;

  generateAPIKeySecret(
    request: user_service_pb.GenerateAPIKeySecretRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: user_service_pb.GenerateAPIKeySecretResponse) => void): grpcWeb.ClientReadableStream<user_service_pb.GenerateAPIKeySecretResponse>;

  generateAPIKeySecret(
    request: user_service_pb.GenerateAPIKeySecretRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: user_service_pb.GenerateAPIKeySecretResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.UserAdminService/GenerateAPIKeySecret',
        request,
        metadata || {},
        this.methodDescriptorGenerateAPIKeySecret,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.UserAdminService/GenerateAPIKeySecret',
    request,
    metadata || {},
    this.methodDescriptorGenerateAPIKeySecret);
  }

}

