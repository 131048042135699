import { LineChart } from "@k2/rv-viz";

import { addDays, format, startOfDay, startOfWeek } from "date-fns";

const data = [
  {
    name: "Accident",
    strokeWidth: 1,
    size: 2,
    color: "#7230c8",
    data: [
      { x: getDate(1).getTime(), y: 0 },
      { x: getDate(2).getTime(), y: 5 },
      { x: getDate(3).getTime(), y: 10 },
      { x: getDate(4).getTime(), y: 20 },
      { x: getDate(5).getTime(), y: 3 },
      { x: getDate(6).getTime(), y: 18 },
      { x: getDate(7).getTime(), y: 11 },
    ],
  },
  {
    name: "Breakdown",
    strokeWidth: 1,
    size: 2,
    color: " #f57400",
    data: [
      { x: getDate(1).getTime(), y: 7 },
      { x: getDate(2).getTime(), y: 3 },
      { x: getDate(3).getTime(), y: 1 },
      { x: getDate(4).getTime(), y: 2 },
      { x: getDate(5).getTime(), y: 9 },
      { x: getDate(6).getTime(), y: 11 },
      { x: getDate(7).getTime(), y: 7 },
    ],
  },
  {
    name: "Service",
    strokeWidth: 1,
    size: 2,
    color: "#4580d9",
    data: [
      { x: getDate(1).getTime(), y: 17 },
      { x: getDate(2).getTime(), y: 4 },
      { x: getDate(3).getTime(), y: 7 },
      { x: getDate(4).getTime(), y: 1 },
      { x: getDate(5).getTime(), y: 10 },
      { x: getDate(6).getTime(), y: 12 },
      { x: getDate(7).getTime(), y: 6 },
    ],
  },
  {
    name: "Vehicle Missing",
    strokeWidth: 1,
    size: 2,
    color: "#ecc600",
    data: [
      { x: getDate(1).getTime(), y: 9 },
      { x: getDate(2).getTime(), y: 7 },
      { x: getDate(3).getTime(), y: 1 },
      { x: getDate(4).getTime(), y: 10 },
      { x: getDate(5).getTime(), y: 19 },
      { x: getDate(6).getTime(), y: 1 },
      { x: getDate(7).getTime(), y: 5 },
    ],
  },
];

function getDate(dayNumber: number) {
  const startOfTheDay = startOfDay(new Date());
  const startingDay = startOfWeek(startOfTheDay);
  const date = addDays(startingDay, dayNumber);

  return date;
}

export default function RenderChart() {
  return (
    <LineChart
      data={data}
      verticalGridLines={false}
      title={"Issue Count"}
      className="line-chart-styles"
      xAxis={{
        tickTotal: 7,
        tickSizeInner: 0,
        tickSizeOuter: 6,
        tickPadding: 15,
        tickFormat: (data: any) => {
          return format(new Date(data), "E");
        },

        className: "line-chart-tick-styles",
      }}
      yAxis={{
        hideLine: true,
        tickPadding: 15,
        tickSize: 0,
      }}
      crosshair={{
        xFormatter: function (val) {
          return format(new Date(val), "EEEE");
        },
      }}
    />
  );
}
