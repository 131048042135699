import { ReactText, useCallback } from "react";
import { Typography } from "antd";
import { format } from "date-fns";

import {
  accidentIcon,
  breakdownIcon,
  serviceIcon,
  vehicleMissingIcon,
  timeIcon,
  bikeIcon,
  editIcon,
  archiveIcon,
  restoreIcon,
  fileUploadIcon,
} from "svgs";
import {BatteryState, IssuesState, IssuesTableRow, KeyValue, VehicleModelState} from "types";
import { classNames } from "utils/helpers";
import { Tag } from "components/base";
import { IssueStatus, IssueType } from "protobuf/issue_service_pb";
import { DATE_FORMAT, TIME_FORMAT } from "utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {setModalName, setSelectedBattery} from "store/actions";
import DropdownAction from "./dropdown-action";
import {AppState} from "../../../store";

const { Text } = Typography;

const issuesIcon: KeyValue = {
  accident: accidentIcon,
  breakdown: breakdownIcon,
  service: serviceIcon,
  "vehicle missing": vehicleMissingIcon,
};

export function ActionMenu(
  value: string,
  row:  {
    raw: BatteryState["selectedBattery"];
    listType: BatteryState["filters"]["listType"];
  }
) {
  const dispatch = useDispatch();

  const onEditBattery = useCallback((e) => {
    e.stopPropagation();
    dispatch(setSelectedBattery(row.raw));
    dispatch(setModalName("edit_battery"));
  }, [dispatch, row.raw]);
  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  if (userType == 1 || userType == 2) {
    return (
        <section className="table-action-container show-on-hover">

      {/*<span className="edit-icon icon" onClick={onEditBattery} title="edit issue">*/}
      {/*  {editIcon}*/}
      {/*</span>*/}
          <DropdownAction battery={row.raw} />
        </section>
    );
  }
  return <></>

}

