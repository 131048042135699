import { IssueStatus, IssueType } from "protobuf/issue_service_pb";
import { IssuesActions, IssuesState } from "types/store";
import { ISSUE_STATUS, ISSUE_TYPE } from "utils/mapped-data";

const originalFilters = {
  "Issue Type": Object.keys(IssueType.Enum).map((key, index) => ({
    label: ISSUE_TYPE[index as IssueType.Enum],
    value: key,
  })),
  "Issue Status": Object.keys(IssueStatus.Enum).map((key, index) => ({
    label: ISSUE_STATUS[index as IssueStatus.Enum],
    value: key,
  })),
  "Time Range": [
    { label: "Last 3 days", value: "Last 3 days" },
    { label: "Last week", value: "Last week" },
    { label: "Last month", value: "Last month" },
    { label: "Custom Range", value: "Custom Range" },
  ],
  Assignee: [],
};
const updatedFilters = {
  "Issue Type": [],
  "Issue Status": [],
  "Time Range": [{ label: "Last month", value: "Last month" }],
  Assignee: [],
};

const initialState: IssuesState = {
  page: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  filters: {
    original: originalFilters,
    updated: updatedFilters,
    listType: "current",
  },
  search: "",
  showbulkActionsIssues: false,
  selectedIssuesRows: [],
};

export default function vehiclesReducer(
  state: IssuesState = initialState,
  action: IssuesActions
): IssuesState {
  switch (action.type) {
    case "SET_ISSUES":
      return { ...state, issues: action.payload };
    case "SET_ISSUES_SELECTED_PAGE":
      return {
        ...state,
        page: { ...state.page, selectedPage: action.payload },
      };
    case "SET_ISSUES_TOTAL_PAGE":
      return { ...state, page: { ...state.page, ...action.payload } };
    case "SET_ISSUES_ORIGINAL_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, original: action.payload },
      };
    case "SET_ISSUES_UPDATED_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, updated: action.payload },
      };
    case "SET_ISSUES_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "SET_SELECTED_ISSUE":
      return {
        ...state,
        selectedIssue: action.payload,
      };
    case "SET_ISSUES_BULK_ACTIONS":
      return {
        ...state,
        showbulkActionsIssues: action.payload,
      };
    case "SET_ISSUES_SELECTED_ROWS":
      return {
        ...state,
        selectedIssuesRows: action.payload,
      };
    case "SET_ISSUES_LIST_TYPE":
      return {
        ...state,
        filters: {
          ...state.filters,
          listType: action.payload,
        },
      };
    default:
      return state;
  }
}
