import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import TableView from "./table-view";
import ListView from "./list-view";
import ProtectedRoute from "components/protected-route";
import UserDetail from "./user-detail";

export default function UsersContent() {
  return (
    <div className="users-content">
      <Switch>
        <ProtectedRoute exact path={ROUTES.users}>
          <Redirect to={ROUTES.users_table_all} />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.user_detail}>
          <UserDetail />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.users_table}>
          <Redirect to={ROUTES.users_table_all} />
        </ProtectedRoute>
        <ProtectedRoute exact path={ROUTES.users_list}>
          <Redirect to={ROUTES.users_list_all} />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.users_list}/:tabName`}>
          <ListView />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.users_table}/:tabName`}>
          <TableView />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.users_table}/*`}>
          <Redirect to={ROUTES.users_table_all} />
        </ProtectedRoute>
        <ProtectedRoute path={`${ROUTES.users_list}/*`}>
          <Redirect to={ROUTES.users_list_all} />
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
