import {useCallback, useEffect, useMemo, useState} from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Typography } from "antd";
import {
  editIssue,
  fetchAdminUsers,
  fetchRiderUsers,
  getDropdownVehicles,
  setModalName,
} from "store/actions";
import { RadioGroup, FormItem, SelectSearch } from "../components";
import { DatePicker } from "../components/base";
import { parseUsers, parseVehicles } from "utils/parsers";
import { ADD_ISSUE_FORMIK } from "utils/formik-data";
import { AppState } from "store";
import { getDateFromSeconds } from "utils/helpers";
import {statusCell} from "../components/tab-content/issues-content/cell-renders";
import {ISSUE_STATUS} from "../utils/mapped-data";

const { Title, Text } = Typography;

const metaData = {
  time_range: {
    name: "Time Range",
    options: [
      { label: "Accident", value: "1" },
      { label: "Breakdown", value: "4" },
      { label: "Roadside Breakdown", value: "5"},
      { label: "Service", value: "2" },
      { label: "Vehicle Missing", value: "3" },
      { label: "Unknown", value: "0" },
    ],
  },
  vehicle_id: {
    items: [
      { label: "J-37829", value: "J-37829" },
      { label: "H-34421", value: "H-34421" },
      { label: "D-34662", value: "D-34662" },
      { label: "G-34522", value: "G-34522" },
      { label: "D-74932", value: "D-74932" },
      { label: "P-34359", value: "P-34359" },
      { label: "S-83204", value: "S-83204" },
    ],
  },
  driver_name: {
    items: [
      { label: "Hasan Sheikh", value: "Hasan Sheikh" },
      { label: "Salman Ahmed", value: "Salman Ahmed" },
      { label: "Harris Shams", value: "Harris Shams" },
      { label: "Mohammad Taimoor", value: "Mohammad Taimoor" },
      { label: "Ali  Bin Saud", value: "Ali  Bin Saud" },
      { label: "Moeen-ud-Din", value: "Moeen-ud-Din" },
    ],
  },
  assignee: {
    items: [
      { label: "Hasan Sheikh", value: "Hasan Sheikh" },
      { label: "Salman Ahmed", value: "Salman Ahmed" },
      { label: "Harris Shams", value: "Harris Shams" },
      { label: "Mohammad Taimoor", value: "Mohammad Taimoor" },
      { label: "Ali  Bin Saud", value: "Ali  Bin Saud" },
      { label: "Moeen-ud-Din", value: "Moeen-ud-Din" },
    ],
  },
};

function disabledDate(current: Date) {
  return current && current <= new Date();
}

function disabledOccurDate(current: Date) {
  return current && current >= new Date();
}

export default function EditUsersForm() {
  const dispatch = useDispatch();
  const {
    vehicles: { dropdownVehicles },
    users: { riderUsers, adminUsers },
    issues: { selectedIssue },
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  const [host, setHost] = useState(selectedIssue?.issueHost || 1);

  const vehiclesOptions = useMemo(() => parseVehicles(dropdownVehicles), [
    dropdownVehicles,
  ]);
  const usersOptions = useMemo(() => parseUsers(riderUsers), [riderUsers]);
  const adminOptions = useMemo(() => parseUsers(adminUsers), [adminUsers]);

  //  0: "Unknown",
  //   1: "Completed",
  //   2: "Pending",
  //   3: "Cancelled",
  const issueStatus = [
    {
      label: "Unknown",
      value: 0
    },
    {
      label: "Pending",
      value: 2
    },
    {
      label: "Completed",
      value: 1
    },
    {
      label: "Cancelled",
      value: 3
    },
  ];
  const kioskList = [
    {label: "K1", value:"K1"},
    {label: "K2", value:"K2"}
  ];
  const hostType = [{label: "Motorcycle", value: 1},{label: "Charging Station", value: 2}]
  const kioskIssueTypes = [
    { label: "Electrical failure", value: "1" },
    { label: "Fire or smoke", value: "2" },
    { label: "Temperature rise", value: "3"},
    { label: "Battery swap", value: "4" },
    { label: "Mechanical failure", value: "5" },
    { label: "Noise", value: "6" },
    { label: "Other", value: "0" },
  ]

  const issueLevel = [
    { label: "Level 0", value: 0 },
    { label: "Level 1", value: 1 },
    { label: "Level 2", value: 2 },
    { label: "Level 3", value: 3 },
  ]

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: typeof ADD_ISSUE_FORMIK.initialValues) => {
      dispatch(
        editIssue(
          {
            assigneeId: values.assignee,
            type: Number(values.issue_type),
            vehicleId: values.vehicle_id,
            dueDate: values.due_date || new Date(),
            riderId: values.driver_name,
            status: values.issue_status,
            issue_host_id: values.issue_host_id || "",
            kiosk_issue_type: Number(values.kiosk_issue_type),
            type_description: values.type_description,
            issue_host:values.issue_host,
            issue_level: values.issue_level || 0,
          },
          selectedIssue?.id || ""
        )
      );
      onCancel();
    },
    [dispatch, onCancel, selectedIssue?.id]
  );

  const initialValues = useMemo(
    () => ({
      issue_type: selectedIssue?.type.toString() || "",
      vehicle_id: selectedIssue?.vehicleId || "",
      driver_name: selectedIssue?.riderId || "",
      assignee: selectedIssue?.assignedTo || "",
      due_date: selectedIssue?.dueDate && selectedIssue?.dueDate >= 0 ? getDateFromSeconds(selectedIssue?.dueDate) : "",
      issue_occured: selectedIssue?.addedAt && selectedIssue?.addedAt >= 0 ? getDateFromSeconds(selectedIssue?.addedAt) : "",
      issue_status: selectedIssue?.status,
      issue_host: selectedIssue?.issueHost || 1,
      kiosk_issue_type: selectedIssue?.kioskIssueType.toString() || "",
      issue_host_id: selectedIssue?.issueHostId || "",
      issue_level: selectedIssue?.issueLevel || 0,
    }),
    [selectedIssue]
  );

  useEffect(() => {
    if (!dropdownVehicles) {
      dispatch(getDropdownVehicles());
    }
    if (!adminUsers) {
      dispatch(fetchRiderUsers());
      dispatch(fetchAdminUsers());
    }
  }, [dispatch, dropdownVehicles, adminUsers]);
  return (
    <Formik
      initialValues={initialValues as typeof ADD_ISSUE_FORMIK.initialValues}
      validationSchema={ADD_ISSUE_FORMIK.validationSchema}
      onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit, errors, isSubmitting }) => (
        <div className="form-container">
          <Title level={5}>Edit Issue</Title>
          <Text className="description" type="secondary">
            Modify the information below and hit Save.
          </Text>
          <Form className="--issues-modal-form" onFinish={handleSubmit}>
            <FormItem
                verticalAlign="flex-start"
                error={errors.issue_type}
                label="Product"
                required
            >
              <RadioGroup
                  options={hostType}
                  value={values.issue_host}
                  disabled={true}
                  onChange={(selected) => {
                    setFieldValue("issue_host", selected.target.value);setHost(selected.target.value);
                  }}
              />
            </FormItem>
            <FormItem
              verticalAlign="flex-start"
              error={errors.issue_type}
              label="Issue Type"
              required>
              { host == 1 ? <RadioGroup
                      options={metaData.time_range.options}

                      value={values.issue_type}
                      onChange={(selected) => {
                        setFieldValue("issue_type", selected.target.value);
                      }}
                  /> :
                  <RadioGroup
                      options={kioskIssueTypes}
                      value={values.kiosk_issue_type}
                      onChange={(selected) => {
                        setFieldValue("kiosk_issue_type", selected.target.value);
                      }}
                  />
              }
            </FormItem>
              {host == 1 && <FormItem error={errors.vehicle_id} label="Vehicle ID">
                <SelectSearch
                    value={values.vehicle_id}
                    items={vehiclesOptions}
                    setFieldValue={setFieldValue}
                    fieldName="vehicle_id"
                    placeholder="Select Vehicle"
                    type={errors.vehicle_id ? "error" : "success"}
                    disabled
                />
              </FormItem> }
              {host == 2 && <FormItem error={errors.issue_host_id} label="Host ID">
                <SelectSearch disabled={true}
                    value={values.issue_host_id}
                    items={kioskList}
                    setFieldValue={setFieldValue}
                    fieldName="issue_host_id"
                    placeholder="Select Kisok"
                    type={errors.issue_host_id ? "error" : "success"}
                />
              </FormItem>
              }


            <FormItem label="Assignee" error={errors.assignee}>
              <SelectSearch
                value={values.assignee}
                items={adminOptions}
                setFieldValue={setFieldValue}
                fieldName="assignee"
                placeholder="Select Assignee"
              />
            </FormItem>
            {host == 1 && <FormItem label="Vehicle Rider" error={errors.driver_name}>
              <SelectSearch
                value={values.driver_name}
                items={usersOptions}
                setFieldValue={setFieldValue}
                fieldName="driver_name"
                placeholder="Select Rider"
                disabled
              />
            </FormItem> }
            <FormItem label="Due Date">
              <DatePicker
                value={values.due_date}
                size="middle"
                placeholder="Select "
                format="DD MMM YYY"
                onChange={(date) => setFieldValue("due_date", date)}
                disabledDate={disabledDate}
              />
            </FormItem>
            <FormItem label="Issue Occured">
              <DatePicker
                size="middle"
                value={values.issue_occured}
                placeholder="Select "
                className="due-date"
                format="DD MMM YYY"
                onChange={(date) => setFieldValue("issue_occured", date)}
                disabledDate={disabledOccurDate}
              />
            </FormItem>
            <FormItem label="Status" error={errors.issue_status}>
              <SelectSearch
                  value={values.issue_status}
                  items={issueStatus}
                  setFieldValue={setFieldValue}
                  fieldName="issue_status"
                  placeholder="Select Status"
              />
            </FormItem>
            {userType == 1 || userType == 2 &&
                <FormItem label="Category" error={errors.issue_level}>
                  <SelectSearch
                      value={values.issue_level}
                      items={issueLevel}
                      setFieldValue={setFieldValue}
                      fieldName="issue_level"
                      placeholder="Select Category Level"
                  />
                </FormItem>
            }
            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
