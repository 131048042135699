import React, { useMemo } from "react";
import numeral from "numeral";

import { classNames, getMaxBar, range } from "utils/helpers";
import { BarDataItem } from "types";

interface ComponentProps {
  title?: string | JSX.Element;
  data?: BarDataItem[];
  scaleVisibility?: boolean;
  sameBarHeight?: boolean;
  renderValue?: (data: BarDataItem) => React.ReactNode;
}

const mockData = [
  {
    name: "Delivery",
    value: 10,
    totalValue: 20,
    color: "#F57400",
    totalColor: "#FEEDDE",
  },
  {
    name: "Maintenance",
    value: 30,
    totalValue: 40,
    color: "#4580D9",
    totalColor: "#E7EFFA",
  },
  {
    name: "Roadside Assistance",
    value: 5,
    totalValue: 10,
    color: "#7230C8",
    totalColor: "#EDE4F8",
  },
];

const SECTIONS = 7;

export default function FixedPlacementBarChart(props: ComponentProps) {
  const {
    title,
    data = mockData,
    sameBarHeight = false,
    scaleVisibility = true,
    renderValue,
  } = props;
  const originalMaxValue = useMemo(() => getMaxBar(data), [data]);
  const singleScale = useMemo(() => originalMaxValue / SECTIONS, [
    originalMaxValue,
  ]);
  const maxValue = useMemo(() => (SECTIONS + 1) * singleScale, [singleScale]);
  const scaleArr = useMemo(
    () =>
      range(SECTIONS + 1).map((val, index) => {
        return singleScale * index;
      }),
    [singleScale]
  );
  const scaleClasses = useMemo(
    () =>
      classNames({
        "scale-structure": true,
        "--disable": !scaleVisibility,
      }),
    [scaleVisibility]
  );
  const scaleLineClasse = useMemo(
    () =>
      classNames({
        "scale-line": true,
        "--disable": !scaleVisibility,
      }),
    [scaleVisibility]
  );
  const innerBarClasses = useMemo(
    () => classNames({ "inner-bar": true, "--same-height": sameBarHeight }),
    [sameBarHeight]
  );

  return (
    <div className="fixed-placement-bar-chart">
      {title && <section className="__title">{title}</section>}
      <div className="__scale">
        <div className={scaleClasses}>
          {scaleArr.map((label, index) => (
            <div key={`value-${index}`} className="vertical-item">
              <div className="line" />
              <span className="label">{numeral(label).format("00a")}</span>
            </div>
          ))}
          <div key={`ending-line`} className="vertical-item">
            <div className="line" />
            <span className="label">{numeral(maxValue).format("00a")}</span>
          </div>
        </div>
        <div className="bars">
          {data.map((bar, index) => (
            <div key={`bar-item-${index}`} className="bar-item">
              <p className="bar-item-label">{bar.name}</p>
              <div
                className="outer-bar"
                title={bar.totalValue.toString()}
                style={
                  {
                    "--outer_bar_width": `${
                      (bar.totalValue / maxValue) * 100 || 0
                    }%`,
                    "--outer_bar_color": bar.totalColor,
                  } as React.CSSProperties
                }>
                <div
                  className={innerBarClasses}
                  title={bar.value.toString()}
                  style={
                    {
                      "--inner_bar_width": `${
                        (bar.value / bar.totalValue) * 100 || 0
                      }%`,
                      "--inner_bar_color": bar.color,
                    } as React.CSSProperties
                  }
                />
                {renderValue && (
                  <div className="render-content">{renderValue(bar)}</div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={scaleLineClasse} />
      </div>
    </div>
  );
}
