import { CommonActions, CommonState } from "types/store";

const initialState: CommonState = {
  loading: false,
};

export default function commonReducer(
  state: CommonState = initialState,
  action: CommonActions
): CommonState {
  switch (action.type) {
    case "SET_MODAL_NAME":
      return { ...state, modalName: action.payload };
      case "SET_ERROR_MESSAGE":
        return {
            ...state,
            errorMessage: action.payload,
        };
    case "TOGGLE_LOADING_STATE":
      return { ...state, loading: action.payload };
    case "SET_TOKEN":
      return { ...state, token: action.payload };
    case "SET_REFRESH_TOKEN":
      return { ...state, refreshToken: action.payload };
    case "SET_CONSIGNEE_ID":
      return { ...state, clientOrgId: action.payload };
    case "SET_ASSETOWNER_ID":
      return { ...state, ownerOrgId: action.payload };
    case "SET_USER_TYPE":
      return { ...state, userType: action.payload };
    case "SET_RESPONSE_STATE":
      return { ...state, responseState: action.payload };
    case "SET_PHONE":
      return { ...state, phone: action.payload };
    case "SET_ORGANIZATION_ID":
      return { ...state, organizationId: action.payload };
    default:
      return state;
  }
}
