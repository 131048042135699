interface ComponentProps {
  name: string | JSX.Element;
  value: number | string;
  render?: JSX.Element;
  link?: boolean;
  buttonText?: string;
  isAmount?: boolean;
  paymentPer?: string;
}



export default function VehicleTile(props: ComponentProps) {
  const {
    name,
    value,
    render,
    link,
    isAmount,
    paymentPer,
    buttonText = "View",
  } = props;

  return (
    
    <article className="tile-card vehicle-tile">
      <section className="tile-left-side">
           <span className="tile-name">
          {name}{" "}
          {paymentPer && <span className="payments-per">/{paymentPer}</span>}
        </span>
        <span className="tile-value">
          {value}
          {isAmount && <span className="amount-unit">PKR</span>}
        </span>
     
        {value === 0 || link === false ? (
          ""
        ) : (
          <a className="tile-link">{buttonText}</a>
        )}
      </section>
      <section className="tile-right-side">{render}</section>
    </article>
  );
}

