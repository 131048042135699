import { Tag as TagAnt } from "antd";
import { TagProps } from "antd/lib/tag";

interface ComponentProps {
  customClasses?: string;
}

export default function Tag(props: ComponentProps & TagProps) {
  const { customClasses = "", children, ...rest } = props;

  return (
    <TagAnt {...rest} className={`common-tag ${customClasses}`}>
      {children}
    </TagAnt>
  );
}
