/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: fcm_user_token_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as fcm_user_token_service_pb from './fcm_user_token_service_pb';


export class FcmUserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddFcmUserToken = new grpcWeb.MethodDescriptor(
    '/api.FcmUserService/AddFcmUserToken',
    grpcWeb.MethodType.UNARY,
    fcm_user_token_service_pb.AddFcmUserTokenRequest,
    fcm_user_token_service_pb.AddFcmUserTokenResponse,
    (request: fcm_user_token_service_pb.AddFcmUserTokenRequest) => {
      return request.serializeBinary();
    },
    fcm_user_token_service_pb.AddFcmUserTokenResponse.deserializeBinary
  );

  addFcmUserToken(
    request: fcm_user_token_service_pb.AddFcmUserTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_user_token_service_pb.AddFcmUserTokenResponse>;

  addFcmUserToken(
    request: fcm_user_token_service_pb.AddFcmUserTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_user_token_service_pb.AddFcmUserTokenResponse) => void): grpcWeb.ClientReadableStream<fcm_user_token_service_pb.AddFcmUserTokenResponse>;

  addFcmUserToken(
    request: fcm_user_token_service_pb.AddFcmUserTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_user_token_service_pb.AddFcmUserTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmUserService/AddFcmUserToken',
        request,
        metadata || {},
        this.methodDescriptorAddFcmUserToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmUserService/AddFcmUserToken',
    request,
    metadata || {},
    this.methodDescriptorAddFcmUserToken);
  }

  methodDescriptorGetFcmUserTokenByUserID = new grpcWeb.MethodDescriptor(
    '/api.FcmUserService/GetFcmUserTokenByUserID',
    grpcWeb.MethodType.UNARY,
    fcm_user_token_service_pb.GetFcmUserTokenByUserIdRequest,
    fcm_user_token_service_pb.GetFcmUserTokenByUserIdResponse,
    (request: fcm_user_token_service_pb.GetFcmUserTokenByUserIdRequest) => {
      return request.serializeBinary();
    },
    fcm_user_token_service_pb.GetFcmUserTokenByUserIdResponse.deserializeBinary
  );

  getFcmUserTokenByUserID(
    request: fcm_user_token_service_pb.GetFcmUserTokenByUserIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<fcm_user_token_service_pb.GetFcmUserTokenByUserIdResponse>;

  getFcmUserTokenByUserID(
    request: fcm_user_token_service_pb.GetFcmUserTokenByUserIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: fcm_user_token_service_pb.GetFcmUserTokenByUserIdResponse) => void): grpcWeb.ClientReadableStream<fcm_user_token_service_pb.GetFcmUserTokenByUserIdResponse>;

  getFcmUserTokenByUserID(
    request: fcm_user_token_service_pb.GetFcmUserTokenByUserIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: fcm_user_token_service_pb.GetFcmUserTokenByUserIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.FcmUserService/GetFcmUserTokenByUserID',
        request,
        metadata || {},
        this.methodDescriptorGetFcmUserTokenByUserID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.FcmUserService/GetFcmUserTokenByUserID',
    request,
    metadata || {},
    this.methodDescriptorGetFcmUserTokenByUserID);
  }

}

