import { useCallback, useState } from "react";
import { Typography } from "antd";
import { classNames } from "utils/helpers";
import {
  FullscreenOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const catalog = [
  {
    imageUrl: "https://all-flux-documents.nyc3.digitaloceanspaces.com/zyp_pics/image11.png",
    name: "",
  },
  { imageUrl: "https://all-flux-documents.nyc3.digitaloceanspaces.com/zyp_pics/image10.png", name: "" },
  { imageUrl: "https://all-flux-documents.nyc3.digitaloceanspaces.com/zyp_pics/image11.png", name: "" },
  { imageUrl: "https://all-flux-documents.nyc3.digitaloceanspaces.com/zyp_pics/image12.png", name: "" },
  { imageUrl: "https://all-flux-documents.nyc3.digitaloceanspaces.com/zyp_pics/image13.png", name: "" },
  // { imageUrl: "https://wallpapercave.com/wp/wp2099305.jpg", name: "" },
];

export default function ImageCatalog() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChange = useCallback((index) => {
    setSelectedIndex(index);
  }, []);

  const onNext = useCallback(() => {
    setSelectedIndex((index) => {
      return index === catalog.length - 1 ? index : index + 1;
    });
  }, []);

  const onBack = useCallback(() => {
    setSelectedIndex((index) => {
      return index === 0  ? index : index - 1;
    });
  }, []);

  return (
    <div className="image-container">
      <div
        className="vehicle-image"
        style={
          {
            "--vehicle-catalog-image": `url(${catalog[selectedIndex].imageUrl})`,
          } as React.CSSProperties
        }
      >
        {/*<Text className="full-screen">*/}
        {/*  Photo Taken on Jan 01, 2020 by Harris Shams <FullscreenOutlined />*/}
        {/*</Text>*/}
        <div className="navigators">
          <div role="button" className="nav-btn" onClick={onBack}>
            <LeftOutlined />
          </div>
          <div role="button" className="nav-btn" onClick={onNext}>
            <RightOutlined />
          </div>
        </div>
      </div>
      <div className="vehicle-image-catalog">
        {catalog.map((image, index) => {
          const classes = classNames({
            thumbnail: true,
            "--selected": index === selectedIndex,
          });
          return (
            <img
              key={image.imageUrl}
              className={classes}
              src={image.imageUrl}
              onClick={() => onChange(index)}
            />
          );
        })}
      </div>
    </div>
  );
}
