import { Formik } from "formik";
import { Form, Button, Typography, Upload, TimePicker, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";

import {
  userRoles,
} from "components/tab-content/users-content/__mock__";
import {
  getDownloadedBytes,
  getDropdownVehicles,
  setModalName,
  setSelectedUser,
  updateUser,
} from "store/actions";
import { fileToByteArray, splitFullName } from "utils/helpers";
import { AppState } from "store";
import { USER_FORMIK } from "utils/formik-data";
import { FormItem, SelectSearch } from "components";
import { UserValues } from "types/formik";
import { parseVehicles } from "utils/parsers";
import { uploadIcon } from "svgs";
import { UploadFile } from "antd/lib/upload/interface";
import { Input } from "components/base";

const { Title, Text } = Typography;

export default function EditUser() {
  const [isEditShift, setIsEditShift] = useState(false);

  const {
    users: { selectedUser },
    vehicles: { dropdownVehicles },
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  const dispatch = useDispatch();

    let userRolesByCurrentUser = userRoles
    if (userType == 2) {
        userRolesByCurrentUser = userRoles.filter((role => role.value != "1" && role.value != "3"))
    } else if (userType == 3 || userType == 5) {
        userRolesByCurrentUser = userRoles.filter((role => role.value != "1" && role.value != "5" && role.value != "2"))
    }

  const vehiclesOptions = useMemo(() => parseVehicles(dropdownVehicles), [
    dropdownVehicles,
  ]);
    const [rider, setRider] = useState(0);

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const downloadFile = useCallback(() => {
    if (selectedUser?.documentLink) {
      dispatch(
        getDownloadedBytes(selectedUser.documentLink, (resp) => {
          dispatch(
            setSelectedUser({ ...selectedUser, document: resp.getFileData() })
          );
        })
      );
    }
  }, [dispatch, selectedUser]);

  useEffect(() => {
    downloadFile();
  }, [downloadFile]);

  const onSubmit = useCallback(
    (values: UserValues) => {
      const splitedName = splitFullName(values.full_name);
      dispatch(
        updateUser(
          {
            email: values.email || "",
            firstName: splitedName.firstName,
            lastName: splitedName.lastName,
            type: Number(values.role),
            number: `+${values.phone_no}`,
            documentId: values.document_id || "",
            licenceNo: values.licence_no || "",
            vehicleId: values.vehicle || [],
            document: values.document_file || selectedUser?.document || "",
            documentName:
              values.document_file_raw?.name ||
              selectedUser?.documentName ||
              "",
            documentExt:
              values.document_file_raw?.type ||
              selectedUser?.documentType ||
              "",
              organizationId: selectedUser?.organization_id || "",
          },
          selectedUser?.id || ""
        )
      );
    },
    [dispatch, selectedUser]
  );

  const initialValues: UserValues = useMemo(() => {
      setRider(selectedUser?.type.toString() == '3' ? 1 : 0)
    return selectedUser
      ? {
          full_name: selectedUser?.name,
          phone_no: selectedUser?.phone.trim().replace("+", ""),
          role: selectedUser?.type.toString(),
          email: selectedUser?.email,
          document_id: selectedUser?.documentId,
          licence_no: selectedUser?.lincenceNo,
          document_file: undefined,
          document_file_raw: undefined,
          vehicle: selectedUser?.vehicle?.map(v => v.vehicleId),
          dial_code: "",
          fileList: selectedUser?.documentName
            ? [
                {
                  uid: selectedUser?.documentName,
                  size: 1000,
                  name: selectedUser?.documentName,
                  type: selectedUser?.documentType,
                } as UploadFile<any>,
              ]
            : undefined,
            organization_id: selectedUser?.organization_id,
        }
      : USER_FORMIK.initialValues;
  }, [selectedUser, vehiclesOptions]);

  useEffect(() => {
    if (!dropdownVehicles) {
        console.log("reached here", dropdownVehicles)
      dispatch(getDropdownVehicles());
    }
  }, [dispatch, dropdownVehicles]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={USER_FORMIK.validationSchema}
      enableReinitialize>
      {({
        values,
        setFieldValue,
        handleChange,
        handleSubmit,
        errors,
        isValid,
        dirty,
      }) => (
        <div className="form-container">
          <Title level={5}>Edit User</Title>
          <Text className="description" type="secondary">
            Modify the information below and hit Save
          </Text>
          <Form className="--edit-users-form" onFinish={handleSubmit}>
            <section className="">
              {!isEditShift ? (
                <>
                  <span className="section-title">
                    Profile Information{" "}
                    {/* <span className="step">1 out of 2 steps</span> */}
                  </span>
                  <FormItem
                    label="Phone Number"
                    error={errors.phone_no}
                    required>
                    <PhoneInput
                      country="ae"
                      inputProps={{
                        required: true,
                        autoFocus: true,
                        name: "phone_no",
                      }}
                      inputClass="login-phone-input --user"
                      buttonClass="user-flag"
                      placeholder="000000"
                      value={values.phone_no}
                      onChange={(val, data: { dialCode: string }) => {
                        setFieldValue("dial_code", data.dialCode);
                        setFieldValue("phone_no", val);
                      }}
                    />
                  </FormItem>
                  <FormItem label="Email Address" error={errors.email} required>
                    <Input
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      placeholder="user@email.com"
                    />
                  </FormItem>
                  <FormItem label="Full Name" error={errors.full_name} required>
                    <Input
                      name="full_name"
                      value={values.full_name}
                      onChange={handleChange}
                      placeholder="Name"
                    />
                  </FormItem>
                  <FormItem label="Role" error={errors.role} required>
                    <SelectSearch
                        disabled={true}
                      value={values.role}
                      items={userRolesByCurrentUser}
                      setFieldValue={setFieldValue}
                      onChangeProp={(el) => { el == '3' ? setRider(1) : setRider(0)}}
                      fieldName="role"
                      placeholder="Select Role"

                    />
                  </FormItem>
                  {rider > 0 && <FormItem label="Assigned Vehicle" error={errors.vehicle}>
                    <SelectSearch
                      isMultiple
                      value={values.vehicle || ""}
                      items={vehiclesOptions}
                      setFieldValue={setFieldValue}
                      fieldName="vehicle"
                      placeholder="Select"
                      onChangeProp={(value) => {
                        setFieldValue("vehicle", value);
                      }}
                    />
                  </FormItem> }
                  { rider > 0 && <FormItem label="Document ID" error={errors.document_id}>
                    <Input
                      name="document_id"
                      value={values.document_id}
                      onChange={handleChange}
                      placeholder="Document ID"
                    />
                  </FormItem> }
                    { rider > 0 &&  <FormItem label="License Number" error={errors.licence_no}>
                    <Input
                      name="licence_no"
                      value={values.licence_no}
                      onChange={handleChange}
                      placeholder="000000"
                    />
                  </FormItem> }
                  {rider > 0 && <FormItem
                    label="Document"
                    error={errors.document_file}
                    verticalAlign="flex-start">
                    <Upload.Dragger
                      name="document_file"
                      multiple={false}
                      fileList={values.fileList}
                      onChange={({ fileList }) => {
                        // Getting total number of files
                        const totalFiles = fileList.length;

                        // If multiple files, pick last file
                        if (totalFiles > 1) {
                          setFieldValue("fileList", [fileList[totalFiles - 1]]);
                        } else {
                          setFieldValue("fileList", fileList);
                        }
                      }}
                      beforeUpload={async (file) => {
                        const unit8Arr = await fileToByteArray(file);
                        setFieldValue("document_file", unit8Arr);
                        setFieldValue("document_file_raw", file);
                      }}>
                      {uploadIcon}
                      <p className="ant-upload text">
                        Drag the document here or
                        <span className="secondary-color"> browse</span> from
                        system
                      </p>
                    </Upload.Dragger>
                  </FormItem> }{" "}
                </>
              ) : (
                <>
                  <span className="section-title">
                    Shift Information{" "}
                    <span className="step">2 out of 2 steps</span>
                  </span>
                  <FormItem label="Start Shift" required>
                    <div className="start-shift">
                      <TimePicker format="HH:mm" className="start-shift" />
                      <Radio.Group
                        name="time"
                        defaultValue="am"
                        buttonStyle="solid"
                        className="filter-switch --edit_shift --primary">
                        <Radio.Button value="am">am</Radio.Button>
                        <Radio.Button value="pm">pm</Radio.Button>
                      </Radio.Group>
                    </div>
                  </FormItem>
                  <FormItem label="End Shift" required>
                    <div className="end-shift">
                      <TimePicker format="HH:mm" name="end-shift" />
                      <Radio.Group
                        name="time"
                        defaultValue="am"
                        buttonStyle="solid"
                        className="filter-switch --edit_shift --primary">
                        <Radio.Button value="am">am</Radio.Button>
                        <Radio.Button value="pm">pm</Radio.Button>
                      </Radio.Group>
                    </div>
                  </FormItem>
                  <FormItem label="Vehicle ID" required>
                    <SelectSearch
                      value={""}
                      items={[]}
                      setFieldValue={setFieldValue}
                      placeholder="Enter or Choose Location"
                    />
                  </FormItem>
                </>
              )}
            </section>
            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Cancel
              </Button>
              {/* <Button
                onClick={() => setIsEditShift(false)}
                type="primary"
                disabled={!isEditShift}
              >
                Prev
              </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid && dirty}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
