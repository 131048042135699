import { Collapse, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import numeral from "numeral";

import { AppState } from "store";
import { cancelIcon, chevronClose, chevronOpen } from "svgs";
import { Checkbox, SwitchButton, Tag } from "components/base";
import { CheckboxPanelList, RadioPanelList } from "./sub-components";
import { useCallback, useEffect, useMemo } from "react";
import {
  onChangeIssuesFilters,
  onChangeRootIssuesFilters,
  onChangeTimeRangeIssuesFilters,
  setAssigneeFilter,
  setIssuesListType,
} from "store/actions";
import { getAllCheckedStatus, getIndeterminate } from "utils/helpers";
import { IssuesState } from "types";

export default function IssuesFilter() {
  const dispatch = useDispatch();
  const {
    issues: { filters },
  } = useSelector<AppState, AppState>((state) => state);
  const {
    original: {
      "Issue Status": originalIssueStatus = [],
      "Issue Type": originalIssueType = [],
      "Time Range": originalTimeRange = [],
      Assignee: originalAssignee = [],
    },
    updated: {
      "Issue Status": updatedIssueStatus = [],
      "Issue Type": updatedIssueType = [],
      "Time Range": updatedTimeRange = [],
      Assignee: updatedAssignee = [],
    },
  } = filters;
  const { "Time Range": timeRangeUpdated, ...restUpdated } = filters.updated;
  const { "Time Range": timeRangeOriginal, ...restOriginal } = filters.original;

  const isAllChecked = useMemo(() => {
    return getAllCheckedStatus(restUpdated, restOriginal);
  }, [restUpdated, restOriginal]);
  const isIndeterminate = useMemo(() => {
    return getIndeterminate(restUpdated, restOriginal);
  }, [restUpdated, restOriginal]);

  const onCheckboxListChange = useCallback(
    (name, value, type) => {
      dispatch(onChangeIssuesFilters(name, value, type));
    },
    [dispatch]
  );
  const onTimeRangeChange = useCallback(
    (value, range) => {
      dispatch(onChangeTimeRangeIssuesFilters(value, range));
    },
    [dispatch]
  );
  const onRootChange = useCallback(
    (event) => {
      dispatch(onChangeRootIssuesFilters(event.target.checked));
    },
    [dispatch]
  );
  const onClearAll = useCallback(() => {
    dispatch(onChangeRootIssuesFilters(false));
  }, [dispatch]);
  const originalAssigneeWithTag = useMemo(
    () =>
      originalAssignee.map((assignee) => (console.log("assignee: ", assignee), {
        ...assignee,
        // TODO: Change inlinle styling
        render: (
          <></>
          // <Tag style={{ float: "right" }}>
          //   {numeral(assignee.render).format("0 a")}
          // </Tag>
        ),
      })),
    [originalAssignee]
  );
  const onListTypeChange = useCallback(
    (selected: IssuesState["filters"]["listType"]) => {
      dispatch(setIssuesListType(selected));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setAssigneeFilter());
  }, [dispatch]);

  return (
    <div className="common-filter">
      <div className="__filter-header ">
        <div className="all-options">
          <Checkbox
            customClasses="--filter-light"
            onChange={onRootChange}
            checked={isAllChecked}
            indeterminate={isIndeterminate}>
            <span className="filter-text">FILTERS</span>
          </Checkbox>
          <Button
            className="clear-all-btn"
            icon={cancelIcon}
            type="link"
            danger
            onClick={onClearAll}>
            Clear All
          </Button>
        </div>
        <SwitchButton
          label=""
          options={["current", "archive"]}
          defaultOption={"current"}
          onChange={(e) => onListTypeChange(e.target.value)}
          type="secondary"
        />
      </div>
      <Collapse
        className="__common-collapse --issues"
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (isActive ? chevronOpen : chevronClose)}
        defaultActiveKey={Object.keys(filters.original)}>
        {/*<CheckboxPanelList*/}
        {/*  header={""}*/}
        {/*  key={"Issue Type"}*/}
        {/*  name={"Issue Type"}*/}
        {/*  options={originalIssueType}*/}
        {/*  value={updatedIssueType.map((row) => row.value)}*/}
        {/*  onChange={onCheckboxListChange}*/}
        {/*/>*/}
        <RadioPanelList
          header={""}
          option={updatedTimeRange[0]}
          key={"Time Range"}
          name={"Time Range"}
          options={originalTimeRange}
          value={updatedTimeRange[0].value}
          onChange={onTimeRangeChange}
        />
        {/*<CheckboxPanelList*/}
        {/*  header={""}*/}
        {/*  key={"Issue Status"}*/}
        {/*  name={"Issue Status"}*/}
        {/*  options={originalIssueStatus}*/}
        {/*  value={updatedIssueStatus.map((row) => row.value)}*/}
        {/*  onChange={onCheckboxListChange}*/}
        {/*/>*/}
        <CheckboxPanelList
          header={""}
          key={"Assignee"}
          name={"Assignee"}
          options={originalAssigneeWithTag}
          value={updatedAssignee.map((row) => row.value)}
          onChange={onCheckboxListChange}
          suffix
          search
        />
      </Collapse>
    </div>
  );
}
