import { Alert as AntAlert, Typography } from "antd";
import { AlertProps } from "antd/lib/alert";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { setResponseState } from "store/actions";

const { Text } = Typography;

function customMessage(message: AlertProps["message"]) {
  return (
    <article className="message-wrapper">
      <Text className="alert-message">{message}</Text>
    </article>
  );
}

export default function Alert() {
  const { responseState } = useSelector<AppState, AppState["common"]>(
    (state) => state.common
  );
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(setResponseState(undefined));
  }, [dispatch]);

  return responseState ? (
    <article
      className={`alert-container ${
        responseState.type === "success" ? "success-border" : "error-border"
      }`}>
      <AntAlert
        message={customMessage(responseState.message)}
        type={responseState.type}
        showIcon
        closable
        onClose={onClose}
      />
    </article>
  ) : (
    <div />
  );
}
