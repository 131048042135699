import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import TableView from "./table-view";
import ProtectedRoute from "components/protected-route";
export default function BatteryModelContent() {
    return (
        <div className="vehicle-models-content">
            <Switch>
                <ProtectedRoute exact path={ROUTES.battery_model}>
                    <Redirect to={ROUTES.battery_model_list} />
                </ProtectedRoute>
                <ProtectedRoute exact path={ROUTES.battery_model_list}>
                    <TableView />
                </ProtectedRoute>
                <ProtectedRoute path={`${ROUTES.battery_model}/*`}>
                    <Redirect to={ROUTES.battery_model_list} />
                </ProtectedRoute>
            </Switch>
        </div>
    );
}
