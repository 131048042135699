import { FinancialsActions, FinancialsState } from "types";

import { financialsTableData } from "components/tab-content/financials-content/__mock__";

const initialState: FinancialsState = {
  paymentList: financialsTableData(),
  search: "",
  showbulkActionsFinancials: false,
};

export default function paymentReducer(
  state: FinancialsState = initialState,
  action: FinancialsActions
): FinancialsState {
  switch (action.type) {
    case "SET_PAYMENTS":
      return { ...state, paymentList: action.payload };
    case "SET_FINANCIALS_BULK_ACTIONS":
      return {
        ...state,
        showbulkActionsFinancials: action.payload,
      };
    default:
      return state;
  }
}
