import { Typography } from "antd";

import {cityIcon, areaIcon, bikeIcon, editIcon} from "svgs";
import { usersTableData } from "./__mock__";
import {ZonesState} from "../../../types";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store";
import DropdownAction from "../battery/dropdown-action";
import {useCallback} from "react";
import {setModalName, setSelectedBattery, setSelectedZone} from "../../../store/actions";
import {useHistory} from "react-router-dom";
import {ROUTES} from "../../../utils/constants";

const { Text } = Typography;

export function renderEmptyContent(value: string) {
  return {
    children: value,
    props: {
      colSpan: 0,
    },
  };
}

export function userIconCell(
  value: string,
  row: typeof usersTableData[number]
) {
  return <figure className="user-icon-container">{row.zone_icon}</figure>;
}

export function UserIconCellTable(
  value: string,
  row: typeof usersTableData[number],
  index: number
) {
  return (
    <figure className="user-icon-container-table">
      <img
        src={`https://picsum.photos/22?random=${index}`}
        alt="user pic"
        className="round-image"
      />
      {row.driver_name}
    </figure>
  );
}

export function userDetailCell(
  value: string,
  row: typeof usersTableData[number]
) {
  return (
    <p className="user-detail">
      <Text className="user-id" type="secondary">
        <strong>{row.zone_name}</strong>
      </Text>
      <Text type="secondary" className="user-detail-wrapper">
        <figure className="list-text-container">
          <figcaption>
            <span>
              Zone created by {row.driver_name} on {row.date_and_time}
            </span>
          </figcaption>
        </figure>

        <figure className="list-text-container">
          <span className="icon">{bikeIcon}</span>
          <figcaption>
            <span>{row.bikes_assigned}</span>
            <span>Assigned</span>
          </figcaption>
        </figure>

        <figure className="list-text-container">
          <span className="icon"> {cityIcon}</span>
          <figcaption>
            <span>{row.city_name}</span>
          </figcaption>
        </figure>

        <figure className="list-text-container">
          <span className="icon"> {areaIcon}</span>
          <figcaption>
            <span>{row.area}</span>
            <span>sq. km.</span>
          </figcaption>
        </figure>
      </Text>
    </p>
  );
}

export function ActionMenu(
    value:string,
    row: {
      raw:ZonesState["selectedZone"]
    }
){
  const dispatch = useDispatch();
  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  const history = useHistory();

  const onEditZone = useCallback(() => {
    dispatch(setSelectedZone(row.raw));
    history.push(ROUTES.edit_zone)
  }, [dispatch, row.raw]);
  if (userType == 1 || userType == 2 || userType == 5) {
    return (
        <section className="table-action-container show-on-hover">

      <span className="edit-icon icon" onClick={onEditZone} title="edit issue">
        {editIcon}
      </span>

        </section>
    );
  }
  return <></>

}
