import { useDispatch } from "react-redux";
import { Form, Button, Typography, Radio, TimePicker } from "antd";
import { useCallback } from "react";
import { Formik } from "formik";

import { setModalName } from "store/actions";
import { FormItem, SelectSearch } from "components";

const { Title, Text } = Typography;

export default function EditShiftForm() {
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <Formik initialValues={{}} onSubmit={(values) => console.trace(values)}>
      {({ setFieldValue }) => (
        <div className="form-container">
          <Title level={5}>Shift Details</Title>
          <Text className="description" type="secondary">
            Please provide the user’s shift details for better fleet performance.
          </Text>
          <section className="--edit-shift-form">
            <FormItem label="Start Shift" required>
              <div className="start-shift">
                <TimePicker format="HH:mm" className="start-shift" />
                <Radio.Group
                  name="time"
                  defaultValue="am"
                  buttonStyle="solid"
                  className="filter-switch --edit_shift --primary">
                  <Radio.Button value="am">am</Radio.Button>
                  <Radio.Button value="pm">pm</Radio.Button>
                </Radio.Group>
              </div>
            </FormItem>
            <FormItem label="End Shift" required>
              <div className="end-shift">
                <TimePicker format="HH:mm" name="end-shift" />
                <Radio.Group
                  name="time"
                  defaultValue="am"
                  buttonStyle="solid"
                  className="filter-switch --edit_shift --primary">
                  <Radio.Button value="am">am</Radio.Button>
                  <Radio.Button value="pm">pm</Radio.Button>
                </Radio.Group>
              </div>
            </FormItem>
            <FormItem label="Vehicle ID" required>
              <SelectSearch
                value={""}
                items={[]}
                setFieldValue={setFieldValue}
                placeholder="Enter or Choose Location"
              />
            </FormItem>
          </section>

          <div className="action-btn-container">
            <Button className="cancel-btn" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={onCancel}>
              Add
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
