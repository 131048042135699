import { useState, useEffect, useCallback, useMemo } from "react";
import { Select } from "antd";

import { searchIcon, customDropdownIcon } from "svgs";
import { Input } from "components/base";
import { classNames } from "utils/helpers";

const { Option } = Select;

interface ComponentProps {
  items: { value: string | number; label: string }[];
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => void;
  fieldName?: string;
  value: string | string[] | number;
  type?: "success" | "error";
  placeholder: string;
  disabled?: boolean;
  onChangeProp?: (value: string) => void;
  isMultiple?: boolean;
}

export default function SelectSearch(props: ComponentProps) {
  const {
    items = [],
    setFieldValue,
    fieldName = "vehicle_model",
    value,
    type = "success",
    placeholder,
    onChangeProp,
    disabled = false,
    isMultiple,
  } = props;
  const [dropDownItems, setDropDownItems] = useState(items);

  useEffect(() => {
    setDropDownItems(items);
  }, [items]);

  const filterItems = useCallback(
    (event) => {
      const filteredData = items.filter((item) =>
        item.label
          .toLocaleLowerCase()
          .includes(event.target.value.toLocaleLowerCase())
      );
      setDropDownItems(filteredData);
    },
    [items]
  );

  const selectClassnames = classNames({
    "custom-select": true,
    error: type === "error",
  });

  const onChange = useCallback(
    (value) => {
      if (onChangeProp) {
        onChangeProp(value);
      }

      setFieldValue(fieldName, value);
    },
    [setFieldValue, value, onChangeProp]
  );

  const operatedValue = useMemo(() => {
    const selectedOption = dropDownItems.find(
      (option) => option.value === value
    );

    return Array.isArray(value) || selectedOption ? value : "";
  }, [dropDownItems, value]);

  return (
    <Select
      mode={isMultiple ? "multiple" : undefined}
      className={selectClassnames}
      value={operatedValue || undefined}
      placeholder={placeholder}
      onChange={onChange}
      virtual={false}
      dropdownRender={(menu) => (
        <div>
          <div className="select-search-container">
            <Input
              onChange={filterItems}
              prefix={searchIcon}
              placeholder="Search..."
            />
          </div>
          {menu}
        </div>
      )}
      suffixIcon={customDropdownIcon}
      disabled={disabled}
    >
      {dropDownItems.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
}
