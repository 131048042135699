import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableProps } from "antd/lib/table";

import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import { parseIssuesTable } from "utils/parsers";
import {
  setBulkActionsIssues,
  setIssuesSelectedPage,
  setSelectedIssuesRows,
} from "store/actions";
import {
  renderEmptyContent,
  issueIconCell,
  issueDetailCell,
  ActionMenu,
} from "./cell-renders";

const columns: TableProps<any>["columns"] = [
  {
    title: "Issue ID",
    dataIndex: "issue_id",
    width: "70px",
    render: issueIconCell,
  },
  // {
  //   title: "Issue Type",
  //   dataIndex: "issue_type",
  //   colSpan: 7,
  //   render: issueDetailCell,
  // },
  {
    title: "Vehicle ID",
    dataIndex: "vehicle_id",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Name",
    dataIndex: "driver_name",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Created On",
    dataIndex: "date_and_time",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Assignee",
    dataIndex: "assignee",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "Status",
    dataIndex: "status",
    colSpan: 0,
    render: renderEmptyContent,
  },
  {
    title: "",
    dataIndex: "action",
    render: ActionMenu,
    align: "right",
    // width: 56,
    className: "action-menu open-link --issues",
  },
];

export default function ListView() {
  const dispatch = useDispatch();
  const { issues, page, search, selectedIssuesRows } = useSelector<
    AppState,
    AppState["issues"]
  >((state) => state.issues);
  const { selectedPage, totalPages } = page;
  const data = useMemo(
    () => parseIssuesTable(search, issues),
    [issues, search]
  );
  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setIssuesSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setIssuesSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setIssuesSelectedPage(1));
  }, [dispatch]);

  const onChangeSelection = useCallback(
    (value) => {
      dispatch(setSelectedIssuesRows(value));

      if (value.length !== 0) {
        dispatch(setBulkActionsIssues(true));
      } else {
        dispatch(setBulkActionsIssues(false));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Table
        customClasses="--common-list-table"
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        // rowSelection={{
        //   selectedRowKeys: selectedIssuesRows,
        //   onChange: onChangeSelection,
        // }}
      />

      <TablePagination
        page={page}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </>
  );
}
