import { Button, Typography, Upload } from "antd";
import { DraggerProps } from "antd/lib/upload";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setModalName } from "store/actions";
import { uploadIcon } from "svgs";

const { Dragger } = Upload;
const { Title } = Typography;

const props: DraggerProps = {
  name: "file",
  multiple: true,
  onChange: (info) => {
    console.log(info.fileList);
  },
};

export default function ImportZoneForm() {
  const dispatch = useDispatch();
  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onImport = useCallback(() => {
    onCancel();
  }, [onCancel]);
  return (
    <>
      <Title level={5}>Import Zone</Title>

      <Dragger {...props} className="import-csv">
        {uploadIcon}
        <p className="ant-upload text">
          Drag and drop a zones file or
          <span className="secondary-color"> browse</span> the system to select
          one
        </p>
      </Dragger>
      <div className="action-btn-container --vehicle">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onImport} disabled>
          Import
        </Button>
      </div>
    </>
  );
}
