import {BatteryState, FcmNotificationState, NotificationsState} from "../../types";
import {getFormatedDate, capitalizeFirstLetter} from "store/actions"
import {  NOTIFICATION_PRIORITY_STATUS } from "utils/mapped-data";

export function parseFcmNotificationTable(
    list?: FcmNotificationState["notifications"]
) {
    if (list) {
        const data = list.map((notification, index) => ({
            key: index,
            id: notification.id,
            title: notification.title,
            body: notification.body,
            is_read: notification.isRead,
            notification_time: notification.notificationTime,
            action: "",
            raw: notification,
            category: notification.categoryName,
            categoryID: notification.categoryId,
            subCategory: notification.subCategoryName, 
            

        }));
        return data
    }

    return [];
}


export function parseNotificationTable(
    list?: NotificationsState["notificationsCenter"]
) {
    if (list) {
        const data = list.map((notification, index) => ({
            key: index,
            id: notification.id,
            title: notification.title,
            body: notification.body,
            is_read: notification.isRead,
            notification_time: getFormatedDate(notification.notificationTime),
            action: "",
            raw: notification,
            category: capitalizeFirstLetter(notification.categoryName),
            categoryID: notification.categoryId,
            subCategory: notification.subCategoryName, 
            subCategoryId: notification.subCategoryId,
            priority: NOTIFICATION_PRIORITY_STATUS[notification.priority] 
            
         
        }));
        console.log("DAta:",data)
        return data
    }

    return [];
}