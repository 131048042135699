import Detail from "./detail";

export type ComponentType = "gallery" | "stats";

export default function IssueDetail() {

  return (
    <main className="vehicle-detail-content">
      <Detail />
    </main>
  );
}
