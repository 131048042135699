/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: client_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as client_service_pb from './client_service_pb';


export class OwnerOrgManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorMe = new grpcWeb.MethodDescriptor(
    '/api.OwnerOrgManagementService/Me',
    grpcWeb.MethodType.UNARY,
    client_service_pb.OwnerOrgManagementMeRequest,
    client_service_pb.OwnerOrgManagementMeResponse,
    (request: client_service_pb.OwnerOrgManagementMeRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.OwnerOrgManagementMeResponse.deserializeBinary
  );

  me(
    request: client_service_pb.OwnerOrgManagementMeRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.OwnerOrgManagementMeResponse>;

  me(
    request: client_service_pb.OwnerOrgManagementMeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.OwnerOrgManagementMeResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.OwnerOrgManagementMeResponse>;

  me(
    request: client_service_pb.OwnerOrgManagementMeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.OwnerOrgManagementMeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OwnerOrgManagementService/Me',
        request,
        metadata || {},
        this.methodDescriptorMe,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OwnerOrgManagementService/Me',
    request,
    metadata || {},
    this.methodDescriptorMe);
  }

  methodDescriptorFindOwnerOrgByName = new grpcWeb.MethodDescriptor(
    '/api.OwnerOrgManagementService/FindOwnerOrgByName',
    grpcWeb.MethodType.UNARY,
    client_service_pb.FindByNameRequest,
    client_service_pb.FindByNameResponse,
    (request: client_service_pb.FindByNameRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.FindByNameResponse.deserializeBinary
  );

  findOwnerOrgByName(
    request: client_service_pb.FindByNameRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.FindByNameResponse>;

  findOwnerOrgByName(
    request: client_service_pb.FindByNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.FindByNameResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.FindByNameResponse>;

  findOwnerOrgByName(
    request: client_service_pb.FindByNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.FindByNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OwnerOrgManagementService/FindOwnerOrgByName',
        request,
        metadata || {},
        this.methodDescriptorFindOwnerOrgByName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OwnerOrgManagementService/FindOwnerOrgByName',
    request,
    metadata || {},
    this.methodDescriptorFindOwnerOrgByName);
  }

  methodDescriptorListOwnerOrgs = new grpcWeb.MethodDescriptor(
    '/api.OwnerOrgManagementService/ListOwnerOrgs',
    grpcWeb.MethodType.UNARY,
    client_service_pb.ListOwnerOrgManagementRequest,
    client_service_pb.ListOwnerOrgManagementResponse,
    (request: client_service_pb.ListOwnerOrgManagementRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.ListOwnerOrgManagementResponse.deserializeBinary
  );

  listOwnerOrgs(
    request: client_service_pb.ListOwnerOrgManagementRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.ListOwnerOrgManagementResponse>;

  listOwnerOrgs(
    request: client_service_pb.ListOwnerOrgManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.ListOwnerOrgManagementResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.ListOwnerOrgManagementResponse>;

  listOwnerOrgs(
    request: client_service_pb.ListOwnerOrgManagementRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.ListOwnerOrgManagementResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OwnerOrgManagementService/ListOwnerOrgs',
        request,
        metadata || {},
        this.methodDescriptorListOwnerOrgs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OwnerOrgManagementService/ListOwnerOrgs',
    request,
    metadata || {},
    this.methodDescriptorListOwnerOrgs);
  }

}

export class ClientOrgManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorFindClientOrgByName = new grpcWeb.MethodDescriptor(
    '/api.ClientOrgManagementService/FindClientOrgByName',
    grpcWeb.MethodType.UNARY,
    client_service_pb.FindByNameRequest,
    client_service_pb.FindByNameResponse,
    (request: client_service_pb.FindByNameRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.FindByNameResponse.deserializeBinary
  );

  findClientOrgByName(
    request: client_service_pb.FindByNameRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.FindByNameResponse>;

  findClientOrgByName(
    request: client_service_pb.FindByNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.FindByNameResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.FindByNameResponse>;

  findClientOrgByName(
    request: client_service_pb.FindByNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.FindByNameResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.ClientOrgManagementService/FindClientOrgByName',
        request,
        metadata || {},
        this.methodDescriptorFindClientOrgByName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.ClientOrgManagementService/FindClientOrgByName',
    request,
    metadata || {},
    this.methodDescriptorFindClientOrgByName);
  }

}

export class OrganizationAdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListOwnerOrgs = new grpcWeb.MethodDescriptor(
    '/api.OrganizationAdminService/ListOwnerOrgs',
    grpcWeb.MethodType.UNARY,
    client_service_pb.ListOwnerOrgAdminRequest,
    client_service_pb.ListOwnerOrgAdminResponse,
    (request: client_service_pb.ListOwnerOrgAdminRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.ListOwnerOrgAdminResponse.deserializeBinary
  );

  listOwnerOrgs(
    request: client_service_pb.ListOwnerOrgAdminRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.ListOwnerOrgAdminResponse>;

  listOwnerOrgs(
    request: client_service_pb.ListOwnerOrgAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.ListOwnerOrgAdminResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.ListOwnerOrgAdminResponse>;

  listOwnerOrgs(
    request: client_service_pb.ListOwnerOrgAdminRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.ListOwnerOrgAdminResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OrganizationAdminService/ListOwnerOrgs',
        request,
        metadata || {},
        this.methodDescriptorListOwnerOrgs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OrganizationAdminService/ListOwnerOrgs',
    request,
    metadata || {},
    this.methodDescriptorListOwnerOrgs);
  }

  methodDescriptorAddOrganization = new grpcWeb.MethodDescriptor(
    '/api.OrganizationAdminService/AddOrganization',
    grpcWeb.MethodType.UNARY,
    client_service_pb.AddOrganizationRequest,
    client_service_pb.AddOrganizationResponse,
    (request: client_service_pb.AddOrganizationRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.AddOrganizationResponse.deserializeBinary
  );

  addOrganization(
    request: client_service_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.AddOrganizationResponse>;

  addOrganization(
    request: client_service_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.AddOrganizationResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.AddOrganizationResponse>;

  addOrganization(
    request: client_service_pb.AddOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.AddOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OrganizationAdminService/AddOrganization',
        request,
        metadata || {},
        this.methodDescriptorAddOrganization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OrganizationAdminService/AddOrganization',
    request,
    metadata || {},
    this.methodDescriptorAddOrganization);
  }

  methodDescriptorUpdateOrganization = new grpcWeb.MethodDescriptor(
    '/api.OrganizationAdminService/UpdateOrganization',
    grpcWeb.MethodType.UNARY,
    client_service_pb.UpdateOrganizationRequest,
    client_service_pb.UpdateOrganizationResponse,
    (request: client_service_pb.UpdateOrganizationRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.UpdateOrganizationResponse.deserializeBinary
  );

  updateOrganization(
    request: client_service_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.UpdateOrganizationResponse>;

  updateOrganization(
    request: client_service_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.UpdateOrganizationResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.UpdateOrganizationResponse>;

  updateOrganization(
    request: client_service_pb.UpdateOrganizationRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.UpdateOrganizationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OrganizationAdminService/UpdateOrganization',
        request,
        metadata || {},
        this.methodDescriptorUpdateOrganization,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OrganizationAdminService/UpdateOrganization',
    request,
    metadata || {},
    this.methodDescriptorUpdateOrganization);
  }

  methodDescriptorFindOrganizationByID = new grpcWeb.MethodDescriptor(
    '/api.OrganizationAdminService/FindOrganizationByID',
    grpcWeb.MethodType.UNARY,
    client_service_pb.FindOrganizationByIdRequest,
    client_service_pb.FindOrganizationByIdResponse,
    (request: client_service_pb.FindOrganizationByIdRequest) => {
      return request.serializeBinary();
    },
    client_service_pb.FindOrganizationByIdResponse.deserializeBinary
  );

  findOrganizationByID(
    request: client_service_pb.FindOrganizationByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<client_service_pb.FindOrganizationByIdResponse>;

  findOrganizationByID(
    request: client_service_pb.FindOrganizationByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: client_service_pb.FindOrganizationByIdResponse) => void): grpcWeb.ClientReadableStream<client_service_pb.FindOrganizationByIdResponse>;

  findOrganizationByID(
    request: client_service_pb.FindOrganizationByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: client_service_pb.FindOrganizationByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.OrganizationAdminService/FindOrganizationByID',
        request,
        metadata || {},
        this.methodDescriptorFindOrganizationByID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.OrganizationAdminService/FindOrganizationByID',
    request,
    metadata || {},
    this.methodDescriptorFindOrganizationByID);
  }

}

