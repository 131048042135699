import { MapMarkerState, MapMarkerActions} from "types/store";

const initialState:MapMarkerState = {
    is_map_marker_change:false,
    map_marker: {lat: 31.501660, lng: 74.361969}
}

export default function mapMarkerReducer(
    state:MapMarkerState = initialState,
    action:MapMarkerActions
): MapMarkerState {
    switch (action.type) {
        case "SET_MAP_MARKER":
            return {...state, map_marker: action.payload}
        case "SET_MAP_MARKER_CHANGE":
            return {...state, is_map_marker_change: action.payload}
        default:
            return state
    }
}