import { Row, Col } from "antd";

import { VehicleTile } from "components";
import { VEHICLE_TIES_DATA } from "../__mock__";

export default function VehicleTileContainer() {
  return (
    <Row className="tiles-container">
      {Object.values(VEHICLE_TIES_DATA).map((tile) => (
        <Col key={tile.name.toString()} className={tile.className}>
          <VehicleTile
            name={tile.name}
            value={tile.value}
            render={tile.render}
            link={tile.link}
          />
        </Col>
      ))}
    </Row>
  );
}
