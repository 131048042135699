import { ReactNode } from "react";
import { Checkbox as CheckboxAntd } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";

interface ComponentProps {
  children: ReactNode;
  customClasses?: string;
}

export default function Checkbox(props: ComponentProps & CheckboxProps) {
  const { customClasses = "", children, ...rest } = props;
  return (
    <CheckboxAntd className={`checkbox ${customClasses}`} {...rest}>
      {children}
    </CheckboxAntd>
  );
}
