import { getRandomNumber, getRandomString, range } from "utils/helpers";

export const usersTableData = range(46).map((item, index) => ({
  key: index,
  driver_name: getRandomString([
    "Hasan Sheikh",
    "Salman Ahmed",
    "Harris Shams",
    "M. Taimoor",
    "Ali Bin Saud",
  ]),
  user_id: getRandomNumber(10000),
  vehicle_id: [
    `${getRandomString(["A", "B", "C", "D", "E", "F"])} - ${getRandomNumber(
      10000
    )}`,
  ],
  date_and_time: getRandomString([
    "Jun 26, 2020 10:53 pm",
    "Jun 30, 2020 01:30 pm",
    "Aug 01, 2020 11:13 pm",
    "Sep 02, 2020 07:06 pm",
    "Dec 15, 2020 12:07 pm",
  ]),
  user_status: getRandomString(["Active", "In-active", "Blocked"]),
  safety_rating: getRandomString(["1", "2", "3", "4", "5"]),
  active_tasks: getRandomString(["1", "2", "3", "4", "5"]),
  task_completed: getRandomString(["1", "2", "3", "4", "5"]),

  action: "",
}));

export const userRoles = [
  { value: "3", label: "Rider" },
  // { value: "4", label: "Mechanic" },
  { value: "1", label: "Super Admin" },
  { value: "2", label: "Fleet Owner Admin" },
  { value: "5", label: "Fleet Client Admin" },
  { value: "6", label: "Operator" },
];

export const albaCars = `69de255a-ff00-4535-af55-5f6697608144
39fb0097-ff28-45cb-8611-780a1debe87d
916f5b97-1623-4287-9772-6d1d7d8d2cfe
d42b8944-2e90-4434-9ba2-0e36699ca230
b62a4313-4955-4513-a040-952c1014d14c
556911ff-de58-4ac5-ad4d-b8614d0c327b
f41e51c7-80a0-49e5-a0e2-41f10f030192
3c1c190d-134e-4a7b-b2a0-95b8a19f5386
6135a8b6-37d1-4374-97ff-f141e79dfe74
37f62b3b-c8ed-431a-b008-28f4e57ea73b
15d11f20-bb3b-41ca-8bae-34fe48a4be3b
cf20bc50-a2f0-48ac-b87d-ee68f64916c4
4855086a-11b0-4c9b-8be2-1b514ab19ce8
b14a3e24-f83d-4ee9-9b7e-4d5b6480baa7
639a6ed2-6e8d-4c9c-8139-4cc5b0957d21
999db00e-c2d6-4d64-8a07-e3a45a5f72e9
d09a1629-6a6e-4f57-80e4-70a4ee781252
b123e745-b27e-4774-a320-d798eb744813
d2d915df-55e5-4944-8727-e856eeb26c74
583e1c7c-1b1d-4a2c-8302-552aed8413d7
3ec4695f-33ab-4b9b-a982-09224f89ccee
02d7088b-0901-4d92-bc1d-4ea215d51cd1
302da35b-17e5-435a-b6f8-6220d59412db
8627efdb-0c45-4926-b357-a87fef9ca16a
c17b639e-4807-42bc-8983-944e953bb3f3
e0178c5b-4f8c-4ac3-ba32-e659fae182b9
61b9e6db-100b-49e3-8f80-4f371dba7c17
545c0ab4-71d3-4cc8-9171-ae8c7bc58bd0
bb090599-09f2-44ac-bb59-a949f2fe8534
75e089b3-a336-432e-a2b6-6c7838853c05
9d2e678a-404f-4b0f-8993-87025e1ae863
130cd9f5-c9e3-4e0d-b72e-c3bfda86216f
126856e0-df9b-41b1-baf5-7052a9495508
e2d2a0bb-b559-4c65-a75c-dfce49da24e3
528ee314-5173-4d1e-81b2-4c34562ec7c3
57329dd9-bca3-4b6a-9792-f33428683b82
3f351db3-30ce-4b42-b919-5c56638be85e
ce4f4bc9-3633-4050-ab19-7e03fb23937e
b494c626-36cf-4760-967a-7c8e4f2330cd
4f559650-df4c-4fd4-bae5-e3001799f43e
1f1f9851-568e-4dae-a45e-3372e2a889da
aa1879fb-a07c-4237-b608-16b7563b2337
e4df3362-3d6c-4d82-9469-39f54f2bcb5f
349ebd56-6955-4bfc-9106-4766a0a484cf
99083278-e76c-4bbd-a805-e6be5e25d79b
1c473e2a-ef31-4c62-801b-df2414983659
f7f92a04-10b9-4b97-9d0c-f81c11d93479
ccfbe81c-db91-423c-88ed-68275f92caa8
c7c4906f-b96b-4e3d-a40c-266d9933bdc1
25f84b24-d627-40eb-8480-c2fe6d6d8718
4d426f7a-254b-4b00-80fd-6ef66815d164
1427fa1d-7586-4c81-9b49-835ab373da8c
4c388d07-5e57-48b4-bf8d-83e1b8792b74
41fab543-72e6-4e86-b918-21cf980f5b7b
d43f6307-47fb-47c1-bbd3-669f725ad329
5b081a5f-bf4f-4135-8889-e369153c3a25
42c0f511-48a4-45e8-80ae-f9a633f3aabe`;

export const alTayerMotors = `4fa36815-adfa-4493-bc4e-0d10633447c4
fa11d483-c15d-4c94-b399-cf2d6b7cd5c2
c1e51eb6-489e-4df1-98f9-63ec336bd434
a96c4961-cdb0-475e-816f-7af2cecc965e
04368238-4a72-4942-930d-e675e7085b03
32eb361d-08db-43b7-9159-1c8da8541c6d
230d58dd-1b20-4960-83d0-a1697fd3aeb1
3a4a0535-9ac8-405d-a1ec-cf9381a0f965
06d0d8f5-b9d6-45a9-b613-e757614005e0
95b666b0-6208-4fc5-a0c4-f75b2393997c
ee252835-c2da-4f9f-902f-b4b8ab78e988
ca6c964e-7ed0-424a-87f1-7a69bcad155f
4ba64a8d-d597-4fab-9fff-5257a41782a9
75592faf-16fc-4042-9d60-aeaf3a7f2f47
cb3cd051-7f0b-4615-877f-658b17f3fbea
d4676da6-4310-4f09-94c8-51c05bd0f179
492f8e4a-33f7-4264-9038-27c84f04d8f9
43455d22-bd78-4222-9124-795ef80e5f71
e35b5205-b892-4440-880c-2da61e19c667
c388dfe5-322a-4029-8f51-514967ab9eb8
97d36467-6f6d-46ca-8ad2-20e4f5491244
8b3d22bd-a7f1-4762-9488-832ba0053c6e
c1fde831-cb98-4784-82f5-a0380c60375f
f33e2e21-cb9f-4015-a0e7-9d2477865ed5
25e15f9d-4bfb-408b-8f72-be2639c9768c
cf34ac74-43b0-4b9f-b8d3-5e62ecb28632
7ce76ac5-7d60-48f2-b839-ff207da280b7
070fc9cd-027a-40c5-b070-c7c3024b52b5
1a93eb83-a38a-441d-b700-9df959166f74
e35ff2b9-271e-4f71-9b91-666fc4acd807
a1a0e4e3-3784-4f86-bca5-05e30508121e
d67ae245-926e-4402-b2eb-dd1fd78e757d
f0561ecf-c30b-41a8-b2e7-068ec7931c78
0cd48c0a-cc09-4137-85c0-631a4beab93d
8cb81e43-8291-49dd-a1a0-b4a7a8d7eaad
a0adc6cb-1dd4-4ae4-a3fb-b09815fbd6b5
324fee67-74c0-4f45-a00e-9e1430a12046
d6892bd8-ad67-4ff0-ba94-45d449901fdb
4a1d31f8-9a31-4955-a5fb-63dbdc829a2d
3899bab0-e0d6-4347-aa17-71c8494d85d8
40cb9a83-45cc-4825-8d28-3767c531adb8
3a87b03c-c889-460b-b6ba-6791f78313e9
dbb3ba21-c45d-47ea-b0eb-1ec3011e9a87
322da269-2961-48e1-98de-0fcda0169145
77b0003b-70bc-496d-9350-162916a2a0c8
e74faec8-22f9-402e-a982-67cd9817f8be
f8c5eaf3-ed3f-4eb2-b762-d1d62ed6a1f9
a4c416d8-b138-4a38-b8bc-df3cf66be0d1
df33a171-bdcd-4974-8b52-79881c478c54
4e4ec193-2c04-470b-8f93-d37df6f485bb
27fc6e56-f316-4447-84d4-b7828c7b54a0
1e7d3bc3-6b67-4cf9-94c5-519c3e7e529b
dcd1f2d9-a7d3-4555-ac26-d04bb469f3b7
44a44ea3-25cf-4950-b4ef-42fe51c67bae
35e72956-3082-4d1c-9c76-3ff3c48a43fc
4b8371b1-fbfb-4c1d-9cee-c974407bb6bb
99ed6b74-489f-4f17-9c3c-a6de68233b59`;

export function getMockLocation(vehicleId: string) {
  const albaCarsArr = albaCars.split("\n");
  const alTayerMotorsArr = alTayerMotors.split("\n");

  if (albaCarsArr.includes(vehicleId)) {
    return "ALBA CARS - Car Showroom in Dubai";
  } else if (alTayerMotorsArr.includes(vehicleId)) {
    return "Al Tayer Motors Body Shop & Ford Service Center Al Quoz - Dubai ";
  }

  return "Al-quoz Labour Camp Autosport Suzuki United Arab Emirates";
}
