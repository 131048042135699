import {BatteryActions, BatteryState, FcmNotificationActions, FcmNotificationState} from "../../types";
import {notification} from "antd";

const initialState: FcmNotificationState = {
    page: {
        selectedPage: 1,
        totalElements: 0,
        totalPages: 0,
    },
    selectedNotificationRows: [],
}

export default function fcmNotificationReducer(
    state: FcmNotificationState = initialState,
    action: FcmNotificationActions
):FcmNotificationState {
    switch (action.type) {
        case "SET_FCM_NOTIFICATIONS":
            return {...state, notifications: action.payload};
        case "SET_NOTIFICATION_PAGE":
            return {
                ...state,
                page: { ...state.page, selectedPage: action.payload },
            };
        case "SET_SELECTED_NOTIFICATION":
            return {...state, selectedNotification: action.payload};
        case "SET_SELECTED_NOTIFICATION_ROWS":
            return {...state, selectedNotificationRows: action.payload};
        default:
            return state;
    }
}