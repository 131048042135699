/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.2
// source: battery_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as battery_service_pb from './battery_service_pb';


export class BatteryManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddBattery = new grpcWeb.MethodDescriptor(
    '/api.BatteryManagementService/AddBattery',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.AddBatteryRequest,
    battery_service_pb.AddBatteryResponse,
    (request: battery_service_pb.AddBatteryRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.AddBatteryResponse.deserializeBinary
  );

  addBattery(
    request: battery_service_pb.AddBatteryRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.AddBatteryResponse>;

  addBattery(
    request: battery_service_pb.AddBatteryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.AddBatteryResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.AddBatteryResponse>;

  addBattery(
    request: battery_service_pb.AddBatteryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.AddBatteryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryManagementService/AddBattery',
        request,
        metadata || {},
        this.methodDescriptorAddBattery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryManagementService/AddBattery',
    request,
    metadata || {},
    this.methodDescriptorAddBattery);
  }

  methodDescriptorUpdateBattery = new grpcWeb.MethodDescriptor(
    '/api.BatteryManagementService/UpdateBattery',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.UpdateBatteryRequest,
    battery_service_pb.UpdateBatteryResponse,
    (request: battery_service_pb.UpdateBatteryRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.UpdateBatteryResponse.deserializeBinary
  );

  updateBattery(
    request: battery_service_pb.UpdateBatteryRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.UpdateBatteryResponse>;

  updateBattery(
    request: battery_service_pb.UpdateBatteryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.UpdateBatteryResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.UpdateBatteryResponse>;

  updateBattery(
    request: battery_service_pb.UpdateBatteryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.UpdateBatteryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryManagementService/UpdateBattery',
        request,
        metadata || {},
        this.methodDescriptorUpdateBattery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryManagementService/UpdateBattery',
    request,
    metadata || {},
    this.methodDescriptorUpdateBattery);
  }

  methodDescriptorGetBatteryById = new grpcWeb.MethodDescriptor(
    '/api.BatteryManagementService/GetBatteryById',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.GetBatteryByIdRequest,
    battery_service_pb.GetBatteryByIdResponse,
    (request: battery_service_pb.GetBatteryByIdRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.GetBatteryByIdResponse.deserializeBinary
  );

  getBatteryById(
    request: battery_service_pb.GetBatteryByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.GetBatteryByIdResponse>;

  getBatteryById(
    request: battery_service_pb.GetBatteryByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteryByIdResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.GetBatteryByIdResponse>;

  getBatteryById(
    request: battery_service_pb.GetBatteryByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteryByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryManagementService/GetBatteryById',
        request,
        metadata || {},
        this.methodDescriptorGetBatteryById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryManagementService/GetBatteryById',
    request,
    metadata || {},
    this.methodDescriptorGetBatteryById);
  }

  methodDescriptorGetBatteries = new grpcWeb.MethodDescriptor(
    '/api.BatteryManagementService/GetBatteries',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.GetBatteriesRequest,
    battery_service_pb.GetBatteriesResponse,
    (request: battery_service_pb.GetBatteriesRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.GetBatteriesResponse.deserializeBinary
  );

  getBatteries(
    request: battery_service_pb.GetBatteriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.GetBatteriesResponse>;

  getBatteries(
    request: battery_service_pb.GetBatteriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteriesResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.GetBatteriesResponse>;

  getBatteries(
    request: battery_service_pb.GetBatteriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryManagementService/GetBatteries',
        request,
        metadata || {},
        this.methodDescriptorGetBatteries,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryManagementService/GetBatteries',
    request,
    metadata || {},
    this.methodDescriptorGetBatteries);
  }

  methodDescriptorDeleteBattery = new grpcWeb.MethodDescriptor(
    '/api.BatteryManagementService/DeleteBattery',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.DeleteBatteryRequest,
    battery_service_pb.DeleteBatteryResponse,
    (request: battery_service_pb.DeleteBatteryRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.DeleteBatteryResponse.deserializeBinary
  );

  deleteBattery(
    request: battery_service_pb.DeleteBatteryRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.DeleteBatteryResponse>;

  deleteBattery(
    request: battery_service_pb.DeleteBatteryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.DeleteBatteryResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.DeleteBatteryResponse>;

  deleteBattery(
    request: battery_service_pb.DeleteBatteryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.DeleteBatteryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryManagementService/DeleteBattery',
        request,
        metadata || {},
        this.methodDescriptorDeleteBattery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryManagementService/DeleteBattery',
    request,
    metadata || {},
    this.methodDescriptorDeleteBattery);
  }

  methodDescriptorAddBatteriesFromCSV = new grpcWeb.MethodDescriptor(
    '/api.BatteryManagementService/AddBatteriesFromCSV',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.AddBatteriesFromCSVRequest,
    battery_service_pb.AddBatteriesFromCSVResponse,
    (request: battery_service_pb.AddBatteriesFromCSVRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.AddBatteriesFromCSVResponse.deserializeBinary
  );

  addBatteriesFromCSV(
    request: battery_service_pb.AddBatteriesFromCSVRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.AddBatteriesFromCSVResponse>;

  addBatteriesFromCSV(
    request: battery_service_pb.AddBatteriesFromCSVRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.AddBatteriesFromCSVResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.AddBatteriesFromCSVResponse>;

  addBatteriesFromCSV(
    request: battery_service_pb.AddBatteriesFromCSVRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.AddBatteriesFromCSVResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryManagementService/AddBatteriesFromCSV',
        request,
        metadata || {},
        this.methodDescriptorAddBatteriesFromCSV,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryManagementService/AddBatteriesFromCSV',
    request,
    metadata || {},
    this.methodDescriptorAddBatteriesFromCSV);
  }

}

export class BatteryModelManagementServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddBatteryModel = new grpcWeb.MethodDescriptor(
    '/api.BatteryModelManagementService/AddBatteryModel',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.AddBatteryModelRequest,
    battery_service_pb.AddBatteryModelResponse,
    (request: battery_service_pb.AddBatteryModelRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.AddBatteryModelResponse.deserializeBinary
  );

  addBatteryModel(
    request: battery_service_pb.AddBatteryModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.AddBatteryModelResponse>;

  addBatteryModel(
    request: battery_service_pb.AddBatteryModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.AddBatteryModelResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.AddBatteryModelResponse>;

  addBatteryModel(
    request: battery_service_pb.AddBatteryModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.AddBatteryModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryModelManagementService/AddBatteryModel',
        request,
        metadata || {},
        this.methodDescriptorAddBatteryModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryModelManagementService/AddBatteryModel',
    request,
    metadata || {},
    this.methodDescriptorAddBatteryModel);
  }

  methodDescriptorUpdateBatteryModel = new grpcWeb.MethodDescriptor(
    '/api.BatteryModelManagementService/UpdateBatteryModel',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.UpdateBatteryModelRequest,
    battery_service_pb.UpdateBatteryModelResponse,
    (request: battery_service_pb.UpdateBatteryModelRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.UpdateBatteryModelResponse.deserializeBinary
  );

  updateBatteryModel(
    request: battery_service_pb.UpdateBatteryModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.UpdateBatteryModelResponse>;

  updateBatteryModel(
    request: battery_service_pb.UpdateBatteryModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.UpdateBatteryModelResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.UpdateBatteryModelResponse>;

  updateBatteryModel(
    request: battery_service_pb.UpdateBatteryModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.UpdateBatteryModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryModelManagementService/UpdateBatteryModel',
        request,
        metadata || {},
        this.methodDescriptorUpdateBatteryModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryModelManagementService/UpdateBatteryModel',
    request,
    metadata || {},
    this.methodDescriptorUpdateBatteryModel);
  }

  methodDescriptorGetBatteryModels = new grpcWeb.MethodDescriptor(
    '/api.BatteryModelManagementService/GetBatteryModels',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.GetBatteryModelsRequest,
    battery_service_pb.GetBatteryModelsResponse,
    (request: battery_service_pb.GetBatteryModelsRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.GetBatteryModelsResponse.deserializeBinary
  );

  getBatteryModels(
    request: battery_service_pb.GetBatteryModelsRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.GetBatteryModelsResponse>;

  getBatteryModels(
    request: battery_service_pb.GetBatteryModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteryModelsResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.GetBatteryModelsResponse>;

  getBatteryModels(
    request: battery_service_pb.GetBatteryModelsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteryModelsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryModelManagementService/GetBatteryModels',
        request,
        metadata || {},
        this.methodDescriptorGetBatteryModels,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryModelManagementService/GetBatteryModels',
    request,
    metadata || {},
    this.methodDescriptorGetBatteryModels);
  }

  methodDescriptorGetBatteryModelById = new grpcWeb.MethodDescriptor(
    '/api.BatteryModelManagementService/GetBatteryModelById',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.GetBatteryModelByIdRequest,
    battery_service_pb.GetBatteryModelByIdResponse,
    (request: battery_service_pb.GetBatteryModelByIdRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.GetBatteryModelByIdResponse.deserializeBinary
  );

  getBatteryModelById(
    request: battery_service_pb.GetBatteryModelByIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.GetBatteryModelByIdResponse>;

  getBatteryModelById(
    request: battery_service_pb.GetBatteryModelByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteryModelByIdResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.GetBatteryModelByIdResponse>;

  getBatteryModelById(
    request: battery_service_pb.GetBatteryModelByIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.GetBatteryModelByIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryModelManagementService/GetBatteryModelById',
        request,
        metadata || {},
        this.methodDescriptorGetBatteryModelById,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryModelManagementService/GetBatteryModelById',
    request,
    metadata || {},
    this.methodDescriptorGetBatteryModelById);
  }

  methodDescriptorDeleteBatteryModel = new grpcWeb.MethodDescriptor(
    '/api.BatteryModelManagementService/DeleteBatteryModel',
    grpcWeb.MethodType.UNARY,
    battery_service_pb.DeleteBatteryModelRequest,
    battery_service_pb.DeleteBatteryModelResponse,
    (request: battery_service_pb.DeleteBatteryModelRequest) => {
      return request.serializeBinary();
    },
    battery_service_pb.DeleteBatteryModelResponse.deserializeBinary
  );

  deleteBatteryModel(
    request: battery_service_pb.DeleteBatteryModelRequest,
    metadata: grpcWeb.Metadata | null): Promise<battery_service_pb.DeleteBatteryModelResponse>;

  deleteBatteryModel(
    request: battery_service_pb.DeleteBatteryModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: battery_service_pb.DeleteBatteryModelResponse) => void): grpcWeb.ClientReadableStream<battery_service_pb.DeleteBatteryModelResponse>;

  deleteBatteryModel(
    request: battery_service_pb.DeleteBatteryModelRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: battery_service_pb.DeleteBatteryModelResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/api.BatteryModelManagementService/DeleteBatteryModel',
        request,
        metadata || {},
        this.methodDescriptorDeleteBatteryModel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/api.BatteryModelManagementService/DeleteBatteryModel',
    request,
    metadata || {},
    this.methodDescriptorDeleteBatteryModel);
  }

}

