import { DashboardActions, DashboardState } from "types/store";

const initialState: DashboardState = {
  selectedDashboard: "issues",
};

export default function commonReducer(
  state: DashboardState = initialState,
  action: DashboardActions
): DashboardState {
  switch (action.type) {
    case "SET_SELECTED_DASHBOARD":
      return { ...state, selectedDashboard: action.payload };
    default:
      return state;
  }
}
