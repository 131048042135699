import { Button, Typography } from "antd";
import { Link } from "react-router-dom";

import { ROUTES } from "utils/constants";
import { fluxNetworkBackIcon } from "svgs";

const { Title, Text } = Typography;

export default function Enguiry() {
  return (
    <main className="main-enquiry-container">
      <section className="enquirey-wrapper">
        <Title level={5} className="enquiry-title">
          Enquiry Sent!
        </Title>
        <Text className="enquiry-description">
          Thank you for reaching out to us. An Flux Network representative will
          contact you shortly with further information.
        </Text>
        <Button type="default" className="back-link">
          <Link to={ROUTES.welcome} className="link">
            {fluxNetworkBackIcon} Go Home
          </Link>
        </Button>
      </section>
    </main>
  );
}
