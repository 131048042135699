import { format } from "date-fns";

import { BatteryModelState } from "types/store";

export function parseBatteryModelTable(
    search: BatteryModelState["search"],
    list?: BatteryModelState["batteryModels"],
    listType?: BatteryModelState["filters"]["listType"]
) {
    if (list) {
        const data = list.map((batteryModel, index) => ({
            key: index,
            id: (index+1),
            make: batteryModel.make,
            model: batteryModel.model,
            year: batteryModel.year,
            action: "",
            raw: batteryModel,
            listType: listType,
        }));
        return data
    }

    return [];
}

export function parseBatteryModels(list?: { model:string, make: string; id: string }[]) {
    if (list) {
        return list.map((batteryModel) => ({
            label: batteryModel.model,
            value: batteryModel.id,
        }));
    }

    return [];
}
