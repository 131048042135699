import { ReactText, useCallback } from "react";
import { Typography } from "antd";
import { format } from "date-fns";

import {
  accidentIcon,
  breakdownIcon,
  serviceIcon,
  vehicleMissingIcon,
  timeIcon,
  bikeIcon,
  editIcon,
  archiveIcon,
  restoreIcon,
  fileUploadIcon,
} from "svgs";
import {BatteryModelState, BatteryState, IssuesState, IssuesTableRow, KeyValue, VehicleModelState} from "types";
import { classNames } from "utils/helpers";
import { Tag } from "components/base";
import { ISSUE_STATUS, ISSUE_TYPE } from "utils/mapped-data";
import { IssueStatus, IssueType } from "protobuf/issue_service_pb";
import { DATE_FORMAT, TIME_FORMAT } from "utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {setModalName, setSelectedBattery, setSelectedBatteryModel} from "store/actions";
import DropdownAction from "./dropdown-action";
import {AppState} from "../../../store";

const { Text } = Typography;

const issuesIcon: KeyValue = {
  accident: accidentIcon,
  breakdown: breakdownIcon,
  service: serviceIcon,
  "vehicle missing": vehicleMissingIcon,
};

export function ActionMenu(
  value: string,
  row:  {
    raw: BatteryModelState["selectedBatteryModel"];
    listType: BatteryModelState["filters"]["listType"];
  }
) {
  const dispatch = useDispatch();

  const onEditBatteryModel = useCallback(() => {
    dispatch(setSelectedBatteryModel(row.raw));
    dispatch(setModalName("edit_battery_model"));
  }, [dispatch, row.raw]);
  const {
    common: { userType =1 },
  } = useSelector<AppState, AppState>((state) => state);
  if (userType == 1 || userType == 2) {
    return (
        <section className="table-action-container show-on-hover">
      <span className="edit-icon icon" onClick={onEditBatteryModel} title="edit battery model">
        {editIcon}
      </span>
          <DropdownAction model={row.raw} />
        </section>
    );
  }
  return <></>

}

