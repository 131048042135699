import ReactFlagsSelect from "react-flags-select";
import { CountrySelectProps } from "types/components";

export default function ReactCountriesFlagSelect(props: CountrySelectProps) {
  return (
    <ReactFlagsSelect
      {...props}
      className={`phone-input-custom ${props.className}`}
    />
  );
}
