import { AreaChartDataProps } from "@k2/rv-viz";
import numeral from "numeral";

import { bikeIcon } from "svgs";
import { TileSparkline } from "components";

interface ComponentProps {
  name: string;
  value: number;
  type: "simple" | "chart";
  chart: AreaChartDataProps;
  className: string;
  callback?: () => void;
}
interface RenderIconProps {
  type: "simple" | "chart";
  className: string;
  callback?: () => void;
  chart: AreaChartDataProps;
}

const RenderIcon = (props: RenderIconProps) => {
  const { type, className, chart } = props;
  return (
    <span className="tile-figure icon-color">
      {type === "simple" ? (
        bikeIcon
      ) : (
        <TileSparkline className={className} data={chart} />
      )}
    </span>
  );
};

export default function IssueTile(props: ComponentProps) {
  const { name, value, type, className, chart } = props;

  return (
    <article className="tile-card">
      <section className="value-chart">
        <span className="tile-value">{numeral(value).format("00a")}</span>
        <RenderIcon chart={chart} type={type} className={className} />
      </section>
      <section className="name-link">
        <span className="tile-name">{name}</span>
        {value === 0 ? "" : <a className="tile-link">View </a>}
      </section>
    </article>
  );
}
