import { Button, Typography } from "antd";
import {useCallback, useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Table,TablePagination } from "components/base";
import {parseVehicleLocationTable} from "utils/parsers";
import { CSSProperties } from "styled-components";
import { plusIcon, searchIcon } from "svgs";
import { issuesTableData , locationTableData } from "../__mock__";
import { renderDueDate, renderResolvedOn } from "../cell-renders";
import { AppState  } from "store";
import { getVehicleLocationHistory, setLogsSelectedPage } from "store/actions/vehicles-actions";
interface ComponentProps {
  onAddIssue: VoidFunction;
}

const { Text } = Typography;

const columns = [
  {
    title: "Issue ID",
    dataIndex: "issue_id",
  },
  {
    title: "Type",
    dataIndex: "issue_type",
  },
  {
    title: "Issue",
    dataIndex: "issue",
    ellipsis: true,
  },
  {
    title: "Created By",
    dataIndex: "created_by",
    ellipsis: true,
  },
  {
    title: "Occured On",
    dataIndex: "occured_on",
    width: 170,
  },
  {
    title: "Rider Name",
    dataIndex: "rider_name",
    ellipsis: true,
  },
  {
    title: "Assignee",
    dataIndex: "assignee",
    ellipsis: true,
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    render: renderDueDate,
  },
  {
    title: "Resolved On",
    dataIndex: "resolved_on",
    render: renderResolvedOn,
  },
];

const location_columns = [
  {
    title: "Date & Time",
    dataIndex: "location_time",
    ellipsis: true,
  },
  {
    title: "Latitude",
    dataIndex: "latitude",
  },
  {
    title: "Longitude",
    dataIndex: "longitude",
  },

];


// export default function IssuesTable(props: ComponentProps) {
//   const { onAddIssue } = props;

//   return (
//     <section className="__table-container">
//       <header className="vehicle-issue-table">
//         <Text className="table-title">Issues History of Vehicle</Text>
//         <Input
//           customClasses="search-issue"
//           prefix={searchIcon}
//           placeholder="Search..."
//         />
//         <Button
//           className="add-issue-btn"
//           type="primary"
//           icon={plusIcon}
//           onClick={onAddIssue}>
//           Add Issue
//         </Button>
//       </header>
//       <Table
//         style={
//           {
//             "--table-height": "330px",
//           } as CSSProperties
//         }
//         customClasses="--custom-height"
//         columns={columns}
//         dataSource={issuesTableData}
//         pagination={false}
//         scroll={{ y: 260 }}
//       />
//     </section>
//   );
// }



export default function LocationTable() {
  const dispatch = useDispatch();
  const {
     vehicleLogs,selectedVehicle,logsPage
  } = useSelector<AppState, AppState["vehicles"]>((state) => state.vehicles);
  const { selectedPage, totalPages } = logsPage;
  const data = useMemo(
    () => parseVehicleLocationTable(vehicleLogs),
    [vehicleLogs]
  );
  // useEffect(() => {
  //   dispatch(getVehicleLocationHistory({id: selectedVehicle?.id || "",pageNo: selectedPage}))
  // }, [dispatch, selectedPage,selectedVehicle?.id]);

  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setLogsSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage <= totalPages) {
      dispatch(setLogsSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setLogsSelectedPage(1));
  }, [dispatch]);
  return (
    <section className="__table-container">
      <Table
        style={
          {
            "--table-height": "330px",
          } as CSSProperties
        }
        customClasses="--custom-height"
        columns={location_columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 260 }}
      />

<TablePagination
        page={logsPage}
        onBack={onBack}
        onNext={onNext}
        resetCallback={resetCallback}
      />
    </section>
  );
}
