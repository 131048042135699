import { Formik } from "formik";
import { useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import { Form, Button, Typography, Input, Upload } from "antd";

import {
    userRoles,
} from "components/tab-content/users-content/__mock__";
import { fileToByteArray } from "utils/helpers";
import { FormItem, SelectSearch } from "components";
import { VEHICLE_MODEL_FORMIK } from "utils/formik-data";
import { VehicleModelValues } from "types/formik";
import { SelectType } from "types";
import { uploadIcon } from "svgs";
import {addIssue, addVehicleModel, setModalName} from "store/actions";
import {useDispatch} from "react-redux";
import {RcFile} from "antd/lib/upload";

const { Title, Text } = Typography;

interface ComponentProps {
    initialValues: VehicleModelValues;
    addFirmwareCallback: VoidFunction;
    setValues: (values: VehicleModelValues) => void;
    onCancel?: React.MouseEventHandler<HTMLElement>;
}

export default function AddVehicleModelForm(props: ComponentProps) {
    const { initialValues, addFirmwareCallback, onCancel, setValues } = props;
    const dispatch = useDispatch();
    const onCancels = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);
    const modelFilesArray:RcFile[] = []
    const onSubmit = useCallback(
        (values: VehicleModelValues) => {
            dispatch(
                addVehicleModel({
                    model: values.model.trim(),
                    make: values.make.trim(),
                    year: parseInt(values.year),
                    document: values.document_file || "",
                    documentExt: values.document_file_raw?.type || "",
                    documentName: values.document_file_raw?.name || "",
                    modelImages: values.modelFiles || [],
                })
            );
            console.log("modelFiles : ", values.modelFiles)
            onCancels();

        },
        [dispatch, onCancels]


    );

    const transmissionOption = [
        {
            label: "Manual",
            value: "Manual"
        },
        {
            label: "Automatic",
            value: "Automatic"
        }
    ]

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={VEHICLE_MODEL_FORMIK.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Add Vehicle Model</Title>
                    <Text className="description" type="secondary">
                        Please provide the authentic detail for model
                    </Text>
                    <Form className="--add-vehicles-form" onFinish={handleSubmit}>
                        <section className="">
                            <FormItem label="Model" error={errors.model} required>
                                <Input
                                    name="model"
                                    value={values.model}
                                    onChange={handleChange}
                                    placeholder="Name"
                                />
                            </FormItem>
                            <FormItem label="Make" error={errors.make} required>
                                <Input
                                    name="make"
                                    value={values.make}
                                    onChange={handleChange}
                                    placeholder="Make"
                                />
                            </FormItem>
                            <FormItem label="Year" error={errors.year} required>
                                <Input
                                    name="year"
                                    value={values.year}
                                    onChange={handleChange}
                                    placeholder="Year"
                                />
                            </FormItem>
                            <FormItem error={errors.transmission} label="Transmission">
                                <SelectSearch
                                    value={values.transmission}
                                    items={transmissionOption}
                                    setFieldValue={setFieldValue}
                                    fieldName="transmission"
                                    placeholder="Select "
                                />
                            </FormItem>
                            <FormItem
                                label="Model Image"
                                error={errors.document_file}
                                verticalAlign="flex-start"
                            >
                                <Upload.Dragger
                                    name="document_file"
                                    multiple={true}
                                    fileList={values.fileList}
                                    style={{width: "320px"}}
                                    onChange={({ fileList }) => {
                                        // Getting total number of files
                                        const totalFiles = fileList.length;

                                        // If multiple files, pick last file
                                        // if (totalFiles > 1) {
                                        //     setFieldValue("fileList", [fileList[totalFiles - 1]]);
                                        // } else {
                                        //     setFieldValue("fileList", fileList);
                                        // }
                                        setFieldValue("fileList", fileList);
                                    }}
                                    beforeUpload={async (file) => {
                                        const unit8Arr = await fileToByteArray(file);
                                        modelFilesArray.push(file)
                                        setFieldValue("modelFiles", modelFilesArray)
                                        setFieldValue("document_file", unit8Arr);
                                        setFieldValue("document_file_raw", file);
                                    }}
                                >
                                    {uploadIcon}
                                    <p className="ant-upload text">
                                        <span>Support for PNG, JPEG files, up to 512 KB</span><br />
                                        Drag the document here or
                                        <span className="secondary-color"> browse</span> from system
                                    </p>
                                </Upload.Dragger>
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Save
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}
