import { Typography, Button } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "store";
import { deleteIssue, setModalName, deleteVehicleModel } from "store/actions";
import { ISSUE_TYPE } from "utils/mapped-data";

const { Text, Title } = Typography;

export default function DeleteVehicleModelForm() {
    const { selectedModels } = useSelector<AppState, AppState["models"]>(
        (state) => state.models
    );
    const dispatch = useDispatch();

    const onDelete = useCallback(() => {
        console.log("Selected Model ID : ", selectedModels?.id)
        dispatch(deleteVehicleModel({
            model_id: selectedModels?.id || ""
        }))
        onCancel();
    }, [dispatch, selectedModels?.id]);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    return (
        <section className="delete-modal">
            <Title level={5}>Delete Vehicle Model</Title>
            <Text type="secondary">
                Are you sure you want to delete {" "}
                <strong>
                    “{selectedModels?.name}”
                </strong>{" "}
                ?
            </Text>
            <div className="action-btn-container">
                <Button className="cancel-btn" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" danger onClick={onDelete}>
                    Archive
                </Button>
            </div>
        </section>
    );
}
