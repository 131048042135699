import { useCookies } from "react-cookie";
import { saveIcon, saveSmallIcon } from "svgs";

const RenderEmptyEnquiry = () => (
  <article className="empty-enquiry-list">
    <span className="icon">{saveIcon}</span>
    <span>No previous enquiries found</span>
  </article>
);

const RenderEnquiries = (props: any) => {
  const { enquiries } = props;
  return (
    <section className="enquiries">
      <h1 className="heading">Previous Enquiries</h1>
      {enquiries.map((enquiry: any, index: number) => (
        <article className="enquiry">
          {enquiries[index - 1]?.date !== enquiry.date ? (
            <p className="enquiry-date">{enquiry.date}</p>
          ) : null}
          <p className="enquiry-text">
            <span className="icon">{saveSmallIcon}</span>
            <span>{enquiry.message}</span>
          </p>
        </article>
      ))}
    </section>
  );
};

export default function Enquiries() {
  const [{ enquiries }] = useCookies();
  return (
    <section className="enquiries-container">
      {enquiries ? (
        <RenderEnquiries enquiries={enquiries} />
      ) : (
        <RenderEmptyEnquiry />
      )}
    </section>
  );
}
