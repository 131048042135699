import { useCallback, useMemo, useState } from "react";
import { Collapse, Button, Slider } from "antd";
import { cancelIcon, chevronClose, chevronOpen } from "svgs";

import { Checkbox } from "components/base";
import { CheckboxPanelList } from "./sub-components";

const { Panel } = Collapse;

const mockData = [
  {
    name: "City",
    options: [
      { label: "Abu Dhabi", value: "Abu Dhabi" },
      { label: "Al Ain", value: "Al Ain" },
      { label: "Dubai", value: "Dubai" },
      { label: "Riyadh", value: "Riyadh" },
    ],
  },
];

function getState(data: typeof mockData, type: "full" | "empty" = "empty") {
  const stateObj: { [key: string]: string[] } = {};
  data.forEach((row) => {
    stateObj[row.name] =
      type === "full" ? row.options.map((option) => option.value) : [];
  });
  return stateObj;
}

function getFullList(name: string, data: typeof mockData) {
  const selectedItem = data.find((row) => row.name === name);
  return selectedItem ? selectedItem.options.map((option) => option.value) : [];
}

function getAllCheckedStatus(
  stateObj: { [key: string]: string[] },
  data: typeof mockData
) {
  const stateArr = Object.values(stateObj).flat();
  const dataArr = data.map((list) => list.options).flat();

  return stateArr.length === dataArr.length;
}

export default function ZonesFilter() {
  const [values, setValues] = useState(getState(mockData));
  const isAllChecked = useMemo(() => getAllCheckedStatus(values, mockData), [
    values,
  ]);
  const onChange = useCallback((name, value, type: "list" | "all") => {
    if (type === "list") {
      setValues((st) => ({ ...st, [name]: value }));
    } else {
      setValues((st) => ({
        ...st,
        [name]: value ? getFullList(name, mockData) : [],
      }));
    }
  }, []);
  const onRootChange = useCallback((event) => {
    const { checked } = event.target;
    setValues(
      checked ? getState(mockData, "full") : getState(mockData, "empty")
    );
  }, []);
  const onClearAll = useCallback(() => {
    setValues(getState(mockData, "empty"));
  }, []);

  return (
    <div className="common-filter">
      <div className="__filter-header">
        {" "}
        <div className="all-options">
          <Checkbox
            customClasses="--filter-light"
            onChange={onRootChange}
            checked={isAllChecked}>
            <span className="filter-text">FILTERS</span>
          </Checkbox>
          <Button
            className="clear-all-btn"
            icon={cancelIcon}
            type="link"
            danger
            onClick={onClearAll}>
            Clear All
          </Button>
        </div>
      </div>
      <Collapse
        className="__common-collapse"
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (isActive ? chevronOpen : chevronClose)}
        defaultActiveKey={["3"]}
        // defaultActiveKey={mockData.map((row) => row.name)}
      >
        {mockData.map((row) => (
          <CheckboxPanelList
            header={""}
            key={row.name}
            name={row.name}
            options={row.options}
            value={values[row.name]}
            onChange={onChange}
          />
        ))}

        <Panel
          header="Assigned Vehicle Count"
          key="2"
          className="assigned-vehicle-container">
          <span className="slider-container">
            <span>25</span>
            <Slider
              range
              defaultValue={[25, 100]}
              min={0}
              max={100}
              className="custom-slider"
            />
            <span>100</span>
          </span>
        </Panel>
      </Collapse>
    </div>
  );
}
