import { SwitchButton, DatePicker } from "components/base";

interface ComponentProps {
  defaultOpt: "am" | "pm";
}

const { TimePicker } = DatePicker;

export default function TimeContainer(props: ComponentProps) {
  const { defaultOpt } = props;

  return (
    <article className="time-container">
      <TimePicker
        defaultValue={new Date()}
        format="HH:mm"
        placeholder="Select"
      />
      <SwitchButton
        label=""
        options={["am", "pm"]}
        defaultOption={defaultOpt}
        type="primary"
      />
    </article>
  );
}
