import { Button } from "antd";
import { TableProps } from "antd/lib/table";

import { Table } from "components/base";
import { settingsIcon2 } from "svgs";
import { KeyValue } from "types";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { useMemo } from "react";
import {getFullName} from "../../../../utils/helpers";

const columns: TableProps<any>["columns"] = [
  {
    title: "label",
    dataIndex: "label",
    width: 180,
    ellipsis: true,
  },
  {
    title: "value",
    dataIndex: "value",
    render: ValueCell,
  },
];

function ValueCell(value: string, row: KeyValue, index: number) {
  switch (row.type) {
    case "color":
      return (
        <span
          className="value-with-color"
          style={
            {
              "--color-cell": value,
            } as React.CSSProperties
          }
        >
          {value}
        </span>
      );
    case "last_maintaned":
      return (
        <span className="last-maintaned-cell">
          {value}
          <Button type="link" icon={settingsIcon2}>
            Schedule Maintenance
          </Button>
        </span>
      );
    case "functional_status":
      return <span className="value-with-color --circle">{ functionalStatusCell( parseInt(value) ) }</span>;
    case "utilization_status":
      return <span>{ renderUtilizationStatus( parseInt(value)) }</span>;
    case "assigned_to":
      return (
        <figure className="user-icon-container-table">
          <img
            src={`https://picsum.photos/22?random=${index}`}
            alt="user pic"
            className="round-image"
          />
          {value}
        </figure>
      );
    default:
      return value;
  }
}

export default function Detail() {

  const {
    vehicles: { selectedVehicle },
  } = useSelector<AppState, AppState>((state) => state);

  const initialValues = useMemo(() => {
    return {
      vehicle_id: selectedVehicle?.licensePlate || "",
      vehicle_model: selectedVehicle?.modelId || "",
      vehicle_model_name: selectedVehicle?.model || "",
      vehicle_owner_org_name: selectedVehicle?.ownerOrgName || "",
      vehicle_status: selectedVehicle?.status || "",
      client: selectedVehicle?.ownerOrgId || "",
      assign_to: selectedVehicle?.assignee?.map((u) => u.id) || [],
      zone: selectedVehicle?.zone_name,
      location: selectedVehicle?.locationName || "",
      iot_id: selectedVehicle?.deviceIotId || "",
      mileage: selectedVehicle?.mileage || "100",
      functionalStatus: selectedVehicle?.vehicleFunctionalStatus || "OFF",
      transmission: selectedVehicle?.transmission || "Automatic",
      battery: selectedVehicle?.battery || "100%",
      assignedTo: selectedVehicle?.assignee?.map((user) =>
          getFullName(user.firstName, user.lastName)),
    };
  }, [selectedVehicle]);
  console.log("selected Vehicle : ",selectedVehicle )
  const modelData = [
    { label: "Model", value: initialValues.vehicle_model_name },
    { label: "Battery Health", value: "100%" },
    { label: "Required Tire Pressure", value: "F - 35 PSI    R - 45 PSI" },
    { label: "Transmission", value: initialValues.transmission },
    { label: "Registration", value: "Lahore" },
    { label: "Color", value: "Red", type: "color" },
  ];

  const vehicleData = [
    { label: "VIN", value: selectedVehicle?.vin },
    // { label: "Mileage", value: initialValues.mileage + " kilometers" },
    // { label: "Odometer Reading", value: "118,666 miles" },
    // { label: "Last Maintaned On", value: "Sep 22, 2020", type: "last_maintaned" },
    { label: "Battery", value: initialValues.battery },
    { label: "Functional Status", value: initialValues.vehicle_status, type: "functional_status" },
    { label: "Utilization Status", value: initialValues.vehicle_status, type: "utilization_status" },
    { label: "Zone", value: initialValues.zone },
    // { label: "Partner", value: initialValues.vehicle_owner_org_name },
    { label: "Assigned To", value: initialValues.assignedTo },
  ];

  return (
    <section className="__detail-container" style={{ marginTop: "80px"}}>
      <div style={{ width: "50px" }}></div>
      <div className="detail-content">
        <Table
          title={() => "Model Information"}
          bordered={false}
          showHeader={false}
          customClasses="--simple-two-columns"
          columns={columns}
          dataSource={modelData}
          pagination={false}
        />
        <Table
          title={() => "Vehicle Details"}
          bordered={false}
          showHeader={false}
          customClasses="--simple-two-columns"
          columns={columns}
          dataSource={vehicleData}
          pagination={false}
        />
      </div>
    </section>
  );
}

function renderUtilizationStatus(value: number) {
  const status = value;
  switch (status) {
    case 0:
      return "In the Lot";
    case 1:
      return "Checked Out";
    case 2:
      return "In the Lot";
    case 3:
      return "In the Lot";
    case 4:
      return "In the Lot";
    case 5:
      return "In the Lot";
    default:
      return "Checked Out";
  }
}

function functionalStatusCell(value: number) {
  const status = value;
  switch (status) {
    case 0:
      return "Unknown";
    case 1:
      return "Ride In-progress";
    case 2:
      return "In Maintenance";
    case 3:
      return "Marked Broken";
    case 4:
      return "Idle";
    case 5:
      return "Retired";
    default:
      return "";
  }
}