import {useCallback, useEffect, useMemo, useState} from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Typography, Input, Upload } from "antd";
import {AppState} from "../../store";
import {BatteryModelValues, BatteryValues} from "../../types/formik";
import {
    addBattery,
    editVehicleModel,
    getDropdownVehicles,
    setModalName,
    updateBattery,
    updateBatteryModel
} from "../../store/actions";
import {BATTERY_MODEL_FIRMWARE} from "../../utils/formik-data";
import {FormItem, SelectSearch} from "../../components";
const { Title, Text } = Typography;


export default function EditBatteryModelForm(){
    const dispatch = useDispatch()
    const {
        batteryModels: {selectedBatteryModel},
    } = useSelector<AppState, AppState>((state) => state);


    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const onSubmit = useCallback(
        (values: BatteryModelValues) => {
            dispatch(
                updateBatteryModel({
                    id: selectedBatteryModel?.id || "",
                    make: values.make.trim() || "",
                    model: values.model.trim() || "",
                    year: parseInt(values.year) || 2023
                })
            )
            onCancel();
        },
        [dispatch, onCancel, selectedBatteryModel?.id]
    );


    const initialValues = useMemo(() => {
        return {
            make: selectedBatteryModel?.make || "",
            model: selectedBatteryModel?.model || "",
            year: selectedBatteryModel?.year.toString() || '2023'
        }
    }, [selectedBatteryModel])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={BATTERY_MODEL_FIRMWARE.validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({
                  setFieldValue,
                  dirty,
                  isValid,
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
              }) => (
                <div className="form-container">
                    <Title level={5}>Update Battery Model</Title>
                    <Text className="description" type="secondary">
                        Update battery details
                    </Text>
                    <Form className="--add-users-form" onFinish={handleSubmit}>
                        <section className="">

                            <FormItem label="Make" error={errors.make} required>
                                <Input
                                    name="make"
                                    value={values.make}
                                    onChange={handleChange}
                                    placeholder="Make"
                                />
                            </FormItem>
                            <FormItem label="Model" error={errors.model} required>
                                <Input
                                    name="model"
                                    value={values.model}
                                    onChange={handleChange}
                                    placeholder="Model"
                                />
                            </FormItem>
                            <FormItem label="Year" error={errors.year} required>
                                <Input
                                    name="year"
                                    value={values.year}
                                    onChange={handleChange}
                                    placeholder="Year"
                                />
                            </FormItem>
                        </section>
                        <div className="action-btn-container">
                            <>
                                <Button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </Button>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={(!isValid && dirty)}
                                >
                                    Update
                                </Button>
                            </>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
}