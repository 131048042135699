import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";

import { ROUTES } from "utils/constants";
import { setRefreshToken, setToken } from "store/actions";

export default function ProtectedRoute(props: RouteProps) {
  const [cookies] = useCookies();
  const dispatch = useDispatch();

  const token = cookies?.meta?.token;

  useEffect(() => {
    if (token) {
      dispatch(setToken(`Bearer ${token?.accessToken}`));
      dispatch(setRefreshToken(token?.refreshToken));
    }
  }, [token, dispatch]);

  if (process.env.NODE_ENV === "development") {
    return <Route {...props} />;
  }

  return token ? <Route {...props} /> : <Redirect to={ROUTES.login} />;
}
