import { Button, Typography, Upload } from "antd";
import { DraggerProps, RcFile } from "antd/lib/upload";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";

import {setModalName, uploadCsvBatteries} from "store/actions"

const { Title } = Typography;
const { Dragger } = Upload;

export default function ImportCsvBattery() {
  const [file, setFile] = useState<RcFile>();
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onImport = useCallback(() => {
    if (file) {
      dispatch(uploadCsvBatteries(file));
    }
    onCancel();
  }, [dispatch, file, onCancel]);

  const draggerProps: DraggerProps = useMemo(
    () => ({
      name: "file",
      multiple: false,
      accept: "application/msword,.csv",
      beforeUpload: async (newFile) => {
        setFile(newFile);
      },
    }),
    []
  );

  return (
    <>
      <Title level={5}>Import File</Title>
      <Dragger {...draggerProps} className="import-csv">
        <p className="ant-upload text mr-2">
          Drag and drop a CSV file here or
          <span className="secondary-color"> browse</span> from your system
        </p>
      </Dragger>
      <div className="action-btn-container --vehicle">
        <Button className="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onImport} disabled={!file}>
          Import
        </Button>
      </div>
    </>
  );
}
