import {BillingState, BillingAction, CustomerBillingItem} from "types/store";
import {CustomerBill} from "../../protobuf/billing_service_pb";
const initialState: BillingState = {

};

export default function batteryHeartbeatReducer(
    state: BillingState = initialState,
    action: BillingAction
): BillingState {
    switch (action.type) {
        case "SET_BILL":
            return { ...state, bill: action.payload };
        case "SET_TOTAL_BILL":
            return { ...state, totalBill: action.payload };
        default:
            return state;
    }
}
