import { Dispatch } from "react";
import { FinancialsActions, FinancialsState, Payment, GetState } from "types";

export function setPayment(
  payload: FinancialsState["paymentList"]
): FinancialsActions {
  return {
    type: "SET_PAYMENTS",
    payload,
  };
}

export function addPayment(payment: Payment) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const {
      financials: { paymentList = [] },
    } = getState();

    dispatch(setPayment([payment, ...paymentList]));
    console.log(payment);
  };
}

export function setBulkActionsFinancials(
  payload: FinancialsState["showbulkActionsFinancials"]
): FinancialsActions {
  return {
    type: "SET_FINANCIALS_BULK_ACTIONS",
    payload,
  };
}
