import { Button } from "antd";

import { Input } from "components/base";
import {useDispatch, useSelector} from "react-redux";
import { AppState } from "store";
import { archiveIcon, exportIcon, plusIcon, searchIcon } from "svgs";
import {useCallback} from "react";
import {setModalName} from "../../store/actions";

export default function ClientsHeader() {
    const dispatch = useDispatch();
  const { showbulkActionsClients } = useSelector<AppState, AppState["clients"]>(
    (state) => state.clients
  );
  // const { userType } = useSelector<AppState, AppState["common"]>(
  //   (state) => state.common
  // );

    const onAddOrganization = useCallback(() => {
        dispatch(setModalName("add_organization"));
    }, [dispatch]);

  return (
    <div className="common-header">
      {/* <Input
        customClasses="--standard"
        placeholder="Search Organizations..."
        prefix={searchIcon}
      /> */}
      {showbulkActionsClients && (
        <article className="bulk-actions">
          <Button disabled icon={exportIcon}>
            Export
          </Button>
          {/* <Button disabled danger icon={archiveIcon}>
            Archive
          </Button> */}
        </article>
      )}
      <div className="actions-container">
        <Button
          type="primary"
          onClick={onAddOrganization}
          icon={<span className="custom-icon">{plusIcon}</span>}>
          Add Organization
        </Button>
      </div>
    </div>
  );
}
