import { getRandomString, range } from "utils/helpers";

export const clientsTableData = range(5).map((item, index) => ({
  key: index,
  onboarding_date: getRandomString(
    [
      "Jun 26, 2020  10:53 pm",
      "Jun 28, 2020  11:00 pm",
      "Jul 01, 2020  06:15 pm",
      "Jul 08, 2020  04:30 pm",
      "Jul 12, 2020  10:53 pm",
      "Jul 16, 2020  11:00 pm",
      "Aug 12, 2020  06:15 pm",
      "Aug 18, 2020  04:30 pm",
      "Aug 20, 2020  10:53 pm",
      "Aug 22, 2020  11:00 pm",
      "Aug 24, 2020  06:15 pm",
      "Aug 27, 2020  04:30 pm",
      "Sep 02, 2020  10:53 pm",
      "Sep 03, 2020  11:00 pm",
      "Sep 05, 2020  06:15 pm",
      "Sep 07, 2020  04:30 pm",
    ],
    index
  ),
  client_name: getRandomString(
    [
      "Foodpanda",
      "TCS",
      "Leopord Courier",
      "Bike 4 Everything",
      "M&P Courier",
      "Bykea",
      "Careem",
      "Uber",
      "Albaik",
      "Flipkart",
      "eBikeGO",
      "Zomato",
      "Rapido",
      "Deliveroo",
      "Pizza Hut",
      "Talabat",
    ],
    index
  ),
  organization_type: getRandomString(
    [
      "Fleet Owner",
      "Fleet Client",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Owner",
      "Fleet Client",
      "Fleet Client",
      "Fleet Client",
      "Fleet Client",
      "Fleet Client",
      "Fleet Client",
    ],
    index
  ),
  vehicle_count: getRandomString(
    [
      "140",
      "240",
      "150",
      "550",
      "40",
      "142",
      "350",
      "250",
      "145",
      "242",
      "147",
      "250",
      "350",
      "445",
      "245",
      "130",
    ],
    index
  ),

  client_admin: getRandomString(
    [
      "Hasan Sheikh (hasansheikh@foodpanda.com)",
      "Salman Ahmed (salman.ahmed@tcs.com)",
      "Harris Shams (harris.shams@leapordcourier.com)",
      "M. Taimoor (muhammad.taimoor@bike4everything.com)",
      "Ali  Bin Saud (ali.saud@mnp.com)",
      "Moeen-ud-Din (moeen@bikea.com)",
      "Kamran Sheikh (kamran.sheikh@careem.com)",
      "Suleman Ali (suleman.ali@uber.com)",
      "Harris Shams (harris.shams@albaik.com)",
      "Abbdul Samad (abdulsamad@flipcart.com)",
      "Qasim Rizwan (qasim.rizwan@eBikeGO.com)",
      "Rizwan Khan (rizwan.khan@zomato.com)",
      "Mustafa Shams (mustafa.shams@rapido.com)",
      "Haroon Rasheed (haroon.rasheed@deliveroo.com)",
      "Sheikh Ali (sheikh.ali@pizzahut.com)",
      "Kamil Hassan (kamil.hassan@talabat.com)",
    ],
    index
  ),
  enquiry_name: getRandomString([
    "Fedex",
    "Global Shipping and Logistics",
    "New Logistics",
    "Detex Courier Service",
    "AGL Courier Service LLC",
  ]),
  enquiry_phone: getRandomString([
    "0543571034",
    "0573620485",
    "0573620485",
    "0573620485",
    "0573620485",
  ]),
  enquiry_lease_duration: getRandomString([
    "6 months",
    "1 year",
    "1+ year",
    "1+ year",
    "2 months",
  ]),
  enquiry_email: getRandomString([
    "hasansheikh@fedex.com",
    "farooqkhan@globalshipping.com",
    "harrisshams@newlogistics.com",
    "muhammad.taimoor@deltex.com",
    "ali.saud@agl.com",
  ]),
}));
