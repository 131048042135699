import ListView from "./list-view";
import TableView from "./table-view";

interface ComponentProps {
  selectedView: string;
}

export default function ClientsContent(props: ComponentProps) {
  const { selectedView } = props;
  return (
    <div className="clients-contentss">
      {selectedView === "clients" ? <TableView /> : <ListView />}
    </div>
  );
}
