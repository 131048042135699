import { Button, Typography, Breadcrumb } from "antd";
import { editIcon, archiveIcon, statsIcon } from "svgs";
import { ComponentType } from ".";
import ImageCatalog from "./image-catalog";
import { backIcon, breadcrumbIcon, settingsIcon2 } from "svgs";
import { useSelector } from "react-redux";
import { AppState } from "store";

interface ComponentProps {
  onEditVehicle: VoidFunction;
  onArchiveVehicle: VoidFunction;
  onChangeComponent: (component: ComponentType) => void;
  onClickBack: VoidFunction;
}

const { Title, Text } = Typography;

export default function Gallery(props: ComponentProps) {
  const { onEditVehicle, onArchiveVehicle, onChangeComponent, onClickBack } = props;

  const { selectedVehicle } = useSelector<AppState, AppState["vehicles"]>(
    (state) => state.vehicles
  );

  return (
    <section className="__gallery-container">
      <div className="action-container">
        <Breadcrumb className="vehicle-route" separator={breadcrumbIcon}>
          <Breadcrumb.Item>
            <Button type="link" className="vehicles-link" onClick={onClickBack}>
              Vehicle
            </Button>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Vehicle Details</Breadcrumb.Item>
        </Breadcrumb>
        {/* <Button className="action-edit" icon={editIcon} onClick={onEditVehicle}>
          Edit
        </Button>
        <Button
          className="action-archive"
          danger
          icon={archiveIcon}
          onClick={onArchiveVehicle}
        >
          Archive
        </Button> */}
      </div>
      <div className="vehicle-meta" style={{ display: "flex" }}>
        <span className="back-icon"  onClick={onClickBack}>{backIcon}</span>
        <Title type="secondary" className="vehicle-no" level={5} style={{ marginLeft: '10px' }}>
          {selectedVehicle?.licensePlate}
          {/*<span*/}
          {/*  className="view-stats"*/}
          {/*  role="button"*/}
          {/*  onClick={() => onChangeComponent("stats")}*/}
          {/*>*/}
          {/*  {statsIcon} View Stats*/}
          {/*</span>*/}
        </Title>
        <Text className="sub-title">
          {/*<span>  Vehicle No.</span>*/}
          {/*<span className="last-uploaded">Last Uploaded on Jan 01, 2020</span>*/}
        </Text>
      </div>
      <ImageCatalog />
    </section>
  );
}
