import { Button } from "antd";

import { Input } from "components/base";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { setModalName } from "store/actions";
import { exportIcon, plusIcon, searchIcon } from "svgs";

export default function FinancialsHeader() {
  const dispatch = useDispatch();

  const onPayment = useCallback(() => {
    dispatch(setModalName("add_payment"));
  }, [dispatch]);

  const { showbulkActionsFinancials } = useSelector<
    AppState,
    AppState["financials"]
  >((state) => state.financials);

  return (
    <div className="common-header">
      <Input
        customClasses="--standard"
        placeholder="Search Payments..."
        prefix={searchIcon}
      />
      {showbulkActionsFinancials && (
        <article className="bulk-actions">
          <Button disabled icon={exportIcon}>
            Export
          </Button>
          {/* <Button disabled danger icon={archiveIcon}>
            Archive
          </Button> */}
        </article>
      )}
      <div className="actions-container">
        <Button
          type="primary"
          icon={<span className="custom-icon">{plusIcon}</span>}
          onClick={onPayment}>
          Add Payment Record
        </Button>
      </div>
    </div>
  );
}
