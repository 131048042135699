import {Provider} from "react-redux";
import { Theme } from "@k2/utils";

import configureStore from "store";
import Routes from "routes";
import { Alert } from "components";
// import {useEffect} from "react";
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import {initializeFirebaseConfiguration} from "./store/actions";
// import useFcmTokenCustomHook from "./store/actions/custom_fcm_token_hook";

const { ThemeProvider } = Theme;
const store = configureStore();

function App() {
    // useEffect(() => {
    //     initializeFirebaseConfiguration()
    // },[])
  return (
    <ThemeProvider>
      <Provider store={store}>
        
        <Alert />
        <Routes />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
