import IssueTypes from "./issue-type";

import Theming from "./theming";
import UploadParagraph from "./upload-paragraph";
import QrFormat from "./qr-format";
import UserRoles from "./user-roles";
import { Divider } from "antd";

export default function ClientsSetting() {
  return (
    <section className="clients-setting">
      <IssueTypes />
      <Divider />
      <UploadParagraph />
      <Divider />
      <QrFormat />
      <Divider />
      <UserRoles />
      <Divider />
      <Theming />
    </section>
  );
}
