import { Tabs } from "antd";

import ClientsSetting from "./clients-setting";

const { TabPane } = Tabs;

export default function SettingsContent() {
  return (
    <div className="settings-content">
      <main className="tabs-container">
        <Tabs defaultActiveKey="2">
          <TabPane tab="Client Specific Settings" key="2">
            <ClientsSetting />
          </TabPane>
        </Tabs>
      </main>
    </div>
  );
}
