import Wrapper from "zyp-kiosk";
import {useSelector} from "react-redux";
import {AppState} from "../store";

export default function Kiosks() {
    const {
        common: { token },
    } = useSelector<AppState, AppState>((state) => state);
    console.log("reached in kiosk prod tab...");
    return (
       <Wrapper token={token} env="stg"></Wrapper>
    );
}