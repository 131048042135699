import { format } from "date-fns";

import { VehicleModelState } from "types/store";

export function parseVehicleModelTable(
    search: VehicleModelState["search"],
    list?: VehicleModelState["models"],
    listType?: VehicleModelState["filters"]["listType"]
) {
    if (list) {
        const data = list.map((model, index) => ({
            key: index,
            model_id: model.id,
            make: model.make,
            name: model.name,
            year: model.year,
            firmware_version: model.firmwareVersion,
            action: "",
            raw: model,
            listType: listType,
        }));

        return data
    }

    return [];
}
