import { UsersActions, UsersState } from "types/store";

const initialState: UsersState = {
  search: "",
  showbulkActionsUser: false,
  selectedUserRows: [],
  selectedTab: "all",
  allUsersPage: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  riderUsersPage: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  mechanicUsersPage: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  adminUsersPage: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
  clientAdminUsersPage: {
    selectedPage: 1,
    totalElements: 0,
    totalPages: 0,
  },
};

export default function vehiclesReducer(
  state: UsersState = initialState,
  action: UsersActions
): UsersState {
  switch (action.type) {
    case "SET_SELECTED_USER":
      return { ...state, selectedUser: action.payload };
    case "SET_ALL_USERS":
      return { ...state, allUsers: action.payload };
    case "SET_ALL_USERS_SELECTED_PAGE":
      return {
        ...state,
        allUsersPage: { ...state.allUsersPage, selectedPage: action.payload },
      };
    case "SET_ALL_USERS_TOTAL_PAGE":
      return {
        ...state,
        allUsersPage: { ...state.allUsersPage, ...action.payload },
      };
    case "SET_ADMIN_USERS":
      return { ...state, adminUsers: action.payload };
    case "SET_CLIENT_ADMIN_USERS":
      return { ...state, clientAdminUsers: action.payload };
    case "SET_ADMIN_USERS_SELECTED_PAGE":
      return {
        ...state,
        adminUsersPage: {
          ...state.adminUsersPage,
          selectedPage: action.payload,
        },
      };
    case "SET_CLIENT_ADMIN_USERS_SELECTED_PAGE":
      return {
        ...state,
        clientAdminUsersPage: {
          ...state.clientAdminUsersPage,
          selectedPage: action.payload,
        },
      };
    case "SET_ADMIN_USERS_TOTAL_PAGE":
      return {
        ...state,
        adminUsersPage: { ...state.adminUsersPage, ...action.payload },
      };
    case "SET_CLIENT_ADMIN_USERS_TOTAL_PAGE":
      return {
        ...state,
        clientAdminUsersPage: { ...state.clientAdminUsersPage, ...action.payload },
      };
    case "SET_RIDER_USERS":
      return { ...state, riderUsers: action.payload };
    case "SET_RIDER_USERS_SELECTED_PAGE":
      return {
        ...state,
        riderUsersPage: {
          ...state.riderUsersPage,
          selectedPage: action.payload,
        },
      };
    case "SET_RIDER_USERS_TOTAL_PAGE":
      return {
        ...state,
        riderUsersPage: { ...state.riderUsersPage, ...action.payload },
      };
    case "SET_MECHANIC_USERS":
      return { ...state, mechanicUsers: action.payload };
    case "SET_MECHANIC_USERS_SELECTED_PAGE":
      return {
        ...state,
        mechanicUsersPage: {
          ...state.mechanicUsersPage,
          selectedPage: action.payload,
        },
      };
    case "SET_MECHANIC_USERS_TOTAL_PAGE":
      return {
        ...state,
        mechanicUsersPage: { ...state.mechanicUsersPage, ...action.payload },
      };
    case "SET_SELECTED_TAB":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "SET_USER_SEARCH":
      return {
        ...state,
        search: action.payload,
      };
    case "SET_USER_BULK_ACTIONS":
      return {
        ...state,
        showbulkActionsUser: action.payload,
      };
    case "SET_USER_SELECTED_ROWS":
      return {
        ...state,
        selectedUserRows: action.payload,
      };
    case "SET_DROPDOWN_USERS":
      return {
        ...state,
        dropdownUsers: action.payload,
      };
    default:
      return state;
  }
}
