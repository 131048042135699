import {
  InnerLayout,
  ClientsHeader,
  ClientsContent,
  ClientsFilter,
  TabNavs,
} from "components";
import { useState } from "react";
//import Wrapper from "zyp-kiosk";

export default function Clients() {
  const [selectedView, setSelectedView] = useState("clients");
  return (
    <InnerLayout>
      {{
        header: <ClientsHeader />,
        // tabs: (
        //   <TabNavs
        //     selected={selectedView}
        //     onChange={(value) => setSelectedView(value)}
        //     options={[{ key: "clients" }, { key: "enquiries" }]}
        //     showCount
        //   />
        // ),
        content: <ClientsContent selectedView={selectedView} />,
       // filter: selectedView !== "enquiries" ? <ClientsFilter /> : null,
      }}
    </InnerLayout>
    //   <Wrapper></Wrapper>
  );
}
