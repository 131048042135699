import { BatteryState, BatteryActions } from "types/store";


const originalFilters = {
    Assignee: [],
};
const updatedFilters = {
    Assignee: [],
};

const initialState: BatteryState = {
    page: {
        selectedPage: 1,
        totalElements: 0,
        totalPages: 0,
    },
    filters: {
        original: originalFilters,
        updated: updatedFilters,
        listType: "current",
    },
    search: "",
    showbulkActionsBatteries: false,
    selectedBatteryRows: [],
};

export default function batteryReducer(
    state: BatteryState = initialState,
    action: BatteryActions
): BatteryState {
    switch (action.type) {
        case "SET_BATTERIES":
            console.log("hb bat in recucer : ", action.payload)
            return { ...state, batteries: action.payload };
        case "SET_BATTERIES_HB":
            console.log("hb in recucer : ", action.payload)
            return { ...state, battery_heartbeat: action.payload };
        case "SET_BATTERY_SELECTED_PAGE":
            return {
                ...state,
                page: { ...state.page, selectedPage: action.payload },
            };
        case "SET_BATTERY_TOTAL_PAGE":
            return { ...state, page: { ...state.page, ...action.payload } };
        case "SET_BATTERY_ORIGINAL_FILTERS":
            return {
                ...state,
                filters: { ...state.filters, original: action.payload },
            };
        case "SET_BATTERY_UPDATED_FILTERS":
            return {
                ...state,
                filters: { ...state.filters, updated: action.payload },
            };
        case "SET_BATTERY_SEARCH":
            return {
                ...state,
                search: action.payload,
            };
        case "SET_SELECTED_BATTERY":
            return {
                ...state,
                selectedBattery: action.payload,
            };
        case "SET_BATTERY_DETAIL":
            return { ...state, batteryDetail: action.payload };

        case "SET_BATTERY_BULK_ACTIONS":
            return {
                ...state,
                showbulkActionsBatteries: action.payload,
            };

        case "SET_BATTERY_SELECTED_ROWS":
            return {
                ...state,
                selectedBatteryRows: action.payload,
            };
        case "SET_BATTERY_LIST_TYPE":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    listType: action.payload,
                },
            };
        default:
            return state;
    }
}
