import { Switch, Redirect } from "react-router-dom";
import { ROUTES } from "utils/constants";
import TableView from "./table-view";
import ProtectedRoute from "components/protected-route";
import VehicleDetail from "../vehicles-content/vehicle-detail";
import BatteryDetailTableView from "./battery_detail";
export default function BatteryContent() {
    return (
        <div className="vehicle-models-content">
            <Switch>
                <ProtectedRoute exact path={ROUTES.battery}>
                    <Redirect to={ROUTES.batteries_list} />
                </ProtectedRoute>
                <ProtectedRoute exact path={ROUTES.batteries_list}>
                    <TableView />
                </ProtectedRoute>
                <ProtectedRoute exact path={ROUTES.battery_detail}>
                    <BatteryDetailTableView />
                </ProtectedRoute>
                <ProtectedRoute path={`${ROUTES.battery}/*`}>
                    <Redirect to={ROUTES.batteries_list} />
                </ProtectedRoute>
            </Switch>
        </div>
    );
}
