import {useCallback, useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "utils/constants";
import { useHistory } from "react-router-dom";
import { AppState } from "store";
import { Table, TablePagination } from "components/base";
import {parseBatteryHeartbeatTable, parseBatteryTable} from "utils/parsers";
import {
    initializeVehicleModel,
    setIssuesSelectedPage,
    initializeBatteries,
    setSelectedBattery
} from "store/actions";
import { ActionMenu } from "./cell-renders";
import { TableProps } from "antd/lib/table";

const getColumns: () => TableProps<any>["columns"] = (


) => {

  
const columns: TableProps<any>["columns"] = [

  {
    title: "Battery Alias",
    dataIndex: "battery_alias",
    width: 130,
    ellipsis: true,
},
    {
        title: "Battery ID",
        dataIndex: "battery_id",
        width: 130,
        ellipsis: true,
    },
   
    {
        title: "SOC",
        dataIndex: "soc_level",
        width: 100,
        ellipsis: true,
    
    },
    {
        title: "Host ID",
        dataIndex: "host_id",
        width: 130,
        ellipsis: true,
  
    },

    {
        title: "Operational State",
        dataIndex: "operational_state",
        width: 160,
        ellipsis: true,
    
    },
    {
        title: "Model",
        dataIndex: "make_model",
        width: 160,
        ellipsis: true,
    
    },
    {
        title: "",
        dataIndex: "action",
        render: ActionMenu,
        align: "right",
        // width: 56,
        className: "action-menu open-link --issues",
    },
];
return columns;
};

export default function TableView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    batteries,
      battery_heartbeat,
    page,
    search,
    selectedBatteryRows,
    filters: { listType },
  } = useSelector<AppState, AppState["batteries"]>((state) => state.batteries);
  const { selectedPage, totalPages } = page;
  const data = useMemo(
    () => parseBatteryTable(search, batteries, listType),
    [batteries, listType, search]
  );
    const data2 = useMemo(
        () => parseBatteryHeartbeatTable(battery_heartbeat),
        [batteries, listType, search]
    );
    useEffect(() => {
        dispatch(initializeBatteries());
       
    }, [dispatch]);

    console.log("Data in table for batterirs hb : = ", data2)
  const onChangeSelection = useCallback(
    (value) => {
        console.log("values : ", value)
    },
    []
  );


  const onBack = useCallback(() => {
    if (selectedPage > 1) {
      dispatch(setIssuesSelectedPage(selectedPage - 1));
    }
  }, [dispatch, selectedPage]);

  const onNext = useCallback(() => {
    if (selectedPage < totalPages) {
      dispatch(setIssuesSelectedPage(selectedPage + 1));
    }
  }, [dispatch, selectedPage, totalPages]);

  const resetCallback = useCallback(() => {
    dispatch(setIssuesSelectedPage(1));
  }, [dispatch]);

  const handleRowClick = useCallback((battery: any) => {
    console.log("Battery in handleRowClick:",battery.raw);
    dispatch(setSelectedBattery(battery.raw));
    history.push(ROUTES.battery_detail);
  }, [dispatch]);

  return (
    <>
      <Table
        columns={getColumns()}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => handleRowClick(record), // Handle row click
          };
        }}
        // rowSelection={{
        //   selectedRowKeys: selectedBatteryRows,
        //   onChange: onChangeSelection,
        // }}
        pagination={false}
        scroll={{ y: "70vh" }}
      />
      {/*<TablePagination*/}
      {/*  page={page}*/}
      {/*  onBack={onBack}*/}
      {/*  onNext={onNext}*/}
      {/*  resetCallback={resetCallback}*/}
      {/*/>*/}
    </>
  );
}
