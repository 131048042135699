import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import { FallbackLayout, Layout } from "components";
import { ROUTES } from "utils/constants";
import {
  setOwnerOrgId,
  setConsigneeId,
  setRefreshToken,
  setToken,
  setUserType, initializeFirebaseConfiguration,
} from "store/actions";
import { ShakeHands, Welcome } from "pages";

const Login = lazy(() => import("pages/login"));
const Verify = lazy(() => import("pages/verify"));

export default function Routes() {
  const [cookies] = useCookies();
  const dispatch = useDispatch();

  const token = cookies?.meta?.token;
  const user = cookies?.meta?.user;



  useEffect(() => {

    if (token) {
      dispatch(setToken(`Bearer ${token?.accessToken}`));
      dispatch(setRefreshToken(token?.refreshToken));
      dispatch(setConsigneeId(user?.clientOrg?.val));
      dispatch(setOwnerOrgId(user?.ownerOrg?.val));
      dispatch(setUserType(user?.type));
      initializeFirebaseConfiguration(dispatch)
    }
  }, [user, token, dispatch]);

  return (
    <Router>
      <Suspense fallback={<FallbackLayout />}>
        <Switch>
          {/*<Route exact path={ROUTES.welcome}>*/}
          {/*  <Welcome />*/}
          {/*</Route>*/}
          {/*<Route exact path={ROUTES.shake_hands}>*/}
          {/*  <ShakeHands />*/}
          {/*</Route>*/}
          <Route exact path={ROUTES.login}>
            <Login />
          </Route>
          <Route exact path={ROUTES.verify}>
            <Verify />
          </Route>
          <Layout />
        </Switch>
      </Suspense>
    </Router>
  );
}
