import { Typography, Button ,Form } from "antd";
import {useCallback, useState, useMemo , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { parseFirmwares } from "utils/parsers";
import { AppState } from "store";
import { setModalName, updateModelFirmware,getDropdownFirmwares } from "store/actions";
import {FormItem, SelectSearch} from "../../components";
import { Formik } from "formik";
const { Text, Title } = Typography;

export default function UpdateModelFirmwareForm() {

    const {
        firmwares: { dropdownFirmwares },
      } = useSelector<AppState, AppState>((state) => state);
    
    const { selectedModels } = useSelector<AppState, AppState["models"]>(
        (state) => state.models
    );
    const dispatch = useDispatch();

    const firmwaresOptions = useMemo(() =>  parseFirmwares(dropdownFirmwares), [
        dropdownFirmwares,
      ]);

    
    const onSubmit = useCallback((values) => {
        console.log("Selected Model ID : ", selectedModels?.id)
        console.log("Version Value:", values['Firmware Version'].toString())
        dispatch(
            updateModelFirmware({
                model_id: selectedModels?.id || ""
            })
        );
        onCancel();
    }, [dispatch, selectedModels?.id]);

    const onCancel = useCallback(() => {
        dispatch(setModalName(undefined));
    }, [dispatch]);

    const initialValues = {
        firmwareVersion: '',
    };
    useEffect(() => {
        if (!dropdownFirmwares) {
            console.log("reached here", dropdownFirmwares)
          dispatch(getDropdownFirmwares());
        }
      }, [dispatch, dropdownFirmwares]);
   

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}>
            {({
                  values,
                  handleSubmit,
                  setFieldValue,
              }) =>
        <section className="delete-modal">
            <Title level={5}>Rollout Firmware Upgrade</Title>
            <Text type="secondary">
                Please select the version to use for updating firmware on all vehicles with model {" "}
                <strong>
                    “{selectedModels?.name}”
                </strong>
                :
                <br/><br/>
            </Text>
            <Form onFinish={handleSubmit} className="--update-firmware-form">
                <FormItem label="Firmware Versions">
                    <SelectSearch
                        value={values.firmwareVersion}
                        items= {firmwaresOptions}
                        setFieldValue={setFieldValue}
                        fieldName="Firmware Version"
                        placeholder="Select "
                />
            </FormItem>
            <div className="action-btn-container">
                <Button className="cancel-btn" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="primary" danger htmlType="submit">
                    Rollout Firmware Upgrade
                </Button>
            </div>
            </Form>
        </section>
            }
        </Formik>
    );
}
