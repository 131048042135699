import { Radio, Typography } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

import { classNames } from "utils/helpers";

interface ComponentProps {
  label: string;
  options: string[];
  icon?: JSX.Element[];
  defaultOption: string;
  type: "primary" | "secondary";
  value?: string;
  onChange?: (e: RadioChangeEvent) => void;
}

const { Text } = Typography;

export default function SwitchButton(props: ComponentProps) {
  const { label, options, defaultOption, onChange, type, icon } = props;

  const classes = classNames({
    "--primary": type === "primary",
    "--secondary": type === "secondary",
  });

  return (
    <>
      <Text type="secondary" className="filter-switch-label">
        {label}
      </Text>
      <Radio.Group
        className={`filter-switch ${classes}`}
        defaultValue={defaultOption}
        buttonStyle="solid"
        onChange={onChange}>
        {options.map((option, index) => (
          <Radio.Button key={`switch-item-${option}`} value={option}>
            <article className="switch-label-icon">
              {icon && icon[index]} {option}
            </article>
          </Radio.Button>
        ))}
      </Radio.Group>
    </>
  );
}
