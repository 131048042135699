import { useDispatch } from "react-redux";
import {
  Form,
  Button,
  Typography,
  Radio,
  DatePicker,
  TimePicker,
  Divider,
} from "antd";
import { useCallback } from "react";
import { Formik } from "formik";

import { setModalName } from "store/actions";
import { SelectSearch } from "components";
import { boxIcon, clipboardIcon, deleteIcon, wrenchIcon } from "svgs";
import { SwitchButton } from "components/base";

const { Title, Text } = Typography;

export default function AddTaskForm() {
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  return (
    <Formik initialValues={{}} onSubmit={(values) => console.trace(values)}>
      {({ setFieldValue, handleChange }) => (
        <div className="form-container">
          <Title level={5}>Add Tasks</Title>
          <Text className="description" type="secondary">
            Please provide the user’s shift detail for better fleet performance.
          </Text>
          <Form className="--add-task-form">
            <div className="actions-container">
              <Form.Item label="Task Type" name="add-task">
                <SwitchButton
                  label=""
                  options={["maintenance", "delivery"]}
                  defaultOption={"maintenance"}
                  icon={[boxIcon, wrenchIcon]}
                  type="secondary"
                />
              </Form.Item>
              <Form.Item
                className="date-time"
                label="Date & Time"
                name="date-time"
                required
                rules={[
                  { required: true, message: "Please select date and time!" },
                ]}>
                <DatePicker />
                <TimePicker format="HH:mm" />
                <Radio.Group
                  name="time"
                  defaultValue="am"
                  buttonStyle="solid"
                  className="filter-switch --add_task --secondary">
                  <Radio.Button value="am">am</Radio.Button>
                  <Radio.Button value="pm">pm</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="pick_up"
                className=""
                label="Pick Up"
                required
                rules={[
                  { required: true, message: "Please enter pick up location!" },
                ]}>
                <SelectSearch
                  value={""}
                  items={[]}
                  setFieldValue={setFieldValue}
                  placeholder="Enter or Choose Location"
                />
              </Form.Item>
              <Form.Item
                className=""
                label="Drop Off"
                name="drop-off"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter drop off location!",
                  },
                ]}>
                <SelectSearch
                  value={""}
                  items={[]}
                  setFieldValue={setFieldValue}
                  placeholder="Enter or Choose Location"
                />
              </Form.Item>
              <Form.Item
                className=""
                label="Workshop"
                name="workshop"
                required
                rules={[
                  {
                    required: true,
                    message: "Please enter workshop address!",
                  },
                ]}>
                <SelectSearch
                  value={""}
                  items={[]}
                  setFieldValue={setFieldValue}
                  placeholder="Enter or Choose Location"
                />
              </Form.Item>
            </div>
            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Reset
              </Button>
              <Button type="primary" onClick={onCancel}>
                Add
              </Button>
            </div>
          </Form>
          <Divider orientation="left" dashed>
            TASK LIST
          </Divider>
          <section className="task-list-section">
            <section className="empty-list">
              <p>{clipboardIcon}</p> <p>No tasks added so far</p>
            </section>
            <section className="task-list">
              <p>{clipboardIcon}</p>
              <article className="task">
                <figure>
                  <p className="task-title">
                    Delivery from Street 8, marina dubai to jumeirah village
                  </p>
                  <figcaption className="task-time">
                    Pick up Time: 12:00 pm on Aug 04, 2020
                  </figcaption>
                </figure>
                <p>{deleteIcon}</p>
              </article>
            </section>
          </section>
          <div className="action-btn-container --footer">
            <Button className="cancel-btn" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
}
