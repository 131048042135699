import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Typography,
  Radio,
  TimePicker,
  Upload,
  Input,
} from "antd";
import { v4 as uuidV4 } from "uuid";
import { useCallback } from "react";
import { Formik } from "formik";

import { addPayment, setModalName, setPayment } from "store/actions";
import { DatePicker, SwitchButton } from "components/base";
import { FormItem, RadioGroup, SelectSearch } from "components";
import { ADD_PAYMENT_FORMIK } from "utils/formik-data";
import { uploadIcon } from "svgs";
import { AppState } from "store";
import { format } from "date-fns";

const { Title, Text } = Typography;

const metaData = {
  payment_options: {
    name: "Time Range",
    options: [
      { label: "Lease", value: "1" },
      { label: "Salik", value: "2" },
      { label: "Traffic Fine", value: "3" },
      { label: "Service & Repair", value: "4" },
      { label: "Other", value: "5" },
    ],
  },
};

const addPaymentType = (val: string) => {
  switch (val) {
    case "1":
      return "Lease";
    case "2":
      return "Salik";
    case "3":
      return "Traffic Fine";
    case "4":
      return "Service & Repair";
    default:
      return "Other";
  }
};

export default function AddPaymentForm() {
  const dispatch = useDispatch();
  const { paymentList = [] } = useSelector<AppState, AppState["financials"]>(
    (state) => state.financials
  );
  const onCancel = useCallback(() => {
    dispatch(setModalName(undefined));
  }, [dispatch]);

  const onSubmit = useCallback(
    (values: typeof ADD_PAYMENT_FORMIK.initialValues) => {
      dispatch(
        addPayment({
          amount: values.amount,
          date: format(Number(values.date), "MMM dd, yyyy p"),
          vehicle_count: values.vehicle_count,
          cause_of_fine: values.cause_of_fine,
          payment_status: values.payment_status,
          payment_type: addPaymentType(values.payment_type),
          toll_gate: values.toll_gate,
          workshop: values.workshop,
          key: paymentList.length + 1,
          client: "Foodpanda",
        })
      );
      onCancel();
    },
    [dispatch, onCancel, paymentList]
  );

  return (
    <Formik
      initialValues={ADD_PAYMENT_FORMIK.initialValues}
      validationSchema={ADD_PAYMENT_FORMIK.validationSchema}
      onSubmit={onSubmit}>
      {({
        setFieldValue,
        errors,
        values,
        isValid,
        dirty,
        handleSubmit,
        handleChange,
      }) => (
        <div className="form-container">
          <Title level={5}>Add Payment Record</Title>
          <Text className="description" type="secondary">
            Provide payment details, including type and amount.
          </Text>
          <Form onFinish={handleSubmit}>
            <section className="--add-payment-form ">
              <FormItem
                verticalAlign="center"
                error={errors.payment_status}
                label="Payment Status">
                <div className="actions-container --fixed-width">
                  <SwitchButton
                    label=""
                    options={["paid", "outstanding"]}
                    defaultOption="paid"
                    type="secondary"
                    value={values.payment_status}
                    onChange={(selected) => {
                      setFieldValue("payment_status", selected.target.value);
                    }}
                  />
                </div>
              </FormItem>
              <FormItem
                verticalAlign="flex-start"
                error={errors.payment_type}
                label="Payment Type"
                required>
                <RadioGroup
                  options={metaData.payment_options.options}
                  value={values.payment_type}
                  onChange={(selected) => {
                    setFieldValue("payment_type", selected.target.value);
                  }}
                />
              </FormItem>
              <FormItem error={errors.amount} label="Amount" required>
                <Input
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  type="float"
                  placeholder="000.00"
                  className="number-input"
                />
              </FormItem>
              <FormItem label="Date">
                <div className="date-time-container">
                  <DatePicker
                    name="date"
                    size="middle"
                    placeholder="Select "
                    format="MMM DD, YYY"
                    onChange={(date) => setFieldValue("date", date)}
                  />
                </div>
              </FormItem>
              {values.payment_type === "1" ? (
                <>
                  <FormItem error={errors.client} label="Client">
                    <SelectSearch
                      value={values.client}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="client"
                      placeholder="Select"
                    />
                  </FormItem>
                  <FormItem
                    error={errors.vehicle_count}
                    label="Number of Vehicles">
                    <Input
                      name="vehicle_count"
                      value={values.vehicle_count}
                      onChange={handleChange}
                      type="number"
                      placeholder="00"
                    />
                  </FormItem>
                </>
              ) : values.payment_type === "2" ? (
                <>
                  <FormItem error={errors.client} label="Client">
                    <SelectSearch
                      value={values.client}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="client"
                      placeholder="Select"
                    />
                  </FormItem>
                  <FormItem error={errors.toll_gate} label="Toll Gate">
                    <SelectSearch
                      value={values.toll_gate}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="toll_gate"
                      placeholder="Select "
                    />
                  </FormItem>
                </>
              ) : values.payment_type === "3" ? (
                <>
                  <FormItem error={errors.client} label="Client">
                    <SelectSearch
                      value={values.client}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="client"
                      placeholder="Select"
                    />
                  </FormItem>
                  <FormItem error={errors.cause_of_fine} label="Cause of Fine">
                    <SelectSearch
                      value={values.cause_of_fine}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="cause_of_fine"
                      placeholder="Select "
                    />
                  </FormItem>
                </>
              ) : values.payment_type === "4" ? (
                <>
                  <FormItem error={errors.client} label="Client">
                    <SelectSearch
                      value={values.client}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="client"
                      placeholder="Select"
                    />
                  </FormItem>
                  <FormItem error={errors.workshop} label="Workshop">
                    <SelectSearch
                      value={values.workshop}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="workshop"
                      placeholder="Select"
                    />
                  </FormItem>
                </>
              ) : values.payment_type === "5" ? (
                <>
                  <FormItem error={errors.client} label="Client">
                    <SelectSearch
                      value={values.client}
                      items={[]}
                      setFieldValue={setFieldValue}
                      fieldName="client"
                      placeholder="Select"
                    />
                  </FormItem>
                  <FormItem
                    error={errors.reason_of_payment}
                    label="Reason of Payment">
                    <Input
                      name="reason_of_payment"
                      value={values.reason_of_payment}
                      onChange={handleChange}
                      placeholder="Enter Reason"
                      className="text-input"
                    />
                  </FormItem>
                </>
              ) : null}

              <FormItem label="Due Date">
                <div className="date-time-container">
                  <DatePicker
                    name="due_date"
                    size="middle"
                    placeholder="Select "
                    format="MMM DD, YYY"
                    value={values.due_date}
                    onChange={(due_date) => setFieldValue("due_date", due_date)}
                  />
                </div>
              </FormItem>
              <FormItem
                label="Invoice"
                error={errors.fileList}
                verticalAlign="flex-start">
                <Upload.Dragger
                  name="document_file"
                  multiple={false}
                  fileList={values.fileList}
                  onChange={({ fileList }) => {
                    // Getting total number of files
                    const totalFiles = fileList.length;

                    // If multiple files, pick last file
                    if (totalFiles > 1) {
                      setFieldValue("fileList", [fileList[totalFiles - 1]]);
                    } else {
                      setFieldValue("fileList", fileList);
                    }
                  }}>
                  {uploadIcon}
                  <p className="ant-upload text">
                    Drag the document here or
                    <span className="primary-color"> browse</span> from system
                  </p>
                </Upload.Dragger>
              </FormItem>
            </section>

            <div className="action-btn-container">
              <Button className="cancel-btn" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!(isValid && dirty)}>
                Add
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
